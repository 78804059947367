import * as React from 'react';

function ThumbsupPolish() {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 74.9 76.1"
      xmlSpace="preserve"
    >
      <path
        fill="#9B0C0F"
        d="M59,75.7C59,75.7,59,75.7,59,75.7H45c-9.1,0-21.3,0.3-26.9-2.5c0,0,0,0,0,0c-1.3-0.6-2.1-2-2.1-3.4v-36
	c0-0.9,0.6-1.7,1.5-1.9l0,0c4-0.9,6.7-2.7,12.8-8c5.9-5.3,7.7-12.8,7.3-20.1l0-0.7L38,2.6C39.2,1,41.2,0.1,43.4,0
	c3.8,0.1,6.7,3.4,8.4,9.1c2,6.9,0,17.3-0.1,17.4c0,0.1,0.1,0.4,0.2,0.6c0.2,0.2,0.4,0.3,0.7,0.3c0,0,0,0,0,0h14.8
	c2.2-0.1,4.3,1,5.6,2.9c1.6,2.2,3.7,7.8-0.7,19.8l-0.1,0.2L67.1,69C66.4,73,63,75.7,59,75.7z"
      />
      <path
        fill="#7F0F10"
        d="M3.8,76.1h10.1c2.1,0,3.8-1.7,3.8-3.8V31.8c0-2.1-1.7-3.8-3.8-3.8H3.8C1.7,28,0,29.7,0,31.8v40.6
	C0,74.4,1.7,76.1,3.8,76.1z"
      />
      <g>
        <path
          fill="#F1D169"
          d="M14,50h-2.3l-0.3-0.2l-2-4l0.2,0.5v3.4L9.4,50H7.7l-0.2-0.2v-7l0.2-0.2H10l0.3,0.2l2,4l-0.2-0.5v-3.4l0.2-0.2
		H14l0.2,0.2v7L14,50z"
        />
        <path
          fill="#F1D169"
          d="M20.4,50H16l-0.2-0.2v-7l0.2-0.2h4.3l0.2,0.2V44l-0.2,0.2H18v1h2.1l0.2,0.2v1.2l-0.2,0.2H18v1.4h2.4l0.2,0.2
		v1.2L20.4,50z"
        />
        <path
          fill="#F1D169"
          d="M27.8,49.8l-1.7,0.4L25.8,50l-1.7-2.9H24v2.7L23.8,50H22l-0.2-0.2v-7l0.2-0.2h3c1.9,0,2.8,0.7,2.8,2.2
		c0,1.3-0.9,1.8-1.5,2l1.7,2.8L27.8,49.8z M24.8,44.1H24v1.3h0.5c0.5,0,1.1,0,1.1-0.6C25.6,44.3,25.5,44.1,24.8,44.1z"
        />
        <path
          fill="#F1D169"
          d="M30.9,50h-1.8l-0.2-0.2v-7l0.2-0.2h1.8l0.2,0.2v7L30.9,50z M35.5,49.8l-1.7,0.4L33.6,50l-2.3-3.8v-0.3l2.2-3.4
		l0.3-0.1l1.5,0.4l0.2,0.3l-2.1,3l2.3,3.5L35.5,49.8z"
        />
        <path
          fill="#F1D169"
          d="M39.6,50.2c-2.5,0-3.7-1.4-3.7-3.9c0-2.4,1.1-4,3.8-4c2.6,0,3.7,1.6,3.7,3.9C43.4,48.7,42.2,50.2,39.6,50.2z
		 M39.6,44.1c-1.1,0-1.4,1-1.4,2.1c0,1.1,0.3,2.1,1.4,2.1c1.1,0,1.4-1,1.4-2.1C41,45.1,40.7,44.1,39.6,44.1z"
        />
        <path
          fill="#F1D169"
          d="M52.3,49.8L52.1,50H50l-0.2-0.2l-0.7-4.2l-0.8,4.2L48,50H46l-0.2-0.2l-2-6.9l0.2-0.3l1.9-0.2l0.2,0.2l1,4.9
		l0.7-4.7l0.2-0.2h2l0.2,0.2l0.8,4.7l1.1-4.9l0.2-0.2l1.8,0.2l0.2,0.3L52.3,49.8z"
        />
        <path
          fill="#F1D169"
          d="M57.2,50h-1.8l-0.2-0.2v-7l0.2-0.2h1.8l0.2,0.2v7L57.2,50z"
        />
        <path
          fill="#F1D169"
          d="M63.6,50h-4.4L59,49.8v-7l0.2-0.2h4.3l0.2,0.2V44l-0.2,0.2h-2.3v1h2.1l0.2,0.2v1.2l-0.2,0.2h-2.1v1.4h2.4
		l0.2,0.2v1.2L63.6,50z"
        />
        <path
          fill="#F1D169"
          d="M70,49.9c-0.2,0.1-1.2,0.3-1.9,0.3c-2.5,0-3.6-1.2-3.6-3.9c0-2.6,1.1-4,3.7-4c0.7,0,1.3,0.2,1.6,0.3l0.2,0.2
		v1.3l-0.2,0.2c-0.3-0.1-1.1-0.3-1.7-0.3c-1.1,0-1.3,0.9-1.3,2.1c0,1.3,0.2,2.1,1.3,2.1c0.5,0,1.4-0.2,1.7-0.3l0.3,0.2v1.4L70,49.9z
		"
        />
      </g>
      <g>
        <path
          fill="#FFFFFF"
          d="M5.6,61.2c-1,0-1.7-0.2-2.3-0.3l-0.2-0.2v-1.4l0.3-0.2c0.4,0.1,1.7,0.3,2.1,0.3C5.8,59.4,6,59.3,6,59
		c0-0.9-3-0.8-3-3.5c0-1.6,1.2-2.2,2.7-2.2c0.9,0,1.7,0.1,2.1,0.3l0.2,0.2v1.3l-0.2,0.2c-0.4-0.1-1.4-0.3-2-0.3
		c-0.4,0-0.5,0.1-0.5,0.3c0,0.9,3,0.7,3,3.4C8.4,60.6,7.2,61.2,5.6,61.2z"
        />
        <path
          fill="#FFFFFF"
          d="M14.5,61H9.3L9,60.8v-1.2l3.1-4.4H9.6l-0.2-0.2v-1.2l0.2-0.2h4.9l0.2,0.2V55l-3,4.4h2.8l0.2,0.2v1.2L14.5,61z"
        />
        <path
          fill="#FFFFFF"
          d="M20.6,60.9c-0.2,0.1-1.2,0.3-1.9,0.3c-2.5,0-3.6-1.2-3.6-3.9c0-2.6,1.1-4,3.7-4c0.7,0,1.3,0.2,1.6,0.3l0.2,0.2
		v1.3l-0.2,0.2c-0.3-0.1-1.1-0.3-1.7-0.3c-1.1,0-1.3,0.9-1.3,2.1c0,1.3,0.2,2.1,1.3,2.1c0.5,0,1.4-0.2,1.7-0.3l0.3,0.2v1.4
		L20.6,60.9z"
        />
        <path
          fill="#FFFFFF"
          d="M26.9,61h-5.2l-0.2-0.2v-1.2l3.1-4.4H22l-0.2-0.2v-1.2l0.2-0.2h4.9l0.2,0.2V55l-3,4.4h2.8l0.2,0.2v1.2L26.9,61
		z"
        />
        <path
          fill="#FFFFFF"
          d="M32.8,61h-0.2c-0.5,0.2-1.3,0.6-1.3,1.1c0,0.2,0.1,0.3,0.3,0.3c0.3,0,0.8-0.2,0.9-0.3l0.3,0.2v0.8l-0.1,0.2
		c-0.3,0.2-0.8,0.4-1.4,0.4c-1,0-1.5-0.4-1.5-1.4c0-0.5,0.4-0.9,0.8-1.2h-2.1l-0.2-0.2v-7l0.2-0.2h4.3l0.2,0.2V55l-0.2,0.2h-2.3v1
		h2.1l0.2,0.2v1.2l-0.2,0.2h-2.1v1.4h2.4l0.2,0.2v1.2L32.8,61z"
        />
        <path
          fill="#FFFFFF"
          d="M36.5,61.2c-1,0-1.7-0.2-2.3-0.3L34,60.6v-1.4l0.3-0.2c0.4,0.1,1.7,0.3,2.1,0.3c0.3,0,0.5-0.1,0.5-0.3
		c0-0.9-3-0.8-3-3.5c0-1.6,1.2-2.2,2.7-2.2c0.9,0,1.7,0.1,2.1,0.3l0.2,0.2v1.3l-0.2,0.2c-0.4-0.1-1.4-0.3-2-0.3
		c-0.4,0-0.5,0.1-0.5,0.3c0,0.9,3,0.7,3,3.4C39.3,60.6,38,61.2,36.5,61.2z M38.2,52.2l-2.6,1l-0.3-0.1l-0.4-0.7L35,52l2.3-1.4
		l0.3,0.1l0.6,1.1L38.2,52.2z"
        />
        <path
          fill="#FFFFFF"
          d="M45.2,61h-4.5l-0.2-0.2v-7l0.2-0.2h1.8l0.2,0.2v5.6h2.5l0.2,0.2v1.2L45.2,61z"
        />
        <path
          fill="#FFFFFF"
          d="M48.4,61h-1.8l-0.2-0.2v-7l0.2-0.2h1.8l0.2,0.2v7L48.4,61z"
        />
        <path
          fill="#FFFFFF"
          d="M58.2,60.8L57.9,61h-2.1l-0.2-0.2l-0.7-4.2l-0.8,4.2L53.9,61h-2.1l-0.2-0.2l-2-6.9l0.2-0.3l1.9-0.2l0.2,0.2
		l1,4.9l0.7-4.7l0.2-0.2h2l0.2,0.2l0.8,4.7l1.1-4.9l0.2-0.2l1.8,0.2l0.2,0.3L58.2,60.8z"
        />
        <path
          fill="#FFFFFF"
          d="M63.1,61h-1.8l-0.2-0.2v-7l0.2-0.2h1.8l0.2,0.2v7L63.1,61z"
        />
      </g>
      <g>
        <path
          fill="#FFFFFF"
          d="M32.2,71.1h-4.5l-0.2-0.2v-7l0.2-0.2h1.8l0.2,0.2v5.6h2.5l0.2,0.2v1.2L32.2,71.1z"
        />
        <path
          fill="#FFFFFF"
          d="M39.7,71l-1.6,0.3l-0.3-0.2c0-0.2-0.2-0.8-0.2-1c-0.5,0.5-1.3,1.1-2.4,1.1c-1.3,0-2-0.9-2-2.5v-4.9l0.2-0.2
		h1.8l0.2,0.2v4.7c0,0.6,0.1,0.9,0.5,0.9c0.5,0,1.1-0.4,1.3-0.7v-5l0.2-0.2h1.8l0.2,0.2v5.3c0,0.4,0.3,1.3,0.4,1.5L39.7,71z"
        />
        <path
          fill="#FFFFFF"
          d="M43.9,71.1h-2.6L41,70.9v-7l0.2-0.2H44c2.6,0,3.6,1.5,3.6,3.6C47.6,69.5,46.5,71.1,43.9,71.1z M43.9,65.3h-0.7
		v4.1h0.7c0.8,0,1.3-0.7,1.3-2.1C45.2,66,44.9,65.3,43.9,65.3z"
        />
        <path
          fill="#FFFFFF"
          d="M53.4,71.1h-5.2l-0.2-0.2v-1.2l3.1-4.4h-2.5L48.2,65v-1.2l0.2-0.2h4.9l0.2,0.2v1.2l-3,4.4h2.8l0.2,0.2v1.2
		L53.4,71.1z"
        />
        <path
          fill="#FFFFFF"
          d="M56.6,71.1h-1.8l-0.2-0.2v-7l0.2-0.2h1.8l0.2,0.2v7L56.6,71.1z"
        />
        <path
          fill="#FFFFFF"
          d="M63,71.1h-4.4l-0.2-0.2v-7l0.2-0.2h4.3l0.2,0.2v1.2l-0.2,0.2h-2.3v1h2.1l0.2,0.2v1.2L62.7,68h-2.1v1.4H63
		l0.2,0.2v1.2L63,71.1z"
        />
      </g>
      <g>
        <path
          fill="#F1D169"
          d="M37.4,39.7h-2.6l-0.2-0.2v-7l0.2-0.2h2.7c2.6,0,3.6,1.5,3.6,3.6C41.1,38.1,40,39.7,37.4,39.7z M37.4,33.9h-0.7
		V38h0.7c0.8,0,1.3-0.7,1.3-2.1C38.8,34.6,38.4,33.9,37.4,33.9z"
        />
        <path
          fill="#F1D169"
          d="M45.6,39.9c-2.5,0-3.7-1.4-3.7-3.9c0-2.4,1.1-4,3.8-4c2.6,0,3.7,1.6,3.7,3.9C49.4,38.4,48.2,39.9,45.6,39.9z
		 M45.6,33.8c-1.1,0-1.4,1-1.4,2.1c0,1.1,0.3,2.1,1.4,2.1s1.4-1,1.4-2.1C47,34.8,46.7,33.8,45.6,33.8z"
        />
        <path
          fill="#F1D169"
          d="M53.4,39.7h-2.6l-0.2-0.2v-7l0.2-0.2h2.6c1.8,0,2.8,0.6,2.8,2c0,0.6-0.5,1.4-1,1.5c0.6,0.1,1.2,0.9,1.2,1.8
		C56.4,39.2,55,39.7,53.4,39.7z M53.3,33.8h-0.5V35h0.5c0.4,0,0.7-0.1,0.7-0.6C54,33.9,53.8,33.8,53.3,33.8z M53.4,36.7h-0.7v1.3
		h0.6c0.4,0,0.8-0.2,0.8-0.7C54.1,36.9,53.9,36.7,53.4,36.7z"
        />
        <path
          fill="#F1D169"
          d="M63.5,39.5l-1.7,0.4l-0.3-0.2l-1.7-2.9h-0.2v2.7l-0.2,0.2h-1.8l-0.2-0.2v-7l0.2-0.2h3c1.9,0,2.8,0.7,2.8,2.2
		c0,1.3-0.9,1.8-1.5,2l1.7,2.8L63.5,39.5z M60.5,33.8h-0.8v1.3h0.5c0.5,0,1.1,0,1.1-0.6C61.2,34,61.1,33.8,60.5,33.8z"
        />
        <path
          fill="#F1D169"
          d="M68.3,37.6v1.9l-0.2,0.2h-1.8l-0.2-0.2v-1.9l-2.5-4.9l0.2-0.3l1.8-0.4l0.3,0.2l1.4,3.4l1.6-3.4l0.3-0.2
		l1.6,0.4l0.2,0.3L68.3,37.6z"
        />
      </g>
    </svg>
  );
}

const MemoThumbsupPolish = React.memo(ThumbsupPolish);
export default MemoThumbsupPolish;
