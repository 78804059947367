import * as React from 'react';

function QR() {
  return (
    <svg
      width="529"
      height="530"
      viewBox="0 0 529 530"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M144.672 0.648193H128.192V17.1282H144.672V0.648193Z"
        fill="black"
      />
      <path
        d="M176.672 0.648193H160.192V17.1282H176.672V0.648193Z"
        fill="black"
      />
      <path
        d="M208.672 0.648193H192.192V17.1282H208.672V0.648193Z"
        fill="black"
      />
      <path
        d="M272.672 0.648193H256.192V17.1282H272.672V0.648193Z"
        fill="black"
      />
      <path
        d="M288.672 0.648193H272.192V17.1282H288.672V0.648193Z"
        fill="black"
      />
      <path
        d="M320.672 0.648193H304.192V17.1282H320.672V0.648193Z"
        fill="black"
      />
      <path
        d="M336.672 0.648193H320.192V17.1282H336.672V0.648193Z"
        fill="black"
      />
      <path
        d="M352.672 0.648193H336.192V17.1282H352.672V0.648193Z"
        fill="black"
      />
      <path
        d="M368.672 0.648193H352.192V17.1282H368.672V0.648193Z"
        fill="black"
      />
      <path
        d="M384.672 0.648193H368.192V17.1282H384.672V0.648193Z"
        fill="black"
      />
      <path
        d="M160.672 16.6482H144.192V33.1282H160.672V16.6482Z"
        fill="black"
      />
      <path
        d="M176.672 16.6482H160.192V33.1282H176.672V16.6482Z"
        fill="black"
      />
      <path
        d="M272.672 16.6482H256.192V33.1282H272.672V16.6482Z"
        fill="black"
      />
      <path
        d="M288.672 16.6482H272.192V33.1282H288.672V16.6482Z"
        fill="black"
      />
      <path
        d="M160.672 32.6482H144.192V49.1282H160.672V32.6482Z"
        fill="black"
      />
      <path
        d="M192.672 32.6482H176.192V49.1282H192.672V32.6482Z"
        fill="black"
      />
      <path
        d="M272.672 32.6482H256.192V49.1282H272.672V32.6482Z"
        fill="black"
      />
      <path
        d="M288.672 32.6482H272.192V49.1282H288.672V32.6482Z"
        fill="black"
      />
      <path
        d="M304.672 32.6482H288.192V49.1282H304.672V32.6482Z"
        fill="black"
      />
      <path
        d="M320.672 32.6482H304.192V49.1282H320.672V32.6482Z"
        fill="black"
      />
      <path
        d="M336.672 32.6482H320.192V49.1282H336.672V32.6482Z"
        fill="black"
      />
      <path
        d="M352.672 32.6482H336.192V49.1282H352.672V32.6482Z"
        fill="black"
      />
      <path
        d="M384.672 32.6482H368.192V49.1282H384.672V32.6482Z"
        fill="black"
      />
      <path
        d="M400.672 32.6482H384.192V49.1282H400.672V32.6482Z"
        fill="black"
      />
      <path
        d="M160.672 48.6482H144.192V65.1282H160.672V48.6482Z"
        fill="black"
      />
      <path
        d="M192.672 48.6482H176.192V65.1282H192.672V48.6482Z"
        fill="black"
      />
      <path
        d="M240.672 48.6482H224.192V65.1282H240.672V48.6482Z"
        fill="black"
      />
      <path
        d="M256.672 48.6482H240.192V65.1282H256.672V48.6482Z"
        fill="black"
      />
      <path
        d="M320.672 48.6482H304.192V65.1282H320.672V48.6482Z"
        fill="black"
      />
      <path
        d="M336.672 48.6482H320.192V65.1282H336.672V48.6482Z"
        fill="black"
      />
      <path
        d="M352.672 48.6482H336.192V65.1282H352.672V48.6482Z"
        fill="black"
      />
      <path
        d="M144.672 64.6482H128.192V81.1282H144.672V64.6482Z"
        fill="black"
      />
      <path
        d="M192.672 64.6482H176.192V81.1282H192.672V64.6482Z"
        fill="black"
      />
      <path
        d="M224.672 64.6482H208.192V81.1282H224.672V64.6482Z"
        fill="black"
      />
      <path
        d="M240.672 64.6482H224.192V81.1282H240.672V64.6482Z"
        fill="black"
      />
      <path
        d="M304.672 64.6482H288.192V81.1282H304.672V64.6482Z"
        fill="black"
      />
      <path
        d="M352.672 64.6482H336.192V81.1282H352.672V64.6482Z"
        fill="black"
      />
      <path
        d="M384.672 64.6482H368.192V81.1282H384.672V64.6482Z"
        fill="black"
      />
      <path
        d="M400.672 64.6482H384.192V81.1282H400.672V64.6482Z"
        fill="black"
      />
      <path
        d="M144.672 80.6482H128.192V97.1282H144.672V80.6482Z"
        fill="black"
      />
      <path
        d="M160.672 80.6482H144.192V97.1282H160.672V80.6482Z"
        fill="black"
      />
      <path
        d="M192.672 80.6482H176.192V97.1282H192.672V80.6482Z"
        fill="black"
      />
      <path
        d="M208.672 80.6482H192.192V97.1282H208.672V80.6482Z"
        fill="black"
      />
      <path
        d="M240.672 80.6482H224.192V97.1282H240.672V80.6482Z"
        fill="black"
      />
      <path
        d="M256.672 80.6482H240.192V97.1282H256.672V80.6482Z"
        fill="black"
      />
      <path
        d="M288.672 80.6482H272.192V97.1282H288.672V80.6482Z"
        fill="black"
      />
      <path
        d="M336.672 80.6482H320.192V97.1282H336.672V80.6482Z"
        fill="black"
      />
      <path
        d="M352.672 80.6482H336.192V97.1282H352.672V80.6482Z"
        fill="black"
      />
      <path
        d="M368.672 80.6482H352.192V97.1282H368.672V80.6482Z"
        fill="black"
      />
      <path
        d="M144.672 96.6482H128.192V113.128H144.672V96.6482Z"
        fill="black"
      />
      <path
        d="M176.672 96.6482H160.192V113.128H176.672V96.6482Z"
        fill="black"
      />
      <path
        d="M208.672 96.6482H192.192V113.128H208.672V96.6482Z"
        fill="black"
      />
      <path
        d="M240.672 96.6482H224.192V113.128H240.672V96.6482Z"
        fill="black"
      />
      <path
        d="M272.672 96.6482H256.192V113.128H272.672V96.6482Z"
        fill="black"
      />
      <path
        d="M304.672 96.6482H288.192V113.128H304.672V96.6482Z"
        fill="black"
      />
      <path
        d="M336.672 96.6482H320.192V113.128H336.672V96.6482Z"
        fill="black"
      />
      <path
        d="M368.672 96.6482H352.192V113.128H368.672V96.6482Z"
        fill="black"
      />
      <path
        d="M400.672 96.6482H384.192V113.128H400.672V96.6482Z"
        fill="black"
      />
      <path
        d="M160.672 112.648H144.192V129.128H160.672V112.648Z"
        fill="black"
      />
      <path
        d="M224.672 112.648H208.192V129.128H224.672V112.648Z"
        fill="black"
      />
      <path
        d="M240.672 112.648H224.192V129.128H240.672V112.648Z"
        fill="black"
      />
      <path
        d="M352.672 112.648H336.192V129.128H352.672V112.648Z"
        fill="black"
      />
      <path d="M32.672 128.648H16.192V145.128H32.672V128.648Z" fill="black" />
      <path d="M48.672 128.648H32.192V145.128H48.672V128.648Z" fill="black" />
      <path d="M64.672 128.648H48.192V145.128H64.672V128.648Z" fill="black" />
      <path d="M80.672 128.648H64.192V145.128H80.672V128.648Z" fill="black" />
      <path d="M96.672 128.648H80.192V145.128H96.672V128.648Z" fill="black" />
      <path d="M112.672 128.648H96.192V145.128H112.672V128.648Z" fill="black" />
      <path
        d="M128.672 128.648H112.192V145.128H128.672V128.648Z"
        fill="black"
      />
      <path
        d="M160.672 128.648H144.192V145.128H160.672V128.648Z"
        fill="black"
      />
      <path
        d="M240.672 128.648H224.192V145.128H240.672V128.648Z"
        fill="black"
      />
      <path
        d="M256.672 128.648H240.192V145.128H256.672V128.648Z"
        fill="black"
      />
      <path
        d="M288.672 128.648H272.192V145.128H288.672V128.648Z"
        fill="black"
      />
      <path
        d="M368.672 128.648H352.192V145.128H368.672V128.648Z"
        fill="black"
      />
      <path
        d="M384.672 128.648H368.192V145.128H384.672V128.648Z"
        fill="black"
      />
      <path
        d="M448.672 128.648H432.192V145.128H448.672V128.648Z"
        fill="black"
      />
      <path
        d="M464.672 128.648H448.192V145.128H464.672V128.648Z"
        fill="black"
      />
      <path
        d="M528.672 128.648H512.192V145.128H528.672V128.648Z"
        fill="black"
      />
      <path d="M16.672 144.648H0.192001V161.128H16.672V144.648Z" fill="black" />
      <path d="M32.672 144.648H16.192V161.128H32.672V144.648Z" fill="black" />
      <path d="M48.672 144.648H32.192V161.128H48.672V144.648Z" fill="black" />
      <path d="M80.672 144.648H64.192V161.128H80.672V144.648Z" fill="black" />
      <path
        d="M144.672 144.648H128.192V161.128H144.672V144.648Z"
        fill="black"
      />
      <path
        d="M176.672 144.648H160.192V161.128H176.672V144.648Z"
        fill="black"
      />
      <path
        d="M192.672 144.648H176.192V161.128H192.672V144.648Z"
        fill="black"
      />
      <path
        d="M208.672 144.648H192.192V161.128H208.672V144.648Z"
        fill="black"
      />
      <path
        d="M224.672 144.648H208.192V161.128H224.672V144.648Z"
        fill="black"
      />
      <path
        d="M240.672 144.648H224.192V161.128H240.672V144.648Z"
        fill="black"
      />
      <path
        d="M256.672 144.648H240.192V161.128H256.672V144.648Z"
        fill="black"
      />
      <path
        d="M272.672 144.648H256.192V161.128H272.672V144.648Z"
        fill="black"
      />
      <path
        d="M304.672 144.648H288.192V161.128H304.672V144.648Z"
        fill="black"
      />
      <path
        d="M320.672 144.648H304.192V161.128H320.672V144.648Z"
        fill="black"
      />
      <path
        d="M384.672 144.648H368.192V161.128H384.672V144.648Z"
        fill="black"
      />
      <path
        d="M432.672 144.648H416.192V161.128H432.672V144.648Z"
        fill="black"
      />
      <path
        d="M448.672 144.648H432.192V161.128H448.672V144.648Z"
        fill="black"
      />
      <path
        d="M480.672 144.648H464.192V161.128H480.672V144.648Z"
        fill="black"
      />
      <path
        d="M496.672 144.648H480.192V161.128H496.672V144.648Z"
        fill="black"
      />
      <path
        d="M528.672 144.648H512.192V161.128H528.672V144.648Z"
        fill="black"
      />
      <path d="M16.672 160.648H0.192001V177.128H16.672V160.648Z" fill="black" />
      <path d="M32.672 160.648H16.192V177.128H32.672V160.648Z" fill="black" />
      <path d="M48.672 160.648H32.192V177.128H48.672V160.648Z" fill="black" />
      <path d="M64.672 160.648H48.192V177.128H64.672V160.648Z" fill="black" />
      <path d="M80.672 160.648H64.192V177.128H80.672V160.648Z" fill="black" />
      <path d="M112.672 160.648H96.192V177.128H112.672V160.648Z" fill="black" />
      <path
        d="M160.672 160.648H144.192V177.128H160.672V160.648Z"
        fill="black"
      />
      <path
        d="M176.672 160.648H160.192V177.128H176.672V160.648Z"
        fill="black"
      />
      <path
        d="M192.672 160.648H176.192V177.128H192.672V160.648Z"
        fill="black"
      />
      <path
        d="M208.672 160.648H192.192V177.128H208.672V160.648Z"
        fill="black"
      />
      <path
        d="M224.672 160.648H208.192V177.128H224.672V160.648Z"
        fill="black"
      />
      <path
        d="M304.672 160.648H288.192V177.128H304.672V160.648Z"
        fill="black"
      />
      <path
        d="M336.672 160.648H320.192V177.128H336.672V160.648Z"
        fill="black"
      />
      <path
        d="M416.672 160.648H400.192V177.128H416.672V160.648Z"
        fill="black"
      />
      <path
        d="M464.672 160.648H448.192V177.128H464.672V160.648Z"
        fill="black"
      />
      <path
        d="M496.672 160.648H480.192V177.128H496.672V160.648Z"
        fill="black"
      />
      <path
        d="M512.672 160.648H496.192V177.128H512.672V160.648Z"
        fill="black"
      />
      <path d="M16.672 176.648H0.192001V193.128H16.672V176.648Z" fill="black" />
      <path
        d="M128.672 176.648H112.192V193.128H128.672V176.648Z"
        fill="black"
      />
      <path
        d="M160.672 176.648H144.192V193.128H160.672V176.648Z"
        fill="black"
      />
      <path
        d="M208.672 176.648H192.192V193.128H208.672V176.648Z"
        fill="black"
      />
      <path
        d="M240.672 176.648H224.192V193.128H240.672V176.648Z"
        fill="black"
      />
      <path
        d="M320.672 176.648H304.192V193.128H320.672V176.648Z"
        fill="black"
      />
      <path
        d="M336.672 176.648H320.192V193.128H336.672V176.648Z"
        fill="black"
      />
      <path
        d="M352.672 176.648H336.192V193.128H352.672V176.648Z"
        fill="black"
      />
      <path
        d="M432.672 176.648H416.192V193.128H432.672V176.648Z"
        fill="black"
      />
      <path
        d="M464.672 176.648H448.192V193.128H464.672V176.648Z"
        fill="black"
      />
      <path
        d="M480.672 176.648H464.192V193.128H480.672V176.648Z"
        fill="black"
      />
      <path
        d="M496.672 176.648H480.192V193.128H496.672V176.648Z"
        fill="black"
      />
      <path
        d="M528.672 176.648H512.192V193.128H528.672V176.648Z"
        fill="black"
      />
      <path d="M32.672 192.648H16.192V209.128H32.672V192.648Z" fill="black" />
      <path d="M48.672 192.648H32.192V209.128H48.672V192.648Z" fill="black" />
      <path d="M64.672 192.648H48.192V209.128H64.672V192.648Z" fill="black" />
      <path d="M96.672 192.648H80.192V209.128H96.672V192.648Z" fill="black" />
      <path d="M112.672 192.648H96.192V209.128H112.672V192.648Z" fill="black" />
      <path
        d="M144.672 192.648H128.192V209.128H144.672V192.648Z"
        fill="black"
      />
      <path
        d="M176.672 192.648H160.192V209.128H176.672V192.648Z"
        fill="black"
      />
      <path
        d="M192.672 192.648H176.192V209.128H192.672V192.648Z"
        fill="black"
      />
      <path
        d="M256.672 192.648H240.192V209.128H256.672V192.648Z"
        fill="black"
      />
      <path
        d="M304.672 192.648H288.192V209.128H304.672V192.648Z"
        fill="black"
      />
      <path
        d="M352.672 192.648H336.192V209.128H352.672V192.648Z"
        fill="black"
      />
      <path
        d="M416.672 192.648H400.192V209.128H416.672V192.648Z"
        fill="black"
      />
      <path
        d="M464.672 192.648H448.192V209.128H464.672V192.648Z"
        fill="black"
      />
      <path
        d="M480.672 192.648H464.192V209.128H480.672V192.648Z"
        fill="black"
      />
      <path
        d="M512.672 192.648H496.192V209.128H512.672V192.648Z"
        fill="black"
      />
      <path
        d="M528.672 192.648H512.192V209.128H528.672V192.648Z"
        fill="black"
      />
      <path d="M32.672 208.648H16.192V225.128H32.672V208.648Z" fill="black" />
      <path d="M64.672 208.648H48.192V225.128H64.672V208.648Z" fill="black" />
      <path d="M96.672 208.648H80.192V225.128H96.672V208.648Z" fill="black" />
      <path
        d="M160.672 208.648H144.192V225.128H160.672V208.648Z"
        fill="black"
      />
      <path
        d="M192.672 208.648H176.192V225.128H192.672V208.648Z"
        fill="black"
      />
      <path
        d="M224.672 208.648H208.192V225.128H224.672V208.648Z"
        fill="black"
      />
      <path
        d="M240.672 208.648H224.192V225.128H240.672V208.648Z"
        fill="black"
      />
      <path
        d="M272.672 208.648H256.192V225.128H272.672V208.648Z"
        fill="black"
      />
      <path
        d="M336.672 208.648H320.192V225.128H336.672V208.648Z"
        fill="black"
      />
      <path
        d="M352.672 208.648H336.192V225.128H352.672V208.648Z"
        fill="black"
      />
      <path
        d="M384.672 208.648H368.192V225.128H384.672V208.648Z"
        fill="black"
      />
      <path
        d="M400.672 208.648H384.192V225.128H400.672V208.648Z"
        fill="black"
      />
      <path
        d="M432.672 208.648H416.192V225.128H432.672V208.648Z"
        fill="black"
      />
      <path
        d="M496.672 208.648H480.192V225.128H496.672V208.648Z"
        fill="black"
      />
      <path
        d="M512.672 208.648H496.192V225.128H512.672V208.648Z"
        fill="black"
      />
      <path
        d="M528.672 208.648H512.192V225.128H528.672V208.648Z"
        fill="black"
      />
      <path d="M48.672 224.648H32.192V241.128H48.672V224.648Z" fill="black" />
      <path d="M80.672 224.648H64.192V241.128H80.672V224.648Z" fill="black" />
      <path d="M112.672 224.648H96.192V241.128H112.672V224.648Z" fill="black" />
      <path
        d="M128.672 224.648H112.192V241.128H128.672V224.648Z"
        fill="black"
      />
      <path
        d="M144.672 224.648H128.192V241.128H144.672V224.648Z"
        fill="black"
      />
      <path
        d="M208.672 224.648H192.192V241.128H208.672V224.648Z"
        fill="black"
      />
      <path
        d="M224.672 224.648H208.192V241.128H224.672V224.648Z"
        fill="black"
      />
      <path
        d="M240.672 224.648H224.192V241.128H240.672V224.648Z"
        fill="black"
      />
      <path
        d="M272.672 224.648H256.192V241.128H272.672V224.648Z"
        fill="black"
      />
      <path
        d="M320.672 224.648H304.192V241.128H320.672V224.648Z"
        fill="black"
      />
      <path
        d="M416.672 224.648H400.192V241.128H416.672V224.648Z"
        fill="black"
      />
      <path
        d="M432.672 224.648H416.192V241.128H432.672V224.648Z"
        fill="black"
      />
      <path
        d="M464.672 224.648H448.192V241.128H464.672V224.648Z"
        fill="black"
      />
      <path
        d="M480.672 224.648H464.192V241.128H480.672V224.648Z"
        fill="black"
      />
      <path
        d="M496.672 224.648H480.192V241.128H496.672V224.648Z"
        fill="black"
      />
      <path
        d="M512.672 224.648H496.192V241.128H512.672V224.648Z"
        fill="black"
      />
      <path
        d="M144.672 240.648H128.192V257.128H144.672V240.648Z"
        fill="black"
      />
      <path
        d="M160.672 240.648H144.192V257.128H160.672V240.648Z"
        fill="black"
      />
      <path
        d="M176.672 240.648H160.192V257.128H176.672V240.648Z"
        fill="black"
      />
      <path
        d="M192.672 240.648H176.192V257.128H192.672V240.648Z"
        fill="black"
      />
      <path
        d="M224.672 240.648H208.192V257.128H224.672V240.648Z"
        fill="black"
      />
      <path
        d="M272.672 240.648H256.192V257.128H272.672V240.648Z"
        fill="black"
      />
      <path
        d="M288.672 240.648H272.192V257.128H288.672V240.648Z"
        fill="black"
      />
      <path
        d="M320.672 240.648H304.192V257.128H320.672V240.648Z"
        fill="black"
      />
      <path
        d="M336.672 240.648H320.192V257.128H336.672V240.648Z"
        fill="black"
      />
      <path
        d="M352.672 240.648H336.192V257.128H352.672V240.648Z"
        fill="black"
      />
      <path
        d="M368.672 240.648H352.192V257.128H368.672V240.648Z"
        fill="black"
      />
      <path
        d="M384.672 240.648H368.192V257.128H384.672V240.648Z"
        fill="black"
      />
      <path
        d="M416.672 240.648H400.192V257.128H416.672V240.648Z"
        fill="black"
      />
      <path
        d="M432.672 240.648H416.192V257.128H432.672V240.648Z"
        fill="black"
      />
      <path
        d="M464.672 240.648H448.192V257.128H464.672V240.648Z"
        fill="black"
      />
      <path
        d="M480.672 240.648H464.192V257.128H480.672V240.648Z"
        fill="black"
      />
      <path
        d="M496.672 240.648H480.192V257.128H496.672V240.648Z"
        fill="black"
      />
      <path d="M32.672 256.648H16.192V273.128H32.672V256.648Z" fill="black" />
      <path d="M80.672 256.648H64.192V273.128H80.672V256.648Z" fill="black" />
      <path d="M96.672 256.648H80.192V273.128H96.672V256.648Z" fill="black" />
      <path d="M112.672 256.648H96.192V273.128H112.672V256.648Z" fill="black" />
      <path
        d="M160.672 256.648H144.192V273.128H160.672V256.648Z"
        fill="black"
      />
      <path
        d="M208.672 256.648H192.192V273.128H208.672V256.648Z"
        fill="black"
      />
      <path
        d="M224.672 256.648H208.192V273.128H224.672V256.648Z"
        fill="black"
      />
      <path
        d="M256.672 256.648H240.192V273.128H256.672V256.648Z"
        fill="black"
      />
      <path
        d="M288.672 256.648H272.192V273.128H288.672V256.648Z"
        fill="black"
      />
      <path
        d="M304.672 256.648H288.192V273.128H304.672V256.648Z"
        fill="black"
      />
      <path
        d="M320.672 256.648H304.192V273.128H320.672V256.648Z"
        fill="black"
      />
      <path
        d="M336.672 256.648H320.192V273.128H336.672V256.648Z"
        fill="black"
      />
      <path
        d="M368.672 256.648H352.192V273.128H368.672V256.648Z"
        fill="black"
      />
      <path
        d="M384.672 256.648H368.192V273.128H384.672V256.648Z"
        fill="black"
      />
      <path
        d="M400.672 256.648H384.192V273.128H400.672V256.648Z"
        fill="black"
      />
      <path
        d="M416.672 256.648H400.192V273.128H416.672V256.648Z"
        fill="black"
      />
      <path
        d="M448.672 256.648H432.192V273.128H448.672V256.648Z"
        fill="black"
      />
      <path
        d="M464.672 256.648H448.192V273.128H464.672V256.648Z"
        fill="black"
      />
      <path
        d="M480.672 256.648H464.192V273.128H480.672V256.648Z"
        fill="black"
      />
      <path
        d="M528.672 256.648H512.192V273.128H528.672V256.648Z"
        fill="black"
      />
      <path d="M32.672 272.648H16.192V289.128H32.672V272.648Z" fill="black" />
      <path d="M80.672 272.648H64.192V289.128H80.672V272.648Z" fill="black" />
      <path
        d="M144.672 272.648H128.192V289.128H144.672V272.648Z"
        fill="black"
      />
      <path
        d="M160.672 272.648H144.192V289.128H160.672V272.648Z"
        fill="black"
      />
      <path
        d="M272.672 272.648H256.192V289.128H272.672V272.648Z"
        fill="black"
      />
      <path
        d="M304.672 272.648H288.192V289.128H304.672V272.648Z"
        fill="black"
      />
      <path
        d="M320.672 272.648H304.192V289.128H320.672V272.648Z"
        fill="black"
      />
      <path
        d="M336.672 272.648H320.192V289.128H336.672V272.648Z"
        fill="black"
      />
      <path
        d="M384.672 272.648H368.192V289.128H384.672V272.648Z"
        fill="black"
      />
      <path
        d="M432.672 272.648H416.192V289.128H432.672V272.648Z"
        fill="black"
      />
      <path
        d="M448.672 272.648H432.192V289.128H448.672V272.648Z"
        fill="black"
      />
      <path
        d="M480.672 272.648H464.192V289.128H480.672V272.648Z"
        fill="black"
      />
      <path
        d="M496.672 272.648H480.192V289.128H496.672V272.648Z"
        fill="black"
      />
      <path
        d="M528.672 272.648H512.192V289.128H528.672V272.648Z"
        fill="black"
      />
      <path d="M32.672 288.648H16.192V305.128H32.672V288.648Z" fill="black" />
      <path d="M64.672 288.648H48.192V305.128H64.672V288.648Z" fill="black" />
      <path d="M80.672 288.648H64.192V305.128H80.672V288.648Z" fill="black" />
      <path d="M96.672 288.648H80.192V305.128H96.672V288.648Z" fill="black" />
      <path d="M112.672 288.648H96.192V305.128H112.672V288.648Z" fill="black" />
      <path
        d="M144.672 288.648H128.192V305.128H144.672V288.648Z"
        fill="black"
      />
      <path
        d="M176.672 288.648H160.192V305.128H176.672V288.648Z"
        fill="black"
      />
      <path
        d="M192.672 288.648H176.192V305.128H192.672V288.648Z"
        fill="black"
      />
      <path
        d="M224.672 288.648H208.192V305.128H224.672V288.648Z"
        fill="black"
      />
      <path
        d="M240.672 288.648H224.192V305.128H240.672V288.648Z"
        fill="black"
      />
      <path
        d="M288.672 288.648H272.192V305.128H288.672V288.648Z"
        fill="black"
      />
      <path
        d="M304.672 288.648H288.192V305.128H304.672V288.648Z"
        fill="black"
      />
      <path
        d="M336.672 288.648H320.192V305.128H336.672V288.648Z"
        fill="black"
      />
      <path
        d="M368.672 288.648H352.192V305.128H368.672V288.648Z"
        fill="black"
      />
      <path
        d="M400.672 288.648H384.192V305.128H400.672V288.648Z"
        fill="black"
      />
      <path
        d="M432.672 288.648H416.192V305.128H432.672V288.648Z"
        fill="black"
      />
      <path
        d="M448.672 288.648H432.192V305.128H448.672V288.648Z"
        fill="black"
      />
      <path
        d="M464.672 288.648H448.192V305.128H464.672V288.648Z"
        fill="black"
      />
      <path
        d="M496.672 288.648H480.192V305.128H496.672V288.648Z"
        fill="black"
      />
      <path d="M16.672 304.648H0.192001V321.128H16.672V304.648Z" fill="black" />
      <path d="M64.672 304.648H48.192V321.128H64.672V304.648Z" fill="black" />
      <path
        d="M128.672 304.648H112.192V321.128H128.672V304.648Z"
        fill="black"
      />
      <path
        d="M144.672 304.648H128.192V321.128H144.672V304.648Z"
        fill="black"
      />
      <path
        d="M160.672 304.648H144.192V321.128H160.672V304.648Z"
        fill="black"
      />
      <path
        d="M176.672 304.648H160.192V321.128H176.672V304.648Z"
        fill="black"
      />
      <path
        d="M192.672 304.648H176.192V321.128H192.672V304.648Z"
        fill="black"
      />
      <path
        d="M208.672 304.648H192.192V321.128H208.672V304.648Z"
        fill="black"
      />
      <path
        d="M256.672 304.648H240.192V321.128H256.672V304.648Z"
        fill="black"
      />
      <path
        d="M272.672 304.648H256.192V321.128H272.672V304.648Z"
        fill="black"
      />
      <path
        d="M288.672 304.648H272.192V321.128H288.672V304.648Z"
        fill="black"
      />
      <path
        d="M336.672 304.648H320.192V321.128H336.672V304.648Z"
        fill="black"
      />
      <path
        d="M384.672 304.648H368.192V321.128H384.672V304.648Z"
        fill="black"
      />
      <path
        d="M416.672 304.648H400.192V321.128H416.672V304.648Z"
        fill="black"
      />
      <path
        d="M448.672 304.648H432.192V321.128H448.672V304.648Z"
        fill="black"
      />
      <path
        d="M464.672 304.648H448.192V321.128H464.672V304.648Z"
        fill="black"
      />
      <path
        d="M480.672 304.648H464.192V321.128H480.672V304.648Z"
        fill="black"
      />
      <path
        d="M496.672 304.648H480.192V321.128H496.672V304.648Z"
        fill="black"
      />
      <path
        d="M528.672 304.648H512.192V321.128H528.672V304.648Z"
        fill="black"
      />
      <path d="M16.672 320.648H0.192001V337.128H16.672V320.648Z" fill="black" />
      <path d="M32.672 320.648H16.192V337.128H32.672V320.648Z" fill="black" />
      <path d="M48.672 320.648H32.192V337.128H48.672V320.648Z" fill="black" />
      <path d="M80.672 320.648H64.192V337.128H80.672V320.648Z" fill="black" />
      <path d="M96.672 320.648H80.192V337.128H96.672V320.648Z" fill="black" />
      <path d="M112.672 320.648H96.192V337.128H112.672V320.648Z" fill="black" />
      <path
        d="M160.672 320.648H144.192V337.128H160.672V320.648Z"
        fill="black"
      />
      <path
        d="M176.672 320.648H160.192V337.128H176.672V320.648Z"
        fill="black"
      />
      <path
        d="M208.672 320.648H192.192V337.128H208.672V320.648Z"
        fill="black"
      />
      <path
        d="M288.672 320.648H272.192V337.128H288.672V320.648Z"
        fill="black"
      />
      <path
        d="M304.672 320.648H288.192V337.128H304.672V320.648Z"
        fill="black"
      />
      <path
        d="M336.672 320.648H320.192V337.128H336.672V320.648Z"
        fill="black"
      />
      <path
        d="M352.672 320.648H336.192V337.128H352.672V320.648Z"
        fill="black"
      />
      <path
        d="M368.672 320.648H352.192V337.128H368.672V320.648Z"
        fill="black"
      />
      <path
        d="M384.672 320.648H368.192V337.128H384.672V320.648Z"
        fill="black"
      />
      <path
        d="M400.672 320.648H384.192V337.128H400.672V320.648Z"
        fill="black"
      />
      <path
        d="M416.672 320.648H400.192V337.128H416.672V320.648Z"
        fill="black"
      />
      <path
        d="M464.672 320.648H448.192V337.128H464.672V320.648Z"
        fill="black"
      />
      <path
        d="M480.672 320.648H464.192V337.128H480.672V320.648Z"
        fill="black"
      />
      <path d="M16.672 336.648H0.192001V353.128H16.672V336.648Z" fill="black" />
      <path d="M64.672 336.648H48.192V353.128H64.672V336.648Z" fill="black" />
      <path d="M80.672 336.648H64.192V353.128H80.672V336.648Z" fill="black" />
      <path
        d="M160.672 336.648H144.192V353.128H160.672V336.648Z"
        fill="black"
      />
      <path
        d="M192.672 336.648H176.192V353.128H192.672V336.648Z"
        fill="black"
      />
      <path
        d="M224.672 336.648H208.192V353.128H224.672V336.648Z"
        fill="black"
      />
      <path
        d="M240.672 336.648H224.192V353.128H240.672V336.648Z"
        fill="black"
      />
      <path
        d="M272.672 336.648H256.192V353.128H272.672V336.648Z"
        fill="black"
      />
      <path
        d="M304.672 336.648H288.192V353.128H304.672V336.648Z"
        fill="black"
      />
      <path
        d="M352.672 336.648H336.192V353.128H352.672V336.648Z"
        fill="black"
      />
      <path
        d="M384.672 336.648H368.192V353.128H384.672V336.648Z"
        fill="black"
      />
      <path
        d="M432.672 336.648H416.192V353.128H432.672V336.648Z"
        fill="black"
      />
      <path
        d="M448.672 336.648H432.192V353.128H448.672V336.648Z"
        fill="black"
      />
      <path
        d="M496.672 336.648H480.192V353.128H496.672V336.648Z"
        fill="black"
      />
      <path
        d="M528.672 336.648H512.192V353.128H528.672V336.648Z"
        fill="black"
      />
      <path d="M16.672 352.648H0.192001V369.128H16.672V352.648Z" fill="black" />
      <path d="M48.672 352.648H32.192V369.128H48.672V352.648Z" fill="black" />
      <path d="M64.672 352.648H48.192V369.128H64.672V352.648Z" fill="black" />
      <path d="M80.672 352.648H64.192V369.128H80.672V352.648Z" fill="black" />
      <path d="M112.672 352.648H96.192V369.128H112.672V352.648Z" fill="black" />
      <path
        d="M128.672 352.648H112.192V369.128H128.672V352.648Z"
        fill="black"
      />
      <path
        d="M144.672 352.648H128.192V369.128H144.672V352.648Z"
        fill="black"
      />
      <path
        d="M160.672 352.648H144.192V369.128H160.672V352.648Z"
        fill="black"
      />
      <path
        d="M224.672 352.648H208.192V369.128H224.672V352.648Z"
        fill="black"
      />
      <path
        d="M272.672 352.648H256.192V369.128H272.672V352.648Z"
        fill="black"
      />
      <path
        d="M304.672 352.648H288.192V369.128H304.672V352.648Z"
        fill="black"
      />
      <path
        d="M336.672 352.648H320.192V369.128H336.672V352.648Z"
        fill="black"
      />
      <path
        d="M352.672 352.648H336.192V369.128H352.672V352.648Z"
        fill="black"
      />
      <path
        d="M432.672 352.648H416.192V369.128H432.672V352.648Z"
        fill="black"
      />
      <path
        d="M464.672 352.648H448.192V369.128H464.672V352.648Z"
        fill="black"
      />
      <path
        d="M496.672 352.648H480.192V369.128H496.672V352.648Z"
        fill="black"
      />
      <path
        d="M512.672 352.648H496.192V369.128H512.672V352.648Z"
        fill="black"
      />
      <path d="M16.672 368.648H0.192001V385.128H16.672V368.648Z" fill="black" />
      <path d="M48.672 368.648H32.192V385.128H48.672V368.648Z" fill="black" />
      <path d="M64.672 368.648H48.192V385.128H64.672V368.648Z" fill="black" />
      <path d="M80.672 368.648H64.192V385.128H80.672V368.648Z" fill="black" />
      <path
        d="M128.672 368.648H112.192V385.128H128.672V368.648Z"
        fill="black"
      />
      <path
        d="M208.672 368.648H192.192V385.128H208.672V368.648Z"
        fill="black"
      />
      <path
        d="M256.672 368.648H240.192V385.128H256.672V368.648Z"
        fill="black"
      />
      <path
        d="M288.672 368.648H272.192V385.128H288.672V368.648Z"
        fill="black"
      />
      <path
        d="M304.672 368.648H288.192V385.128H304.672V368.648Z"
        fill="black"
      />
      <path
        d="M352.672 368.648H336.192V385.128H352.672V368.648Z"
        fill="black"
      />
      <path
        d="M368.672 368.648H352.192V385.128H368.672V368.648Z"
        fill="black"
      />
      <path
        d="M464.672 368.648H448.192V385.128H464.672V368.648Z"
        fill="black"
      />
      <path
        d="M480.672 368.648H464.192V385.128H480.672V368.648Z"
        fill="black"
      />
      <path
        d="M496.672 368.648H480.192V385.128H496.672V368.648Z"
        fill="black"
      />
      <path
        d="M528.672 368.648H512.192V385.128H528.672V368.648Z"
        fill="black"
      />
      <path d="M16.672 384.648H0.192001V401.128H16.672V384.648Z" fill="black" />
      <path d="M64.672 384.648H48.192V401.128H64.672V384.648Z" fill="black" />
      <path d="M80.672 384.648H64.192V401.128H80.672V384.648Z" fill="black" />
      <path d="M112.672 384.648H96.192V401.128H112.672V384.648Z" fill="black" />
      <path
        d="M144.672 384.648H128.192V401.128H144.672V384.648Z"
        fill="black"
      />
      <path
        d="M240.672 384.648H224.192V401.128H240.672V384.648Z"
        fill="black"
      />
      <path
        d="M256.672 384.648H240.192V401.128H256.672V384.648Z"
        fill="black"
      />
      <path
        d="M288.672 384.648H272.192V401.128H288.672V384.648Z"
        fill="black"
      />
      <path
        d="M368.672 384.648H352.192V401.128H368.672V384.648Z"
        fill="black"
      />
      <path
        d="M400.672 384.648H384.192V401.128H400.672V384.648Z"
        fill="black"
      />
      <path
        d="M416.672 384.648H400.192V401.128H416.672V384.648Z"
        fill="black"
      />
      <path
        d="M432.672 384.648H416.192V401.128H432.672V384.648Z"
        fill="black"
      />
      <path
        d="M448.672 384.648H432.192V401.128H448.672V384.648Z"
        fill="black"
      />
      <path
        d="M464.672 384.648H448.192V401.128H464.672V384.648Z"
        fill="black"
      />
      <path
        d="M480.672 384.648H464.192V401.128H480.672V384.648Z"
        fill="black"
      />
      <path
        d="M512.672 384.648H496.192V401.128H512.672V384.648Z"
        fill="black"
      />
      <path
        d="M144.672 400.648H128.192V417.128H144.672V400.648Z"
        fill="black"
      />
      <path
        d="M160.672 400.648H144.192V417.128H160.672V400.648Z"
        fill="black"
      />
      <path
        d="M176.672 400.648H160.192V417.128H176.672V400.648Z"
        fill="black"
      />
      <path
        d="M208.672 400.648H192.192V417.128H208.672V400.648Z"
        fill="black"
      />
      <path
        d="M240.672 400.648H224.192V417.128H240.672V400.648Z"
        fill="black"
      />
      <path
        d="M320.672 400.648H304.192V417.128H320.672V400.648Z"
        fill="black"
      />
      <path
        d="M336.672 400.648H320.192V417.128H336.672V400.648Z"
        fill="black"
      />
      <path
        d="M352.672 400.648H336.192V417.128H352.672V400.648Z"
        fill="black"
      />
      <path
        d="M368.672 400.648H352.192V417.128H368.672V400.648Z"
        fill="black"
      />
      <path
        d="M400.672 400.648H384.192V417.128H400.672V400.648Z"
        fill="black"
      />
      <path
        d="M464.672 400.648H448.192V417.128H464.672V400.648Z"
        fill="black"
      />
      <path
        d="M496.672 400.648H480.192V417.128H496.672V400.648Z"
        fill="black"
      />
      <path
        d="M528.672 400.648H512.192V417.128H528.672V400.648Z"
        fill="black"
      />
      <path
        d="M144.672 416.648H128.192V433.128H144.672V416.648Z"
        fill="black"
      />
      <path
        d="M192.672 416.648H176.192V433.128H192.672V416.648Z"
        fill="black"
      />
      <path
        d="M208.672 416.648H192.192V433.128H208.672V416.648Z"
        fill="black"
      />
      <path
        d="M272.672 416.648H256.192V433.128H272.672V416.648Z"
        fill="black"
      />
      <path
        d="M288.672 416.648H272.192V433.128H288.672V416.648Z"
        fill="black"
      />
      <path
        d="M352.672 416.648H336.192V433.128H352.672V416.648Z"
        fill="black"
      />
      <path
        d="M384.672 416.648H368.192V433.128H384.672V416.648Z"
        fill="black"
      />
      <path
        d="M400.672 416.648H384.192V433.128H400.672V416.648Z"
        fill="black"
      />
      <path
        d="M432.672 416.648H416.192V433.128H432.672V416.648Z"
        fill="black"
      />
      <path
        d="M464.672 416.648H448.192V433.128H464.672V416.648Z"
        fill="black"
      />
      <path
        d="M496.672 416.648H480.192V433.128H496.672V416.648Z"
        fill="black"
      />
      <path
        d="M512.672 416.648H496.192V433.128H512.672V416.648Z"
        fill="black"
      />
      <path
        d="M144.672 432.648H128.192V449.128H144.672V432.648Z"
        fill="black"
      />
      <path
        d="M192.672 432.648H176.192V449.128H192.672V432.648Z"
        fill="black"
      />
      <path
        d="M240.672 432.648H224.192V449.128H240.672V432.648Z"
        fill="black"
      />
      <path
        d="M256.672 432.648H240.192V449.128H256.672V432.648Z"
        fill="black"
      />
      <path
        d="M272.672 432.648H256.192V449.128H272.672V432.648Z"
        fill="black"
      />
      <path
        d="M304.672 432.648H288.192V449.128H304.672V432.648Z"
        fill="black"
      />
      <path
        d="M352.672 432.648H336.192V449.128H352.672V432.648Z"
        fill="black"
      />
      <path
        d="M368.672 432.648H352.192V449.128H368.672V432.648Z"
        fill="black"
      />
      <path
        d="M384.672 432.648H368.192V449.128H384.672V432.648Z"
        fill="black"
      />
      <path
        d="M400.672 432.648H384.192V449.128H400.672V432.648Z"
        fill="black"
      />
      <path
        d="M464.672 432.648H448.192V449.128H464.672V432.648Z"
        fill="black"
      />
      <path
        d="M480.672 432.648H464.192V449.128H480.672V432.648Z"
        fill="black"
      />
      <path
        d="M496.672 432.648H480.192V449.128H496.672V432.648Z"
        fill="black"
      />
      <path
        d="M512.672 432.648H496.192V449.128H512.672V432.648Z"
        fill="black"
      />
      <path
        d="M144.672 448.648H128.192V465.128H144.672V448.648Z"
        fill="black"
      />
      <path
        d="M160.672 448.648H144.192V465.128H160.672V448.648Z"
        fill="black"
      />
      <path
        d="M192.672 448.648H176.192V465.128H192.672V448.648Z"
        fill="black"
      />
      <path
        d="M224.672 448.648H208.192V465.128H224.672V448.648Z"
        fill="black"
      />
      <path
        d="M240.672 448.648H224.192V465.128H240.672V448.648Z"
        fill="black"
      />
      <path
        d="M272.672 448.648H256.192V465.128H272.672V448.648Z"
        fill="black"
      />
      <path
        d="M320.672 448.648H304.192V465.128H320.672V448.648Z"
        fill="black"
      />
      <path
        d="M352.672 448.648H336.192V465.128H352.672V448.648Z"
        fill="black"
      />
      <path
        d="M400.672 448.648H384.192V465.128H400.672V448.648Z"
        fill="black"
      />
      <path
        d="M416.672 448.648H400.192V465.128H416.672V448.648Z"
        fill="black"
      />
      <path
        d="M432.672 448.648H416.192V465.128H432.672V448.648Z"
        fill="black"
      />
      <path
        d="M448.672 448.648H432.192V465.128H448.672V448.648Z"
        fill="black"
      />
      <path
        d="M464.672 448.648H448.192V465.128H464.672V448.648Z"
        fill="black"
      />
      <path
        d="M480.672 448.648H464.192V465.128H480.672V448.648Z"
        fill="black"
      />
      <path
        d="M512.672 448.648H496.192V465.128H512.672V448.648Z"
        fill="black"
      />
      <path
        d="M144.672 464.648H128.192V481.128H144.672V464.648Z"
        fill="black"
      />
      <path
        d="M192.672 464.648H176.192V481.128H192.672V464.648Z"
        fill="black"
      />
      <path
        d="M224.672 464.648H208.192V481.128H224.672V464.648Z"
        fill="black"
      />
      <path
        d="M240.672 464.648H224.192V481.128H240.672V464.648Z"
        fill="black"
      />
      <path
        d="M256.672 464.648H240.192V481.128H256.672V464.648Z"
        fill="black"
      />
      <path
        d="M304.672 464.648H288.192V481.128H304.672V464.648Z"
        fill="black"
      />
      <path
        d="M336.672 464.648H320.192V481.128H336.672V464.648Z"
        fill="black"
      />
      <path
        d="M352.672 464.648H336.192V481.128H352.672V464.648Z"
        fill="black"
      />
      <path
        d="M368.672 464.648H352.192V481.128H368.672V464.648Z"
        fill="black"
      />
      <path
        d="M384.672 464.648H368.192V481.128H384.672V464.648Z"
        fill="black"
      />
      <path
        d="M416.672 464.648H400.192V481.128H416.672V464.648Z"
        fill="black"
      />
      <path
        d="M464.672 464.648H448.192V481.128H464.672V464.648Z"
        fill="black"
      />
      <path
        d="M496.672 464.648H480.192V481.128H496.672V464.648Z"
        fill="black"
      />
      <path
        d="M528.672 464.648H512.192V481.128H528.672V464.648Z"
        fill="black"
      />
      <path
        d="M144.672 480.648H128.192V497.128H144.672V480.648Z"
        fill="black"
      />
      <path
        d="M192.672 480.648H176.192V497.128H192.672V480.648Z"
        fill="black"
      />
      <path
        d="M224.672 480.648H208.192V497.128H224.672V480.648Z"
        fill="black"
      />
      <path
        d="M256.672 480.648H240.192V497.128H256.672V480.648Z"
        fill="black"
      />
      <path
        d="M272.672 480.648H256.192V497.128H272.672V480.648Z"
        fill="black"
      />
      <path
        d="M304.672 480.648H288.192V497.128H304.672V480.648Z"
        fill="black"
      />
      <path
        d="M320.672 480.648H304.192V497.128H320.672V480.648Z"
        fill="black"
      />
      <path
        d="M384.672 480.648H368.192V497.128H384.672V480.648Z"
        fill="black"
      />
      <path
        d="M432.672 480.648H416.192V497.128H432.672V480.648Z"
        fill="black"
      />
      <path
        d="M448.672 480.648H432.192V497.128H448.672V480.648Z"
        fill="black"
      />
      <path
        d="M496.672 480.648H480.192V497.128H496.672V480.648Z"
        fill="black"
      />
      <path
        d="M144.672 496.648H128.192V513.128H144.672V496.648Z"
        fill="black"
      />
      <path
        d="M192.672 496.648H176.192V513.128H192.672V496.648Z"
        fill="black"
      />
      <path
        d="M272.672 496.648H256.192V513.128H272.672V496.648Z"
        fill="black"
      />
      <path
        d="M304.672 496.648H288.192V513.128H304.672V496.648Z"
        fill="black"
      />
      <path
        d="M320.672 496.648H304.192V513.128H320.672V496.648Z"
        fill="black"
      />
      <path
        d="M336.672 496.648H320.192V513.128H336.672V496.648Z"
        fill="black"
      />
      <path
        d="M352.672 496.648H336.192V513.128H352.672V496.648Z"
        fill="black"
      />
      <path
        d="M368.672 496.648H352.192V513.128H368.672V496.648Z"
        fill="black"
      />
      <path
        d="M416.672 496.648H400.192V513.128H416.672V496.648Z"
        fill="black"
      />
      <path
        d="M464.672 496.648H448.192V513.128H464.672V496.648Z"
        fill="black"
      />
      <path
        d="M496.672 496.648H480.192V513.128H496.672V496.648Z"
        fill="black"
      />
      <path
        d="M192.672 512.648H176.192V529.128H192.672V512.648Z"
        fill="black"
      />
      <path
        d="M208.672 512.648H192.192V529.128H208.672V512.648Z"
        fill="black"
      />
      <path
        d="M224.672 512.648H208.192V529.128H224.672V512.648Z"
        fill="black"
      />
      <path
        d="M240.672 512.648H224.192V529.128H240.672V512.648Z"
        fill="black"
      />
      <path
        d="M256.672 512.648H240.192V529.128H256.672V512.648Z"
        fill="black"
      />
      <path
        d="M320.672 512.648H304.192V529.128H320.672V512.648Z"
        fill="black"
      />
      <path
        d="M368.672 512.648H352.192V529.128H368.672V512.648Z"
        fill="black"
      />
      <path
        d="M384.672 512.648H368.192V529.128H384.672V512.648Z"
        fill="black"
      />
      <path
        d="M400.672 512.648H384.192V529.128H400.672V512.648Z"
        fill="black"
      />
      <path
        d="M448.672 512.648H432.192V529.128H448.672V512.648Z"
        fill="black"
      />
      <path
        d="M512.672 512.648H496.192V529.128H512.672V512.648Z"
        fill="black"
      />
      <path
        d="M0.399994 0.600098V112.6H112.4V0.600098H0.399994V0.600098ZM96.4 96.6001H16.4V16.6001H96.4V96.6001Z"
        fill="black"
      />
      <path
        d="M416.4 0.600098V112.6H528.4V0.600098H416.4V0.600098ZM512.4 96.6001H432.4V16.6001H512.4V96.6001Z"
        fill="black"
      />
      <path
        d="M0.399994 416.6V528.6H112.4V416.6H0.399994V416.6ZM96.4 512.6H16.4V432.6H96.4V512.6Z"
        fill="black"
      />
      <path d="M80.4 32.6001H32.4V80.6001H80.4V32.6001Z" fill="black" />
      <path d="M496.4 32.6001H448.4V80.6001H496.4V32.6001Z" fill="black" />
      <path d="M80.4 448.6H32.4V496.6H80.4V448.6Z" fill="black" />
    </svg>
  );
}

const MemoQR = React.memo(QR);
export default MemoQR;
