import { css, Stack, Box, Grid, SystemProps } from '@storyofams/react-ui';
import SbEditable from 'storyblok-react';

import { ContentImage } from '~components';
import section from '~styles/theme/section';

import { Title } from '../Blocks';
import { Container } from '../Container';
import { RichText } from '../RichText';
import { Sticker } from './Sticker';

type SimpleProps = {
  first?: boolean;
  content: {
    body?: any;
    background_colour: 'banana' | 'tiger' | 'fig' | 'aloe' | 'lapiz';
    title?: any;
    sticker_type?:
      | 'boundaries'
      | 'cashews'
      | 'check'
      | 'factory'
      | 'good'
      | 'journey'
      | 'nuts'
      | 'promise';
    sticker_alignment?: 'left' | 'right';
    lapiz_bold_colour?: boolean;
  };
} & SystemProps;

export interface SectionProps {
  content: any;
  first?: boolean;
  background_colour: 'banana' | 'tiger' | 'fig' | 'aloe' | 'lapiz';
  sectionType: string;
}

const Item = ({
  content,
  sectionType,
  background_colour,
  first,
}: SectionProps) => {
  const { bold, link, paragraph, title } =
    section[background_colour] || section.banana;
  let item = null;

  switch (sectionType) {
    case 'image':
      item = <ContentImage height={[200, 430]} content={content?.image} />;
      break;
    case 'gallery':
      item = (
        <Grid
          width="100%"
          columnGap={[3, 5]}
          rowGap={[3, 5]}
          rowSize={2}
          mt={first && [-180, -375]}
        >
          <Box
            flex={[
              !(
                content?.images?.[1]?.image?.filename &&
                !content?.images?.[2]?.image?.filename &&
                !content?.images?.[3]?.image?.filename
              ) && '0 1 100% !important',
            ]}
          >
            <ContentImage
              height={[200, 430]}
              content={content?.images?.[0]?.image}
            />
          </Box>
          {content?.images?.[1]?.image?.filename && (
            <Stack flexDirection="column" space={[3, 5]}>
              <ContentImage
                height={
                  (content?.images?.[2]?.image?.filename &&
                    content?.images?.[3]?.image?.filename) ||
                  (content?.images?.[2]?.image?.filename &&
                    !content?.images?.[3]?.image?.filename)
                    ? [88, 195]
                    : [200, 430]
                }
                content={content?.images?.[1]?.image}
              />
              {content?.images?.[2]?.image?.filename &&
                content?.images?.[3]?.image?.filename && (
                  <ContentImage
                    height={[88, 195]}
                    content={content?.images?.[2]?.image}
                  />
                )}
            </Stack>
          )}
          {content?.images?.[2]?.image?.filename &&
            !content?.images?.[3]?.image?.filename && (
              <Box>
                <ContentImage
                  height={[88, 195]}
                  content={content?.images?.[2]?.image}
                />
              </Box>
            )}
          {content?.images?.[3]?.image?.filename && (
            <Box>
              <ContentImage
                height={[200, 430]}
                content={content?.images?.[3]?.image}
              />
            </Box>
          )}
        </Grid>
      );
      break;
    case 'rich paragraph':
      item = (
        <RichText
          titleColor={title}
          paragraphColor={paragraph}
          boldColor={bold}
          linkColor={link}
          css={css({
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
          })}
          text={content?.text}
        />
      );
  }

  return item;
};

export const Simple = ({ content, first, ...props }: SimpleProps) => {
  const { bg, bold, title } =
    section[content.background_colour] || section.banana;

  return (
    <Container
      textAlign="center"
      bgColor={bg}
      childProps={{
        pt:
          (first && content?.body?.[0]?.component === 'rich paragraph') ||
          (first && content?.title?.content?.[0].content)
            ? [148, 280]
            : [10, 20],
      }}
      {...props}
    >
      {!!content?.title?.content?.[0].content && (
        <Title
          text={content?.title}
          boldColor={!!content?.lapiz_bold_colour ? 'lapiz' : bold}
          titleColor={title}
          fontSize={[5, 7]}
          h1={first}
        />
      )}
      {content?.body?.map((section, i) => (
        <SbEditable content={section} key={`section-${i}`}>
          <>
            <Item
              background_colour={content?.background_colour}
              sectionType={section.component}
              content={section}
              first={i === 0}
            />
          </>
        </SbEditable>
      ))}
      {content?.sticker_type && (
        <Sticker
          align={content?.sticker_alignment}
          type={content?.sticker_type}
          mt={
            content?.body?.[0]?.component === 'gallery' && [
              '-120px !important',
              '0 !important',
            ]
          }
        />
      )}
    </Container>
  );
};
