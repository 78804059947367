import * as React from 'react';

function Youtube() {
  return (
    <svg
      width="44"
      height="43"
      viewBox="0 0 44 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37.9813 7.20183C44.6299 9.22215 43.6613 15.432 43.3963 21.6666C43.2555 24.9785 43.7523 30.1368 42.3034 33.2168C41.3761 35.1791 39.5131 35.2535 37.6005 35.3364C28.203 35.7752 18.648 36.1645 9.25885 35.7339C3.94321 35.4855 0.358147 33.8875 1.01226 28.6795C0.589914 23.6619 0.962583 18.3546 1.48428 13.4283C1.7576 10.8615 2.31217 8.2866 5.06122 7.44209C6.99863 6.83765 9.48266 7.45871 11.5028 7.37579C14.1109 7.27644 16.6942 6.91208 19.3106 6.87902C25.3217 6.79628 31.3328 6.92039 37.344 6.95363C37.6252 6.95344 37.8239 7.06111 37.9813 7.20183ZM28.8405 20.2259C26.5057 18.9426 24.1542 17.6841 21.7695 16.4999C20.246 15.7382 18.7143 14.9847 17.1577 14.2728C17.0914 14.2397 16.843 14.1568 16.5699 14.0575C17.2406 18.5367 16.6526 23.4715 16.4622 28.0585C20.7097 25.6243 24.7998 22.9748 28.8405 20.2259Z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoYoutube = React.memo(Youtube);
export default MemoYoutube;
