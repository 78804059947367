import * as React from 'react';

function Instagram() {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32.1715 7.00803C27.9009 6.97294 23.6216 6.80596 19.3422 6.9114C14.3861 7.03448 10.5198 7.33318 7.59367 11.2963C6.82911 13.1855 6.44261 15.3912 6.25799 17.6935C6.2229 18.2999 6.18763 18.9237 6.14373 19.5652C5.98556 23.4754 6.24917 27.5089 6.16136 30.7073C6.57432 32.8075 7.38278 34.6967 8.89426 36.1378C13.903 40.9092 24.0961 39.4242 30.7743 39.1606C33.9817 37.9919 36.6529 37.0252 38.0852 33.361C39.2188 30.4787 39.0342 26.8057 39.1133 23.7127C39.2627 17.3505 39.5439 8.57223 32.1715 7.00803Z"
        fill="currentColor"
      />
      <path
        d="M30.212 9.27504C38.7443 10.3734 36.2401 22.7371 36.0028 28.7299C35.8709 32.0251 35.2823 34.5734 31.8817 35.7156C27.409 37.2183 20.8186 36.7173 16.258 36.0496C13.2088 35.6102 10.8187 34.6963 9.97516 31.533C8.63948 26.533 9.01735 19.7668 9.59729 14.6703C9.61493 14.5737 9.6412 14.5033 9.6851 14.4418C9.76408 6.81476 25.7744 8.70392 30.212 9.27504Z"
        fill="#EDD279"
      />
      <path
        d="M33.7532 13.9237C33.7709 11.8235 31.1522 11.1557 29.7462 12.4475C29.3683 12.7989 29.1925 13.335 29.1663 13.9149C29.3068 14.6618 29.755 15.3999 30.335 15.8217C31.6971 16.7795 33.7356 15.5054 33.7532 13.9237Z"
        fill="currentColor"
      />
      <path
        d="M29.8603 18.0097C26.8552 13.458 20.0713 13.0098 16.4247 17.0431C13.938 19.7934 13.7622 24.3715 15.6339 27.6228C15.7393 27.6579 15.836 27.7195 15.924 27.8249C18.9644 31.2167 24.2015 32.447 27.9625 29.3715C31.3191 26.6211 32.3031 21.709 29.8603 18.0097Z"
        fill="currentColor"
      />
      <path
        d="M17.8833 24.2487C18.9465 27.2364 22.725 29.6089 25.739 27.7636C31.8284 24.0378 24.6757 13.9151 19.6582 18.4052C17.8833 19.4508 17.1891 22.289 17.8833 24.2487Z"
        fill="#EDD279"
      />
    </svg>
  );
}

const MemoInstagram = React.memo(Instagram);
export default MemoInstagram;
