import * as React from 'react';

function Factory() {
  return (
    <svg
      width="66"
      height="77"
      viewBox="0 0 66 77"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.200012 1.4C0.200012 0.600002 0.800006 0 1.60001 0H17.2C18 0 18.6 0.600002 18.6 1.4V23.2L33.3 14.1C34.2 13.5 35.4 14.2 35.4 15.3V23.8L48.2 14.4C49.1 13.7 50.4 14.4 50.4 15.5V23.7L63.2 14.3C64.1 13.6 65.4 14.3 65.4 15.4V23.6V74.9C65.4 75.7 64.8 76.3 64 76.3H1.40001C0.600009 76.3 0 75.7 0 74.9V32.2V23.7V1.4H0.200012Z"
        fill="#84AC4A"
      />
      <path
        d="M7.1 18.4C7.2 18.1 7.6 18.1 7.7 18.4C8 19.1 8.5 20.2 9.1 20.8C9.7 21.4 10.8 21.9 11.5 22.2C11.8 22.3 11.8 22.7 11.5 22.8C10.8 23.1 9.7 23.6 9.1 24.2C8.5 24.8 8 25.9 7.7 26.6C7.6 26.9 7.2 26.9 7.1 26.6C6.8 25.9 6.3 24.8 5.7 24.2C5.1 23.6 3.99999 23.1 3.29999 22.8C3.09999 22.7 3.09999 22.3 3.29999 22.2C3.99999 21.9 5.1 21.4 5.7 20.8C6.3 20.2 6.8 19.2 7.1 18.4Z"
        fill="white"
      />
      <path
        d="M9.10001 10.4C9.20001 10.3 9.40001 10.3 9.40001 10.4C9.60001 10.8 9.90002 11.4 10.2 11.8C10.6 12.2 11.2 12.4 11.6 12.6C11.7 12.7 11.7 12.9 11.6 12.9C11.2 13.1 10.6 13.4 10.2 13.7C9.80002 14.1 9.60001 14.7 9.40001 15.1C9.30001 15.2 9.10001 15.2 9.10001 15.1C8.90001 14.7 8.60001 14.1 8.30001 13.7C8.00001 13.4 7.30001 13.1 6.90001 12.9C6.80001 12.8 6.80001 12.6 6.90001 12.6C7.30001 12.4 7.90001 12.1 8.30001 11.8C8.60001 11.5 8.90001 10.9 9.10001 10.4Z"
        fill="white"
      />
      <path
        d="M34.8 70.2C34.9 70 35.3 70 35.4 70.2C35.6 70.6 35.9 71.1 36.2 71.4C36.5 71.7 37 72 37.4 72.2C37.6 72.3 37.6 72.7 37.4 72.8C37 73 36.5 73.3 36.2 73.6C35.9 73.9 35.6 74.4 35.4 74.8C35.3 75 34.9 75 34.8 74.8C34.6 74.4 34.3 73.9 34 73.6C33.7 73.3 33.2 73 32.8 72.8C32.6 72.7 32.6 72.3 32.8 72.2C33.2 72 33.7 71.7 34 71.4C34.4 71.1 34.6 70.7 34.8 70.2Z"
        fill="white"
      />
      <path
        d="M60.1 65.6C60.2 65.4 60.6 65.4 60.7 65.6C61 66.2 61.4 67 61.9 67.5C62.4 68 63.2 68.4 63.8 68.7C64.1 68.8 64.1 69.2 63.8 69.3C63.2 69.6 62.4 70 61.9 70.5C61.4 71 61 71.8 60.7 72.4C60.6 72.6 60.2 72.7 60.1 72.4C59.8 71.8 59.4 71 58.9 70.5C58.4 70 57.6 69.6 57 69.3C56.8 69.2 56.8 68.8 57 68.7C57.6 68.4 58.4 68 58.9 67.5C59.4 67 59.8 66.2 60.1 65.6Z"
        fill="white"
      />
      <path
        d="M7.40001 73.8C8.72549 73.8 9.8 72.7255 9.8 71.4C9.8 70.0745 8.72549 69 7.40001 69C6.07453 69 5 70.0745 5 71.4C5 72.7255 6.07453 73.8 7.40001 73.8Z"
        fill="#70913F"
      />
      <path
        d="M6.59999 64.1C7.31796 64.1 7.89999 63.518 7.89999 62.8C7.89999 62.082 7.31796 61.5 6.59999 61.5C5.88202 61.5 5.29999 62.082 5.29999 62.8C5.29999 63.518 5.88202 64.1 6.59999 64.1Z"
        fill="#70913F"
      />
      <path
        d="M3.8 68.8C4.68366 68.8 5.40001 68.0837 5.40001 67.2C5.40001 66.3164 4.68366 65.6 3.8 65.6C2.91635 65.6 2.20001 66.3164 2.20001 67.2C2.20001 68.0837 2.91635 68.8 3.8 68.8Z"
        fill="#70913F"
      />
      <path
        d="M61.3 38.7C62.6255 38.7 63.7 37.6255 63.7 36.3C63.7 34.9745 62.6255 33.9 61.3 33.9C59.9745 33.9 58.9 34.9745 58.9 36.3C58.9 37.6255 59.9745 38.7 61.3 38.7Z"
        fill="#70913F"
      />
      <path
        d="M60.2 32.6C60.918 32.6 61.5 32.018 61.5 31.3C61.5 30.582 60.918 30 60.2 30C59.482 30 58.9 30.582 58.9 31.3C58.9 32.018 59.482 32.6 60.2 32.6Z"
        fill="#70913F"
      />
      <path
        d="M62.7 27.5C63.418 27.5 64 26.918 64 26.2C64 25.482 63.418 24.9 62.7 24.9C61.982 24.9 61.4 25.482 61.4 26.2C61.4 26.918 61.982 27.5 62.7 27.5Z"
        fill="#70913F"
      />
      <path
        d="M17.9 36.9L16.1 37.2L15.8 37L15.3 35.2H13.1L12.5 37L12.2 37.2L10.6 36.9L10.4 36.6L13.1 29.6L13.4 29.3H15.2L15.5 29.6L18.1 36.6L17.9 36.9ZM14.2 31.7L13.6 33.4H14.7L14.2 31.7Z"
        fill="#EDD279"
      />
      <path
        d="M26.5 36.9C26.2 37 25.3 37.2 24.6 37.2C22.1 37.2 21 36 21 33.2C21 30.5 22.2 29.1 24.8 29.1C25.6 29.1 26.2 29.3 26.5 29.4L26.8 29.7V31L26.5 31.3C26.2 31.2 25.4 31 24.8 31C23.6 31 23.4 31.9 23.4 33.2C23.4 34.6 23.6 35.4 24.7 35.4C25.2 35.4 26.2 35.2 26.5 35.1L26.8 35.3V36.7L26.5 36.9Z"
        fill="white"
      />
      <path
        d="M32.7 37H28.1L27.8 36.7V29.5L28.1 29.2H29.9L30.2 29.5V35.2H32.8L33.1 35.5V36.8L32.7 37Z"
        fill="white"
      />
      <path
        d="M38.7 37H34.2L33.9 36.7V29.5L34.2 29.2H38.6L38.9 29.5V30.8L38.6 31.1H36.3V32.1H38.4L38.7 32.4V33.7L38.4 34H36.3V35.4H38.7L39 35.7V37H38.7Z"
        fill="white"
      />
      <path
        d="M46.8 36.9L45 37.2L44.7 37L44.2 35.2H42L41.4 37L41.1 37.2L39.5 36.9L39.3 36.6L42 29.6L42.3 29.3H44.1L44.4 29.6L47 36.6L46.8 36.9ZM43.1 31.7L42.5 33.4H43.6L43.1 31.7Z"
        fill="white"
      />
      <path
        d="M54.4 37H52L51.7 36.7L49.7 32.6L49.9 33.1V36.6L49.6 36.9H47.9L47.6 36.6V29.4L47.9 29.1H50.2L50.5 29.4L52.6 33.5L52.4 33V29.5L52.7 29.2H54.4L54.7 29.5V36.7L54.4 37Z"
        fill="white"
      />
      <path
        d="M13.9 41.2H11.7V42.4H13.7L14 42.7V44L13.7 44.3H11.7V47L11.5 47.3H9.59999L9.29999 47V39.8L9.59999 39.5H13.9L14.2 39.8V41.1L13.9 41.2Z"
        fill="#EDD279"
      />
      <path
        d="M21.4 47L19.6 47.3L19.3 47.1L18.8 45.3H16.6L16 47.1L15.7 47.3L14.1 47L13.9 46.7L16.6 39.7L16.9 39.4H18.7L19 39.7L21.6 46.7L21.4 47ZM17.7 41.8L17.1 43.5H18.2L17.7 41.8Z"
        fill="#EDD279"
      />
      <path
        d="M27.3 47C27 47.1 26.1 47.3 25.4 47.3C22.9 47.3 21.8 46.1 21.8 43.3C21.8 40.6 23 39.2 25.6 39.2C26.4 39.2 27 39.4 27.3 39.5L27.6 39.8V41.1L27.3 41.4C27 41.3 26.2 41.1 25.6 41.1C24.4 41.1 24.2 42 24.2 43.3C24.2 44.7 24.4 45.5 25.5 45.5C26 45.5 27 45.3 27.3 45.2L27.6 45.4V46.8L27.3 47Z"
        fill="#EDD279"
      />
      <path
        d="M34.1 41.2H32.3V46.9L32 47.2H30.2L29.9 46.9V41.2H28.1L27.8 40.9V39.6L28.1 39.3H33.9L34.2 39.6V40.9L34.1 41.2Z"
        fill="#EDD279"
      />
      <path
        d="M38.3 47.3C35.8 47.3 34.5 45.8 34.5 43.3C34.5 40.9 35.7 39.2 38.4 39.2C41 39.2 42.2 40.8 42.2 43.2C42.2 45.7 41 47.3 38.3 47.3ZM38.4 41.1C37.3 41.1 37 42.1 37 43.3C37 44.5 37.3 45.5 38.4 45.5C39.5 45.5 39.8 44.5 39.8 43.3C39.8 42.1 39.5 41.1 38.4 41.1Z"
        fill="#EDD279"
      />
      <path
        d="M49.5 46.9L47.8 47.3L47.5 47.1L45.8 44.1H45.6V46.8L45.3 47.1H43.5L43.2 46.8V39.6L43.5 39.3H46.5C48.4 39.3 49.4 40 49.4 41.5C49.4 42.8 48.5 43.3 47.8 43.6L49.6 46.4L49.5 46.9ZM46.4 41.1H45.6V42.4H46.1C46.6 42.4 47.2 42.4 47.2 41.7C47.2 41.2 47.1 41.1 46.4 41.1Z"
        fill="#EDD279"
      />
      <path
        d="M54.5 44.9V46.8L54.2 47.1H52.4L52.1 46.8V44.9L49.5 39.9L49.7 39.6L51.5 39.2L51.8 39.4L53.3 42.9L54.9 39.4L55.2 39.2L56.8 39.6L57 39.9L54.5 44.9Z"
        fill="#EDD279"
      />
      <path
        d="M5.39999 57.2H3.59999L3.29999 56.9V49.7L3.59999 49.4H5.39999L5.7 49.7V56.9L5.39999 57.2Z"
        fill="#EDD279"
      />
      <path
        d="M9.69998 57.4C8.59998 57.4 7.89998 57.2 7.39998 57.1L7.09998 56.8V55.4L7.39998 55.2C7.79998 55.3 9.09998 55.5 9.59998 55.5C9.89998 55.5 10.1 55.4 10.1 55.1C10.1 54.1 7.09998 54.3 7.09998 51.6C7.09998 50 8.29998 49.3 9.89998 49.3C10.8 49.3 11.6 49.4 12.1 49.6L12.4 49.9V51.2L12.1 51.5C11.7 51.4 10.6 51.2 10.1 51.2C9.69998 51.2 9.59998 51.3 9.59998 51.6C9.59998 52.5 12.7 52.3 12.7 55.1C12.5 56.7 11.3 57.4 9.69998 57.4Z"
        fill="#EDD279"
      />
      <path
        d="M23 57.1L21.2 57.4L20.9 57.2L20.4 55.4H18.2L17.6 57.2L17.3 57.4L15.7 57.1L15.5 56.8L18.2 49.8L18.5 49.5H20.3L20.6 49.8L23.2 56.8L23 57.1ZM19.4 51.9L18.8 53.6H19.9L19.4 51.9Z"
        fill="#EDD279"
      />
      <path
        d="M33 57.2H31.2L31 56.9V53.9H28.9V56.9L28.6 57.2H26.8L26.6 56.9V49.7L26.8 49.4H28.6L28.9 49.7V52.2H31V49.7L31.2 49.4H33L33.3 49.7V56.9L33 57.2Z"
        fill="white"
      />
      <path
        d="M41.5 57.1L39.7 57.4L39.4 57.2L38.9 55.4H36.7L36.1 57.2L35.8 57.4L34.2 57.1L34 56.8L36.7 49.8L37 49.5H38.8L39.1 49.8L41.7 56.8L41.5 57.1ZM37.9 51.9L37.3 53.6H38.4L37.9 51.9Z"
        fill="white"
      />
      <path
        d="M45.3 54.8H44.7V56.9L44.4 57.2H42.6L42.3 56.9V49.7L42.6 49.4H45.7C47.6 49.4 48.6 50.4 48.6 52C48.6 53.8 47.3 54.8 45.3 54.8ZM45.5 51.2H44.8V53.1H45.3C45.9 53.1 46.4 52.9 46.4 52.2C46.3 51.6 46.1 51.2 45.5 51.2Z"
        fill="white"
      />
      <path
        d="M52.5 54.8H51.9V56.9L51.6 57.2H49.8L49.5 56.9V49.7L49.8 49.4H52.9C54.8 49.4 55.8 50.4 55.8 52C55.8 53.8 54.5 54.8 52.5 54.8ZM52.6 51.2H51.9V53.1H52.4C53 53.1 53.5 52.9 53.5 52.2C53.5 51.6 53.2 51.2 52.6 51.2Z"
        fill="white"
      />
      <path
        d="M60.6 55V56.9L60.3 57.2H58.5L58.2 56.9V55L55.6 50L55.8 49.7L57.6 49.3L57.9 49.5L59.4 53L61 49.5L61.3 49.3L62.9 49.7L63.1 50L60.6 55Z"
        fill="white"
      />
      <path
        d="M14 61.4H11.8V62.6H13.8L14.1 62.9V64.2L13.8 64.5H11.8V67.2L11.6 67.5H9.7L9.39999 67.2V60L9.7 59.7H14L14.3 60V61.3L14 61.4Z"
        fill="#EDD279"
      />
      <path
        d="M21.4 67.2L19.6 67.5L19.3 67.3L18.8 65.5H16.6L16 67.3L15.7 67.5L14.1 67.2L13.9 66.9L16.6 59.9L16.9 59.6H18.7L19 59.9L21.6 66.9L21.4 67.2ZM17.8 62L17.2 63.7H18.3L17.8 62Z"
        fill="#EDD279"
      />
      <path
        d="M27.3 67.2C27 67.3 26.1 67.5 25.4 67.5C22.9 67.5 21.8 66.3 21.8 63.5C21.8 60.8 23 59.4 25.6 59.4C26.4 59.4 27 59.6 27.3 59.7L27.6 60V61.3L27.3 61.6C27 61.5 26.2 61.3 25.6 61.3C24.4 61.3 24.2 62.2 24.2 63.5C24.2 64.9 24.4 65.7 25.5 65.7C26 65.7 27 65.5 27.3 65.4L27.6 65.6V67L27.3 67.2Z"
        fill="#EDD279"
      />
      <path
        d="M34.1 61.4H32.3V67.1L32 67.4H30.2L29.9 67.1V61.4H28.1L27.8 61.1V59.8L28.1 59.5H33.9L34.2 59.8V61.1L34.1 61.4Z"
        fill="#EDD279"
      />
      <path
        d="M38.4 67.5C35.9 67.5 34.6 66 34.6 63.5C34.6 61.1 35.8 59.4 38.5 59.4C41.1 59.4 42.3 61 42.3 63.4C42.3 65.9 41 67.5 38.4 67.5ZM38.4 61.3C37.3 61.3 37 62.3 37 63.5C37 64.7 37.3 65.7 38.4 65.7C39.5 65.7 39.8 64.7 39.8 63.5C39.8 62.3 39.5 61.3 38.4 61.3Z"
        fill="#EDD279"
      />
      <path
        d="M49.5 67.1L47.8 67.5L47.5 67.3L45.8 64.3H45.6V67L45.3 67.3H43.5L43.2 67V59.8L43.5 59.5H46.5C48.4 59.5 49.4 60.2 49.4 61.7C49.4 63 48.5 63.5 47.8 63.8L49.6 66.6L49.5 67.1ZM46.5 61.3H45.7V62.6H46.2C46.7 62.6 47.3 62.6 47.3 61.9C47.2 61.4 47.1 61.3 46.5 61.3Z"
        fill="#EDD279"
      />
      <path
        d="M54.5 65.1V67L54.2 67.3H52.4L52.1 67V65.1L49.5 60.1L49.7 59.8L51.5 59.4L51.8 59.6L53.3 63.1L54.9 59.6L55.2 59.4L56.8 59.8L57 60.1L54.5 65.1Z"
        fill="#EDD279"
      />
    </svg>
  );
}

const MemoFactory = React.memo(Factory);
export default MemoFactory;
