import * as React from 'react';

function Marker() {
  return (
    <svg
      width="23"
      height="31"
      viewBox="0 0 23 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip11)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 10.8212C0 4.85435 5.15884 0 11.5 0C17.8412 0 23 4.85435 23 10.8212C23 14.0433 19.2314 21.3256 16.9703 24.1625C14.7092 26.9994 12.8846 30.2031 12.8846 30.2031C12.309 31.2656 10.6911 31.2657 10.1154 30.2031C10.1154 30.2031 5 24 3.40348 21.3256C1.80696 18.6512 0 14.0433 0 10.8212ZM7.71981 19.797C5.29052 16.6322 5.39324 13.4336 5.5511 12.2036C6.0271 8.96117 7.48739 6.54675 9.80188 5.36198C11.2522 4.61785 12.2234 4.72834 12.7834 4.95234C13.5798 5.27172 14.0874 6.15246 14.5015 6.95392C14.7614 7.45734 14.9471 7.9903 14.8533 8.56034C14.6781 9.63384 13.8782 10.3633 13.0975 11.0754C12.7533 11.3893 12.4129 11.6998 12.1313 12.0349C11.6424 12.6162 11.2032 13.3059 11.0892 14.0799C10.9762 14.8479 11.1892 15.6179 11.4855 16.3265C11.8178 17.1218 12.0876 17.9721 12.1132 18.8374C12.1536 20.2162 11.4174 21.3828 10.4724 21.439C9.06213 21.5231 7.77429 19.8675 7.71981 19.797Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip11">
          <rect width="23" height="31" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

const MemoMarker = React.memo(Marker);
export default MemoMarker;
