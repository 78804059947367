import * as React from 'react';

function Check() {
  return (
    <svg
      width="89"
      height="49"
      viewBox="0 0 89 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M78.3 11.9C74 8.30001 68.8 5.40002 63 3.50002C57.2 1.50002 50.9 0.400009 44.3 0.400009C37.7 0.400009 31.3 1.50002 25.6 3.50002C19.8 5.50002 14.7 8.40001 10.3 11.9C6 15.5 2.4 19.7 0 24.4C2.4 29.1 6 33.4 10.3 36.9C14.6 40.5 19.8 43.4 25.6 45.3C31.4 47.3 37.7 48.4 44.3 48.4C50.9 48.4 57.3 47.3 63 45.3C68.8 43.3 73.9 40.4 78.3 36.9C82.6 33.3 86.2 29.1 88.6 24.4C86.2 19.7 82.6 15.5 78.3 11.9Z"
        fill="#6A50A1"
      />
      <path
        d="M44.3 48.5C57.6101 48.5 68.4 37.7101 68.4 24.4C68.4 11.0899 57.6101 0.300018 44.3 0.300018C30.99 0.300018 20.2 11.0899 20.2 24.4C20.2 37.7101 30.99 48.5 44.3 48.5Z"
        fill="#795CB9"
      />
      <path
        d="M13.1 22.5H11.3V28.2L11 28.5H9.19999L8.89999 28.2V22.5H7.09999L6.79999 22.2V20.9L7.09999 20.6H12.9L13.2 20.9V22.2L13.1 22.5Z"
        fill="#EDD279"
      />
      <path
        d="M20.8 28.4H19L18.8 28.1V25.1H16.7V28.1L16.4 28.4H14.5L14.3 28.1V20.9L14.5 20.7H16.3L16.6 21V23.5H18.7V21L18.9 20.7H20.7L21 21V28.2L20.8 28.4Z"
        fill="#EDD279"
      />
      <path
        d="M24.6 28.4H22.8L22.5 28.1V20.9L22.8 20.6H24.6L24.9 20.9V28.1L24.6 28.4Z"
        fill="#EDD279"
      />
      <path
        d="M28.8 28.6C27.7 28.6 27 28.4 26.5 28.3L26.2 28V26.6L26.5 26.4C26.9 26.5 28.2 26.7 28.7 26.7C29 26.7 29.2 26.6 29.2 26.3C29.2 25.3 26.2 25.5 26.2 22.8C26.2 21.2 27.4 20.5 29 20.5C29.9 20.5 30.7 20.6 31.2 20.8L31.5 21.1V22.4L31.2 22.7C30.8 22.6 29.7 22.4 29.2 22.4C28.8 22.4 28.7 22.5 28.7 22.8C28.7 23.7 31.8 23.5 31.8 26.3C31.6 27.9 30.4 28.6 28.8 28.6Z"
        fill="#EDD279"
      />
      <path
        d="M40.6 28.3C40.3 28.4 39.4 28.6 38.7 28.6C36.2 28.6 35.1 27.4 35.1 24.6C35.1 21.9 36.3 20.5 38.9 20.5C39.7 20.5 40.3 20.7 40.6 20.8L40.9 21.1V22.4L40.6 22.7C40.3 22.6 39.5 22.4 38.9 22.4C37.7 22.4 37.5 23.3 37.5 24.6C37.5 26 37.7 26.8 38.8 26.8C39.3 26.8 40.3 26.6 40.6 26.5L40.9 26.7V28.1L40.6 28.3Z"
        fill="#EDD279"
      />
      <path
        d="M48.8 28.3L47 28.6L46.7 28.4L46.2 26.6H44L43.4 28.4L43.1 28.6L41.5 28.3L41.3 28L44 21L44.3 20.7H46.1L46.4 21L49 28L48.8 28.3ZM45.2 23.1L44.6 24.8H45.7L45.2 23.1Z"
        fill="#EDD279"
      />
      <path
        d="M52.2 28.6C51.1 28.6 50.4 28.4 49.9 28.3L49.6 28V26.6L49.9 26.4C50.3 26.5 51.6 26.7 52.1 26.7C52.4 26.7 52.6 26.6 52.6 26.3C52.6 25.3 49.6 25.5 49.6 22.8C49.6 21.2 50.8 20.5 52.4 20.5C53.3 20.5 54.1 20.6 54.6 20.8L54.9 21.1V22.4L54.6 22.7C54.2 22.6 53.1 22.4 52.6 22.4C52.2 22.4 52.1 22.5 52.1 22.8C52.1 23.7 55.2 23.5 55.2 26.3C55 27.9 53.7 28.6 52.2 28.6Z"
        fill="#EDD279"
      />
      <path
        d="M62.7 28.4H60.9L60.7 28.1V25.1H58.5V28.1L58.2 28.4H56.4L56.2 28.1V20.9L56.4 20.6H58.2L58.5 20.9V23.4H60.6V20.9L60.8 20.6H62.6L62.9 20.9V28.1L62.7 28.4Z"
        fill="#EDD279"
      />
      <path
        d="M69.2 28.4H64.7L64.4 28.1V20.9L64.7 20.6H69.1L69.4 20.9V22.2L69.1 22.5H66.8V23.5H68.9L69.2 23.8V25.1L68.9 25.4H66.8V26.8H69.2L69.5 27.1V28.4H69.2Z"
        fill="#EDD279"
      />
      <path
        d="M78.8 28.2L78.5 28.5H76.4L76.1 28.2L75.4 23.9L74.6 28.2L74.3 28.5H72.2L71.9 28.2L69.8 21.1L70 20.8L72 20.6L72.3 20.8L73.3 25.8L74 21L74.3 20.7H76.4L76.7 21L77.5 25.8L78.6 20.8L78.9 20.6L80.7 20.8L80.9 21.1L78.8 28.2Z"
        fill="#EDD279"
      />
      <path
        d="M36.6 38.4C34.1 38.4 32.8 36.9 32.8 34.4C32.8 32 34 30.3 36.7 30.3C39.3 30.3 40.5 31.9 40.5 34.3C40.5 36.9 39.2 38.4 36.6 38.4ZM36.6 32.2C35.5 32.2 35.2 33.2 35.2 34.4C35.2 35.6 35.5 36.6 36.6 36.6C37.7 36.6 38 35.6 38 34.4C38.1 33.3 37.7 32.2 36.6 32.2Z"
        fill="white"
      />
      <path
        d="M48.2 38.1L46.5 38.5L46.2 38.3C46.2 38.1 46 37.5 46 37.3C45.5 37.8 44.7 38.4 43.6 38.4C42.3 38.4 41.6 37.5 41.6 35.8V30.8L41.8 30.5H43.6L43.9 30.8V35.6C43.9 36.2 44 36.5 44.4 36.5C44.9 36.5 45.6 36.1 45.8 35.8V30.7L46 30.4H47.8L48.1 30.7V36.1C48.1 36.5 48.4 37.5 48.5 37.7L48.2 38.1Z"
        fill="white"
      />
      <path
        d="M55 32.3H53.2V38L52.9 38.3H51.1L50.8 38V32.3H49L48.7 32V30.7L49 30.4H54.8L55.1 30.7V32L55 32.3Z"
        fill="white"
      />
      <path
        d="M32.2 17.5C31.9 17.6 31 17.8 30.3 17.8C27.8 17.8 26.7 16.6 26.7 13.8C26.7 11.1 27.9 9.70001 30.5 9.70001C31.3 9.70001 31.9 9.90002 32.2 10L32.5 10.3V11.6L32.2 11.9C31.9 11.8 31.1 11.6 30.5 11.6C29.3 11.6 29.1 12.5 29.1 13.8C29.1 15.2 29.3 16 30.4 16C30.9 16 31.9 15.8 32.2 15.7L32.5 15.9V17.3L32.2 17.5Z"
        fill="white"
      />
      <path
        d="M40 17.7H38.2L38 17.4V14.4H35.9V17.4L35.6 17.7H33.8L33.6 17.4V10.2L33.8 9.90001H35.6L35.9 10.2V12.7H38V10.2L38.2 9.90001H40L40.3 10.2V17.4L40 17.7Z"
        fill="white"
      />
      <path
        d="M46.5 17.7H42L41.7 17.4V10.2L42 9.90001H46.4L46.7 10.2V11.5L46.4 11.8H44.1V12.8H46.2L46.5 13.1V14.4L46.2 14.7H44.1V16.1H46.5L46.8 16.4V17.7H46.5Z"
        fill="white"
      />
      <path
        d="M53 17.5C52.7 17.6 51.8 17.8 51.1 17.8C48.6 17.8 47.5 16.6 47.5 13.8C47.5 11.1 48.7 9.70001 51.3 9.70001C52.1 9.70001 52.7 9.90002 53 10L53.3 10.3V11.6L53 11.9C52.7 11.8 51.9 11.6 51.3 11.6C50.1 11.6 49.9 12.5 49.9 13.8C49.9 15.2 50.1 16 51.2 16C51.7 16 52.7 15.8 53 15.7L53.3 15.9V17.3L53 17.5Z"
        fill="white"
      />
      <path
        d="M56.4 17.7H54.6L54.3 17.4V10.2L54.6 9.90001H56.4L56.7 10.2V17.4L56.4 17.7ZM61.1 17.5L59.4 17.9L59.1 17.7L56.8 13.8V13.4L59.1 9.90001L59.4 9.80002L61 10.2L61.2 10.5L59 13.5L61.3 17.1L61.1 17.5Z"
        fill="white"
      />
    </svg>
  );
}

const MemoCheck = React.memo(Check);
export default MemoCheck;
