import { Text } from '@storyofams/react-ui';
import {
  MARK_BOLD,
  NODE_PARAGRAPH,
  MARK_LINK,
} from 'storyblok-rich-text-react-renderer';

import { RichText } from '../RichText';

export const Paragraph = ({
  paragraphColor = 'black',
  boldColor = 'fig',
  linkColor = 'lapiz',
  text,
  fontSize = [2, 2.5],
  ...props
}) => {
  return (
    <RichText
      text={text}
      {...props}
      markResolvers={{
        [MARK_BOLD]: (children) => (
          <Text color={boldColor} fontWeight="bold" as="span">
            {children}
          </Text>
        ),
        [MARK_LINK]: (children, props) => {
          const { href } = props;
          return (
            <Text color={linkColor} textDecoration="underline" as="span">
              <a href={href} target="_blank" rel="noopener noreferrer">
                {children}
              </a>
            </Text>
          );
        },
      }}
      nodeResolvers={{
        [NODE_PARAGRAPH]: (children) => (
          <Text
            color={paragraphColor}
            fontSize={fontSize}
            lineHeight="high"
            maxWidth="480px"
            pb={2}
          >
            {children}
          </Text>
        ),
      }}
    />
  );
};
