import * as React from 'react';

function Triangle() {
  return (
    <svg
      width="126"
      height="203"
      viewBox="0 0 126 203"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
    >
      <g>
        <path
          d="M0.481598 110.447C0.235028 152.296 31.1785 193.239 72.1489 201.354C84.8879 203.866 98.8187 203.248 109.955 196.534C121.092 189.82 128.529 176.063 125.201 163.459C120.968 147.395 103.955 140.722 94.7504 128.241C81.6826 110.529 72.4777 83.7557 82.5867 62.5428C91.052 44.8722 127.543 8.95445 90.0247 1.37545C85.751 0.510464 81.3539 0.0161552 76.998 0.510433C57.684 2.7347 33.5209 24.7714 23.2064 40.1766C9.44001 60.7305 0.645976 85.4857 0.481598 110.447Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

const MemoTriangle = React.memo(Triangle);
export default MemoTriangle;
