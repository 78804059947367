import { FC, ReactNode } from 'react';
import { StoryProvider } from '@storyofams/storyblok-toolkit';
import { LazyMotion } from 'framer-motion';
import { IntlProvider } from 'react-intl';
import { ThemeProvider } from 'styled-components';

import { CursorProvider } from '~/components';

import theme from '~styles/theme';

const loadFeatures = () =>
  import('./motionFeatures').then((res) => res.default);

type ProviderProps = {
  children: ReactNode;
  /** defaults to empty object */
  pageProps?: any;
  /** defaults to 'en' as per the defaultLocale */
  locale?: string;
};

export const Providers = ({
  children,
  locale = 'en',
  pageProps = {},
}: ProviderProps) => {
  return (
    <ThemeProvider theme={theme}>
      <IntlProvider
        messages={pageProps.messages}
        defaultLocale="en"
        locale={locale}
      >
        <StoryProvider>
          <LazyMotion features={loadFeatures}>
            <CursorProvider>{children}</CursorProvider>
          </LazyMotion>
        </StoryProvider>
      </IntlProvider>
    </ThemeProvider>
  );
};
