import * as React from 'react';

function Good() {
  return (
    <svg
      width="76"
      height="77"
      viewBox="0 0 76 77"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M59.9 75.8C59.8 75.8 59.8 75.8 59.9 75.8H45.9C36.8 75.8 24.6 76.1 19 73.3C17.7 72.7 16.9 71.3 16.9 69.9V33.9C16.9 33 17.5 32.2 18.4 32C22.4 31.1 25.1 29.3 31.2 24C37.1 18.7 38.9 11.2 38.5 3.9V3.2L38.9 2.6C40.1 1 42.1 0.1 44.3 0C48.1 0.1 51 3.4 52.7 9.1C54.7 16 52.7 26.4 52.6 26.5C52.6 26.6 52.7 26.9 52.8 27.1C53 27.3 53.2 27.4 53.5 27.4H68.3C70.5 27.3 72.6 28.4 73.9 30.3C75.5 32.5 77.6 38.1 73.2 50.1L73.1 50.3L67.9 68.8C67.2 73 63.8 75.8 59.9 75.8Z"
        fill="#8F201A"
      />
      <path
        d="M28.6 49.9C28.3 50 27.4 50.2 26.7 50.2C24.2 50.2 23.1 49 23.1 46.2C23.1 43.5 24.3 42.1 26.9 42.1C27.7 42.1 28.3 42.3 28.6 42.4L28.9 42.7V44L28.5 44.3C28.2 44.2 27.4 44 26.8 44C25.6 44 25.4 44.9 25.4 46.2C25.4 47.6 25.6 48.4 26.7 48.4C27.2 48.4 28.2 48.2 28.5 48.1L28.8 48.3V49.7L28.6 49.9Z"
        fill="#EDD279"
      />
      <path
        d="M36.8 49.9L35 50.2L34.7 50L34.2 48.2H32L31.4 50L31.1 50.2L29.5 50L29.3 49.7L32 42.7L32.3 42.4H34.1L34.4 42.7L37 49.7L36.8 49.9ZM33.2 44.7L32.6 46.4H33.7L33.2 44.7Z"
        fill="#EDD279"
      />
      <path
        d="M40.2 50.2C39.1 50.2 38.4 50 37.9 49.9L37.6 49.7V48.3L37.9 48.1C38.3 48.2 39.6 48.4 40.1 48.4C40.4 48.4 40.6 48.3 40.6 48C40.6 47 37.6 47.2 37.6 44.5C37.6 42.9 38.8 42.2 40.4 42.2C41.3 42.2 42.1 42.3 42.6 42.5L42.9 42.8V44.1L42.6 44.4C42.2 44.3 41.1 44.1 40.6 44.1C40.2 44.1 40.1 44.2 40.1 44.5C40.1 45.4 43.2 45.2 43.2 48C43 49.6 41.7 50.2 40.2 50.2Z"
        fill="#EDD279"
      />
      <path
        d="M50.7 50.1H48.9L48.7 49.8V46.8H46.5V49.8L46.2 50.1H44.4L44.2 49.8V42.6L44.4 42.3H46.2L46.5 42.6V45.1H48.6V42.6L48.8 42.3H50.6L50.9 42.6V49.8L50.7 50.1Z"
        fill="#EDD279"
      />
      <path
        d="M57.2 50.1H52.7L52.4 49.8V42.6L52.7 42.3H57.1L57.4 42.6V43.9L57.1 44.2H54.8V45.2H56.9L57.2 45.5V46.8L56.9 47.1H54.8V48.5H57.2L57.5 48.8V50.1H57.2Z"
        fill="#EDD279"
      />
      <path
        d="M66.7 49.8L66.4 50.1H64.3L64 49.8L63.3 45.5L62.5 49.8L62.2 50.1H60.1L59.8 49.8L57.7 42.7L57.9 42.4L59.9 42.2L60.2 42.4L61.2 47.4L61.9 42.6L62.2 42.3H64.3L64.6 42.6L65.4 47.4L66.5 42.4L66.8 42.2L68.6 42.4L68.8 42.7L66.7 49.8Z"
        fill="#EDD279"
      />
      <path
        d="M36.4 60H34.6L34.4 59.7V56.7H32.3V59.7L32 60H30.2L30 59.7V52.5L30.2 52.2H32L32.3 52.5V55H34.4V52.5L34.6 52.2H36.4L36.7 52.5V59.7L36.4 60Z"
        fill="white"
      />
      <path
        d="M44.9 59.9L43.1 60.2L42.8 60L42.3 58.2H40.1L39.5 60L39.2 60.2L37.6 59.9L37.4 59.6L40.1 52.6L40.4 52.3H42.2L42.5 52.6L45.1 59.6L44.9 59.9ZM41.3 54.7L40.7 56.4H41.8L41.3 54.7Z"
        fill="white"
      />
      <path
        d="M48.7 57.7H48.1V59.8L47.8 60.1H46L45.7 59.8V52.6L46 52.3H49.1C51 52.3 52 53.3 52 54.9C52 56.7 50.7 57.7 48.7 57.7ZM48.9 54H48.2V55.9H48.7C49.3 55.9 49.8 55.7 49.8 55C49.7 54.4 49.5 54 48.9 54Z"
        fill="white"
      />
      <path
        d="M55.9 57.7H55.3V59.8L55 60.1H53.2L52.9 59.8V52.6L53.2 52.3H56.3C58.2 52.3 59.2 53.3 59.2 54.9C59.2 56.7 57.9 57.7 55.9 57.7ZM56 54H55.3V55.9H55.8C56.4 55.9 56.9 55.7 56.9 55C56.9 54.4 56.6 54 56 54Z"
        fill="white"
      />
      <path
        d="M64 57.8V59.7L63.7 60H61.9L61.6 59.7V57.8L59 52.8L59.2 52.5L61 52.1L61.3 52.3L62.8 55.8L64.4 52.3L64.7 52.1L66.3 52.5L66.5 52.8L64 57.8Z"
        fill="white"
      />
      <path
        d="M26 67.6H25.4V69.7L25.1 70H23.3L23 69.7V62.5L23.3 62.2H26.4C28.3 62.2 29.3 63.2 29.3 64.8C29.3 66.6 28 67.6 26 67.6ZM26.1 64H25.4V65.9H25.9C26.5 65.9 27 65.7 27 65C27 64.3 26.8 64 26.1 64Z"
        fill="white"
      />
      <path
        d="M35 70H30.5L30.2 69.7V62.5L30.5 62.2H34.9L35.2 62.5V63.8L34.9 64.1H32.6V65.1H34.7L35 65.4V66.7L34.7 67H32.6V68.4H35L35.3 68.7V70H35Z"
        fill="white"
      />
      <path
        d="M39.8 70.2C37.3 70.2 36 68.7 36 66.2C36 63.8 37.2 62.1 39.9 62.1C42.5 62.1 43.7 63.7 43.7 66.1C43.6 68.6 42.4 70.2 39.8 70.2ZM39.8 64C38.7 64 38.4 65 38.4 66.2C38.4 67.4 38.7 68.4 39.8 68.4C40.9 68.4 41.2 67.4 41.2 66.2C41.2 65 40.9 64 39.8 64Z"
        fill="white"
      />
      <path
        d="M47.6 67.6H47V69.7L46.7 70H44.9L44.6 69.7V62.5L44.9 62.2H48C49.9 62.2 50.9 63.2 50.9 64.8C50.9 66.6 49.6 67.6 47.6 67.6ZM47.8 64H47.1V65.9H47.6C48.2 65.9 48.7 65.7 48.7 65C48.6 64.3 48.4 64 47.8 64Z"
        fill="white"
      />
      <path
        d="M56.8 70H52.2L51.9 69.7V62.5L52.2 62.2H54L54.3 62.5V68.2H56.9L57.2 68.5V69.8L56.8 70Z"
        fill="white"
      />
      <path
        d="M62.7 70H58.2L57.9 69.7V62.5L58.2 62.2H62.6L62.9 62.5V63.8L62.6 64.1H60.3V65.1H62.4L62.7 65.4V66.7L62.4 67H60.3V68.4H62.7L63 68.7V70H62.7Z"
        fill="white"
      />
      <path
        d="M44.7 39.9C44.4 40 42.8 40.2 42.2 40.2C39.5 40.2 38.4 39 38.4 36.2C38.4 33.6 39.6 32.1 42.3 32.1C43.2 32.1 44.1 32.4 44.5 32.5L44.8 32.8V34.1L44.5 34.4C44.1 34.3 43.1 34.1 42.3 34.1C41 34.1 40.9 35 40.9 36.3C40.9 37.7 41.2 38.5 42.3 38.5C42.4 38.5 42.8 38.5 42.9 38.5V37.5H42.4L42.1 37.2V35.9L42.4 35.6H44.8L45.1 35.9V39.7L44.7 39.9Z"
        fill="#EDD279"
      />
      <path
        d="M49.8 40.3C47.3 40.3 46 38.8 46 36.3C46 33.9 47.2 32.2 49.9 32.2C52.5 32.2 53.7 33.8 53.7 36.2C53.6 38.7 52.4 40.3 49.8 40.3ZM49.8 34C48.7 34 48.4 35 48.4 36.2C48.4 37.4 48.7 38.4 49.8 38.4C50.9 38.4 51.2 37.4 51.2 36.2C51.2 35.1 50.9 34 49.8 34Z"
        fill="#EDD279"
      />
      <path
        d="M58.2 40.3C55.7 40.3 54.4 38.8 54.4 36.3C54.4 33.9 55.6 32.2 58.3 32.2C60.9 32.2 62.1 33.8 62.1 36.2C62.1 38.7 60.8 40.3 58.2 40.3ZM58.2 34C57.1 34 56.8 35 56.8 36.2C56.8 37.4 57.1 38.4 58.2 38.4C59.3 38.4 59.6 37.4 59.6 36.2C59.7 35.1 59.3 34 58.2 34Z"
        fill="#EDD279"
      />
      <path
        d="M66.1 40.1H63.4L63.1 39.8V32.6L63.4 32.3H66.2C68.8 32.3 69.9 33.9 69.9 36C69.9 38.4 68.8 40.1 66.1 40.1ZM66.2 34.1H65.5V38.3H66.2C67.1 38.3 67.5 37.5 67.5 36.2C67.5 34.9 67.1 34.1 66.2 34.1Z"
        fill="#EDD279"
      />
      <path
        d="M4.60005 76.2H14.7C16.8 76.2 18.5 74.5 18.5 72.4V31.9C18.5 29.8 16.8 28.1 14.7 28.1H4.60005C2.50005 28.1 0.800049 29.8 0.800049 31.9V72.5C0.800049 74.5 2.50005 76.2 4.60005 76.2Z"
        fill="#751C17"
      />
    </svg>
  );
}

const MemoGood = React.memo(Good);
export default MemoGood;
