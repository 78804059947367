import * as React from 'react';

function CashewBunch() {
  return (
    <svg
      width="117"
      height="65"
      viewBox="0 0 117 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M51.841 3.25459C52.562 7.00079 46.124 16.3803 53.422 17.2961C58.223 17.2406 70.433 11.8293 66.964 20.4596C60.526 36.471 36.606 27.147 40.019 13.0503C41.046 8.83229 48.733 -4.26561 51.841 3.25459Z"
        fill="#D4BC6C"
      />
      <path
        d="M51.84 3.25401C52.562 7.00031 45.097 15.464 52.395 16.3798C57.196 16.3243 68.879 11.3015 66.631 19.2935C59.444 33.973 36.661 25.343 39.685 11.8843C39.796 7.52751 48.732 -4.26619 51.84 3.25401Z"
        fill="#EDD279"
      />
      <path
        d="M19.872 61.0289C25.172 64.0539 41.6 67.1899 44.431 57.9209C47.955 46.4329 31.777 48.8199 26.06 47.9589C24.34 47.7099 22.536 42.6869 22.148 39.7449C21.343 33.6679 14.794 32.6409 12.268 36.4429C8.43901 42.1319 7.93902 55.1459 19.872 61.0289Z"
        fill="#D4BC6C"
      />
      <path
        d="M19.957 59.253C25.257 62.278 42.517 65.636 44.515 56.145C46.513 46.627 31.39 47.654 26.145 46.183C24.452 45.711 22.621 40.911 22.232 37.969C21.427 31.892 14.878 31.892 12.353 35.666C8.52399 41.355 7.91299 52.371 19.957 59.253Z"
        fill="#EDD279"
      />
      <path
        d="M25.422 58.2821C23.563 58.2551 21.815 57.8381 20.233 57.0061C17.819 55.7291 15.959 53.8981 14.655 51.6501C13.323 49.3471 12.601 46.9051 12.324 44.3241C12.157 42.7701 12.296 41.2441 12.296 39.7171C12.296 39.4401 12.38 39.1621 12.629 38.8571C12.99 40.8001 13.434 42.6591 13.933 44.5181C14.405 46.1281 15.044 47.7101 15.654 49.2641C16.126 50.5121 16.93 51.6221 17.818 52.6771C19.095 54.2031 20.483 55.6181 22.314 56.6451C23.341 57.2561 24.478 57.5891 25.422 58.2821Z"
        fill="#F4F5E2"
      />
      <path
        d="M12.296 27.147C17.902 29.533 35.412 31.809 37.521 23.9C40.185 13.91 23.59 17.129 18.124 16.019C16.375 15.6583 13.961 14.7425 13.434 12.3005C12.352 7.22232 5.05301 6.25103 2.61101 9.49783C-1.07899 14.3818 -0.107995 22.3738 12.296 27.147Z"
        fill="#D4BC6C"
      />
      <path
        d="M12.296 25.26C17.902 27.646 35.884 30.033 37.521 22.013C39.186 13.9933 23.59 15.242 18.124 14.132C16.375 13.7712 13.961 12.8555 13.434 10.4135C12.352 5.33519 5.58101 5.50169 3.13901 8.74849C-0.579989 13.6325 -0.440991 19.8208 12.296 25.26Z"
        fill="#EDD279"
      />
      <path
        d="M2.58401 12.4393C1.94501 19.5155 11.575 23.6503 17.236 23.789C11.408 21.0973 5.41401 18.6553 2.58401 12.4393Z"
        fill="#F4F5E2"
      />
      <path
        d="M107.284 33.7789C102.539 35.9709 87.526 37.8309 85.972 30.1439C83.974 20.2647 97.794 23.9277 102.428 22.9842C103.927 22.6789 105.73 18.9049 106.202 16.5739C107.174 11.7454 112.89 12.023 114.916 15.1865C118.024 19.9318 118.079 28.8119 107.284 33.7789Z"
        fill="#D4BC6C"
      />
      <path
        d="M76.205 49.069C81.699 52.233 100.125 56.34 102.29 47.099C105.176 34.834 88.748 38.274 83.281 36.609C81.533 36.082 79.757 31.309 79.479 28.451C78.869 22.5125 71.959 22.2072 69.128 25.787C64.855 31.17 63.745 41.854 76.205 49.069Z"
        fill="#D4BC6C"
      />
      <path
        d="M76.204 47.404C81.699 50.568 99.792 54.592 102.289 45.434C104.787 36.276 88.747 36.609 83.281 34.944C81.532 34.417 79.756 29.644 79.479 26.786C78.868 20.8475 71.959 20.5422 69.128 24.122C64.855 29.505 63.745 40.189 76.204 47.404Z"
        fill="#EDD279"
      />
      <path
        d="M46.179 47.488C41.101 47.155 36.633 45.462 33.137 41.632C31.694 40.051 31.194 36.887 31.416 34.75C31.555 33.446 32.803 33.391 34.246 33.196C35.245 33.058 36.217 33.196 37.216 33.474C40.129 34.279 43.099 34.778 46.096 33.779C48.316 33.03 49.648 31.171 49.676 28.812C49.703 27.008 54.115 26.897 53.533 25.204C52.95 23.5115 48.787 20.8753 50.397 20.0427C52.173 19.127 54.06 19.016 55.975 19.4045C57.307 19.682 58.417 20.4313 59.443 21.3193C61.885 23.4283 62.912 26.176 62.829 29.367C62.773 31.698 62.135 33.89 61.219 36.027C60.193 38.386 58.999 40.633 57.279 42.576C55.475 44.629 53.283 46.156 50.619 46.905C49.148 47.293 47.677 47.488 46.179 47.488Z"
        fill="#D4BC6C"
      />
      <path
        d="M46.179 45.85C41.101 45.517 36.633 43.825 33.137 39.995C31.694 38.413 31.055 36.582 31.277 34.417C31.416 33.113 32.803 31.753 34.246 31.559C35.245 31.42 36.217 31.559 37.216 31.837C40.129 32.641 43.099 33.141 46.096 32.142C48.316 31.393 49.648 29.533 49.675 27.175C49.703 25.371 49.37 23.5948 48.787 21.902C48.205 20.2092 48.815 19.2103 50.397 18.4055C52.173 17.4898 54.06 17.3787 55.975 17.7672C57.307 18.0447 58.417 18.794 59.443 19.682C61.885 21.791 62.912 24.538 62.829 27.73C62.773 30.061 62.135 32.253 61.219 34.39C60.193 36.748 58.999 38.996 57.279 40.939C55.475 42.992 53.283 44.518 50.619 45.268C49.148 45.656 47.677 45.85 46.179 45.85Z"
        fill="#EDD279"
      />
      <path
        d="M47.4 43.547C49.009 43.63 50.563 43.325 51.979 42.631C54.143 41.549 55.836 39.884 57.085 37.831C58.361 35.722 59.055 33.418 59.444 30.948C59.666 29.478 59.61 28.007 59.665 26.536C59.665 26.259 59.61 26.009 59.416 25.704C58.999 27.535 58.555 29.283 58.028 31.032C57.556 32.558 56.918 34.001 56.335 35.472C55.864 36.637 55.114 37.664 54.282 38.635C53.089 40.023 51.84 41.299 50.203 42.187C49.259 42.659 48.232 42.909 47.4 43.547Z"
        fill="#F4F5E2"
      />
      <path
        d="M107.285 32.336C102.539 34.528 87.305 36.415 85.973 28.701C84.641 20.9585 97.794 22.4848 102.428 21.5413C103.927 21.236 105.731 17.462 106.202 15.131C107.174 10.3025 112.89 10.58 114.916 13.7435C118.024 18.4887 118.079 27.369 107.285 32.336Z"
        fill="#EDD279"
      />
      <path
        d="M80.145 40.55C79.646 38.108 79.035 35.777 77.259 34.417C74.9 32.586 69.628 33.252 70.322 37.054C71.321 40.633 71.598 46.155 68.129 48.681C63.939 53.703 53.949 45.85 52.145 53.592C50.647 74.544 84.918 63.832 80.145 40.55Z"
        fill="#D4BC6C"
      />
      <path
        d="M79.396 39.2181C78.841 36.6371 78.286 34.4451 76.51 33.0851C74.151 31.2541 68.879 31.9201 69.572 35.7221C70.571 39.3011 70.544 44.4351 67.38 47.3491C62.413 51.9281 53.2 44.5181 51.396 52.2611C49.87 73.2121 84.308 62.4731 79.396 39.2181Z"
        fill="#EDD279"
      />
      <path
        d="M57.501 60.447C65.882 60.891 71.542 56.4509 76.121 50.0959C74.401 57.1439 65.965 63.915 57.501 60.447Z"
        fill="#F4F5E2"
      />
      <path
        d="M85.362 48.098C93.465 47.127 96.101 48.375 100.153 43.963C97.738 50.124 92.771 49.791 85.362 48.098Z"
        fill="#F4F5E2"
      />
      <path
        d="M102.539 31.309C104.149 31.392 105.703 31.115 107.118 30.532C109.283 29.616 110.975 28.229 112.224 26.481C113.501 24.705 114.194 22.7622 114.583 20.7087C114.805 19.46 114.749 18.239 114.805 16.9902C114.805 16.7682 114.749 16.5462 114.555 16.2965C114.139 17.8505 113.695 19.3212 113.168 20.7642C112.696 22.0407 112.058 23.2617 111.475 24.51C111.003 25.509 110.254 26.37 109.421 27.174C108.228 28.34 106.979 29.422 105.342 30.171C104.426 30.56 103.4 30.782 102.539 31.309Z"
        fill="#F4F5E2"
      />
    </svg>
  );
}

const MemoCashewBunch = React.memo(CashewBunch);
export default MemoCashewBunch;
