import * as React from 'react';

function FingersPolish() {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 69.4 69.4"
      xmlSpace="preserve"
    >
      <circle fill="#7F58BE" cx="34.7" cy="34.7" r="34.2" />
      <g>
        <path
          fill="#EBD078"
          d="M9,20.9H8.7v1.3l-0.2,0.2H7.4l-0.2-0.2v-4.5l0.2-0.2h1.9c1.2,0,1.8,0.6,1.8,1.6C11.1,20.3,10.3,20.9,9,20.9z
		 M9.1,18.6H8.7v1.2h0.3c0.4,0,0.7-0.1,0.7-0.6C9.7,18.8,9.5,18.6,9.1,18.6z"
        />
        <path
          fill="#EBD078"
          d="M13.8,22.5c-1.6,0-2.4-0.9-2.4-2.5c0-1.5,0.7-2.6,2.4-2.6c1.7,0,2.4,1,2.4,2.5C16.3,21.5,15.5,22.5,13.8,22.5z
		 M13.9,18.6c-0.7,0-0.9,0.6-0.9,1.4c0,0.7,0.2,1.4,0.9,1.4c0.7,0,0.9-0.6,0.9-1.4C14.7,19.2,14.5,18.6,13.9,18.6z"
        />
        <path
          fill="#EBD078"
          d="M20.2,18.7h-1.1v3.6l-0.2,0.2h-1.1l-0.2-0.2v-3.6h-1.1l-0.2-0.2v-0.8l0.2-0.2h3.7l0.2,0.2v0.8L20.2,18.7z"
        />
        <path
          fill="#EBD078"
          d="M26.2,22.2L26,22.4h-1.3l-0.2-0.2l-0.5-2.7l-0.5,2.7l-0.2,0.2h-1.3l-0.2-0.2l-1.3-4.4l0.1-0.2l1.2-0.1l0.2,0.1
		l0.7,3.1l0.5-3l0.2-0.2h1.3l0.2,0.2l0.5,3l0.7-3.1l0.2-0.1l1.1,0.1l0.1,0.2L26.2,22.2z"
        />
        <path
          fill="#EBD078"
          d="M29.3,22.4h-1.1L28,22.2v-4.5l0.2-0.2h1.1l0.2,0.2v4.5L29.3,22.4z"
        />
        <path
          fill="#EBD078"
          d="M33.4,22.4h-2.8l-0.2-0.2v-4.5l0.2-0.2h2.8l0.2,0.2v0.8l-0.2,0.2h-1.5v0.6h1.3l0.2,0.2v0.8l-0.2,0.2h-1.3v0.9
		h1.5l0.2,0.2v0.8L33.4,22.4z"
        />
        <path
          fill="#EBD078"
          d="M38.2,22.2l-1.1,0.3l-0.2-0.1l-1.1-1.9h-0.1v1.7l-0.2,0.2h-1.1l-0.2-0.2v-4.5l0.2-0.2h1.9
		c1.2,0,1.8,0.4,1.8,1.4c0,0.8-0.6,1.2-1,1.3l1.1,1.8L38.2,22.2z M36.2,18.6h-0.5v0.8H36c0.3,0,0.7,0,0.7-0.4
		C36.7,18.7,36.6,18.6,36.2,18.6z"
        />
        <path
          fill="#EBD078"
          d="M40.7,22.4H39l-0.2-0.2v-4.5l0.2-0.2h1.8c1.6,0,2.3,1,2.3,2.3C43.1,21.3,42.4,22.4,40.7,22.4z M40.7,18.7h-0.4
		v2.6h0.4c0.5,0,0.8-0.5,0.8-1.3C41.6,19.1,41.4,18.7,40.7,18.7z"
        />
        <path
          fill="#EBD078"
          d="M46.8,22.4h-3.4l-0.2-0.2v-0.8l2-2.8h-1.6l-0.2-0.2v-0.8l0.2-0.2h3.1l0.2,0.2v0.8l-2,2.8h1.8l0.2,0.2v0.8
		L46.8,22.4z"
        />
        <path
          fill="#EBD078"
          d="M50.6,22.4h-2.8l-0.2-0.2v-4.5l0.2-0.2h2.8l0.2,0.2v0.8l-0.2,0.2h-1.5v0.6h1.3l0.2,0.2v0.8l-0.2,0.2h-1.3v0.9
		h1.5l0.2,0.2v0.8L50.6,22.4z"
        />
        <path
          fill="#EBD078"
          d="M55.7,22.4h-1.5L54,22.2l-1.3-2.6l0.1,0.3v2.2l-0.2,0.2h-1.1l-0.2-0.2v-4.5l0.2-0.2h1.4l0.2,0.2l1.3,2.5
		l-0.1-0.3v-2.2l0.2-0.2h1.1l0.2,0.2v4.5L55.7,22.4z"
        />
        <path
          fill="#EBD078"
          d="M58.1,22.4H57l-0.2-0.2v-4.5l0.2-0.2h1.1l0.2,0.2v4.5L58.1,22.4z"
        />
        <path
          fill="#EBD078"
          d="M62.2,22.4h-2.8l-0.2-0.2v-4.5l0.2-0.2h2.8l0.2,0.2v0.8l-0.2,0.2h-1.5v0.6H62l0.2,0.2v0.8L62,20.4h-1.3v0.9
		h1.5l0.2,0.2v0.8L62.2,22.4z"
        />
      </g>
      <g>
        <path
          fill="#FFFFFF"
          d="M11,31.3c-2.4,0-3.6-1.4-3.6-3.8c0-2.3,1.1-3.9,3.7-3.9c2.5,0,3.6,1.6,3.6,3.8C14.6,29.8,13.4,31.3,11,31.3z
		 M11,25.4c-1.1,0-1.4,0.9-1.4,2.1c0,1.1,0.3,2.1,1.3,2.1c1.1,0,1.4-0.9,1.4-2.1C12.3,26.4,12,25.4,11,25.4z"
        />
        <path
          fill="#FFFFFF"
          d="M18.5,31.1h-2.6l-0.2-0.2v-6.8l0.2-0.2h2.6c1.7,0,2.7,0.5,2.7,1.9c0,0.6-0.5,1.3-1,1.5
		c0.5,0.1,1.2,0.9,1.2,1.8C21.4,30.6,20.1,31.1,18.5,31.1z M18.4,25.4h-0.5v1.1h0.5c0.4,0,0.7-0.1,0.7-0.6
		C19,25.5,18.9,25.4,18.4,25.4z M18.5,28.2h-0.7v1.3h0.6c0.4,0,0.8-0.2,0.8-0.7C19.2,28.4,19,28.2,18.5,28.2z"
        />
        <path
          fill="#FFFFFF"
          d="M24.3,31.1h-1.7l-0.2-0.2v-6.8l0.2-0.2h1.7l0.2,0.2v6.8L24.3,31.1z"
        />
        <path
          fill="#FFFFFF"
          d="M30.5,31.1h-4.2L26,30.9v-6.8l0.2-0.2h4.2l0.2,0.2v1.2l-0.2,0.2h-2.2v1h2l0.2,0.2v1.2l-0.2,0.2h-2v1.3h2.3
		l0.2,0.2v1.2L30.5,31.1z"
        />
        <path
          fill="#FFFFFF"
          d="M37,25.5h-1.7v5.4l-0.2,0.2h-1.7l-0.2-0.2v-5.4h-1.7l-0.2-0.2v-1.2l0.2-0.2H37l0.2,0.2v1.2L37,25.5z"
        />
        <path
          fill="#FFFFFF"
          d="M44.5,31.1h-2.2L42,30.9L40,27l0.2,0.5v3.3L40,31.1h-1.7l-0.2-0.2v-6.8l0.2-0.2h2.2l0.3,0.2l2,3.8l-0.2-0.5
		v-3.3l0.2-0.2h1.7l0.2,0.2v6.8L44.5,31.1z"
        />
        <path
          fill="#FFFFFF"
          d="M48.1,31.1h-1.7l-0.2-0.2v-6.8l0.2-0.2h1.7l0.2,0.2v6.8L48.1,31.1z"
        />
        <path
          fill="#FFFFFF"
          d="M54.7,31c-0.2,0.1-1.1,0.3-1.8,0.3c-2.4,0-3.5-1.2-3.5-3.8c0-2.5,1.1-3.9,3.6-3.9c0.7,0,1.3,0.1,1.6,0.3
		l0.2,0.2v1.2l-0.2,0.2c-0.3-0.1-1-0.3-1.6-0.3c-1.1,0-1.3,0.9-1.3,2.1c0,1.3,0.2,2.1,1.3,2.1c0.5,0,1.4-0.2,1.7-0.3l0.3,0.2v1.3
		L54.7,31z"
        />
        <path
          fill="#FFFFFF"
          d="M59.8,29.1v1.8l-0.2,0.2h-1.7l-0.2-0.2v-1.8l-2.5-4.7l0.2-0.3l1.7-0.4l0.3,0.2l1.4,3.3l1.6-3.3l0.3-0.2
		l1.6,0.4l0.2,0.3L59.8,29.1z"
        />
      </g>
      <g>
        <defs>
          <circle id="SVGID_1_" cx="34.7" cy="34.7" r="34.2" />
        </defs>
        <clipPath id="SVGID_2_">
          <use
            xlinkHref="#SVGID_1_"
            //   style="overflow:visible;"
          />
        </clipPath>
        <g fill="clip-path:url(#SVGID_2_)">
          <path
            fill="#EBD078"
            d="M45.1,42.8c-0.3-0.6-0.2-1.3,0.4-1.6l2.3-2.3l3.2-0.8l1.1-0.4c2.6-1.5,6.4,4.5,8.7,10.7
			c1.1,2.8,0.7,6.1,0.4,7.7c-0.1,0.7,0,1.4,0.3,2l1.7,3l-0.4,1.2l-9.9,5.7c-0.2,0.1-0.6,0.1-0.7-0.2l-1.9-3.3
			c-0.5-0.9-1.4-1.6-2.3-1.9c-4.3-1.6-6.9-6.1-6.9-6.1L29.8,36.8c-0.4-0.8-0.4-1.7,0.1-2.4c0,0,1.9-1.1,3,0.6l5,8.7L45.1,42.8z"
          />
          <path
            fill="#D6BC6C"
            d="M32.1,35.5l6,10.6c0.5,0.9,1.6,1.2,2.5,0.7l-6.9-12.1c-0.6-1.1-2-1.5-3-0.9c-0.3,0.2-0.5,0.4-0.7,0.6
			C30.8,34.3,31.6,34.7,32.1,35.5z"
          />
          <path
            fill="#D6BC6C"
            d="M51.9,37.4l-1.6,0.9c4.1-2.4,7.3,4.3,9.5,10.4c1,2.8,0.7,6.1,0.4,7.7c-0.1,0.7,0,1.4,0.3,2l2.2,3.7l1.1-0.7
			c0.2-0.1,0.3-0.4,0.2-0.7l-1.9-3.3c-0.4-0.6-0.5-1.3-0.3-2c0.3-1.6,0.7-4.8-0.4-7.7C59.2,41.7,56,35,51.9,37.4z"
          />
          <path
            fill="#EBD078"
            d="M44.9,38.2l1,1.7c0,0-0.4,1.2-0.4,1.2c-0.6,0.3-0.7,1.1-0.4,1.6c0.5,0.8,1.3,1.4,2.2,1.6c0,0,0.1,0,0.1,0
			l1.6,1l1,1.7c0.6,1.1-1.1,2.1-1.4,2.1c-0.8,0.1-1.7-0.3-2.1-1.1l-4.7-8.2c-0.4-0.8-0.4-1.7,0.1-2.4C42.1,37.3,43.9,36.4,44.9,38.2
			z"
          />
          <path
            fill="#D6BC6C"
            d="M47.4,44.4l1.4,2.4c0.5,0.8,0.4,1.7-0.1,2.4c0.3,0,0.6-0.1,0.9-0.3c1.1-0.6,1.4-2,0.8-3l-1-1.7l-0.5,0.1
			C48.4,44.4,47.9,44.5,47.4,44.4z"
          />
          <path
            fill="#D6BC6C"
            d="M42.6,36.9c-0.3,0.2-0.5,0.4-0.7,0.6c0.8-0.1,1.7,0.3,2.1,1.1l1.4,2.5c0,0,1.6-0.9,1.6-0.9l-1.4-2.5
			C45,36.7,43.7,36.3,42.6,36.9z"
          />
          <path
            fill="#EBD078"
            d="M44.1,50.2c-0.8,0.1-1.7-0.3-2.1-1.1l-3.3-5.7c-0.4-0.8-0.4-1.7,0.1-2.4c0.2-0.2,2.3-0.4,3,0.6l3.3,5.7
			C45.6,48.4,44.4,50.1,44.1,50.2z"
          />
          <path
            fill="#D6BC6C"
            d="M39.5,40.3L39.5,40.3c-0.3,0.2-0.5,0.4-0.7,0.6c0.8-0.1,1.7,0.3,2.1,1.1l3.3,5.7c0.5,0.8,0.4,1.7-0.1,2.4
			c0.3,0,0.6-0.1,0.9-0.3l0,0c1.1-0.6,1.4-2,0.8-3l-3.3-5.7C41.9,40.1,40.5,39.7,39.5,40.3z"
          />
          <path
            fill="#D6BC6C"
            d="M51.4,53.9c-0.7-4.9,1.8-8.3,2.7-9.4c0.3,1.1,0.2,2.2-0.3,3.3c-0.7,1.5-1.3,3.5-0.9,5.9
			c0,0.3-0.1,0.6-0.4,0.8c-0.1,0-0.2,0.1-0.3,0.1C51.8,54.6,51.5,54.3,51.4,53.9z"
          />
          <path
            fill="#EBD078"
            d="M52.9,43.2l0.2,0.4c1,1.8-0.6,2.8-0.9,2.9c-0.8,0.1-1.7-0.3-2.1-1.1l-0.7-1.2l2.5-0.8L52.9,43.2z"
          />
          <path
            fill="#D6BC6C"
            d="M51.9,43.3l0.4,0.7c0.5,0.8,0.4,1.7-0.1,2.4c0.3,0,0.6-0.1,0.9-0.3c1.1-0.6,1.4-2,0.8-3l-0.2-0.4L51.9,43.3z"
          />
          <path
            fill="#EBD078"
            d="M47.1,40.2l-1.3-2.2c-0.4-0.8-0.4-1.7,0.1-2.4c0.2-0.2,1.9-1.1,3,0.6l0.9,1.4l-0.4,1.2L47.1,40.2z"
          />
          <path
            fill="#D6BC6C"
            d="M46.6,35c-0.3,0.2-0.5,0.4-0.7,0.6c0.8-0.1,1.7,0.3,2.1,1.1l1.3,2.2l1.6-0.9l-1.3-2.2
			C49,34.8,47.6,34.4,46.6,35z"
          />
          <path
            fill="#EBD078"
            d="M26.4,40.3c0.3-0.6-1.3-0.5-1.9-0.8l-1.9-2.1l-2.9,2L17,40.1c-4.1-2.4-6.1-0.1-8.4,6.1
			c-1.1,2.8-0.7,6.1-0.4,7.7c0.1,0.7,0,1.4-0.3,2l-1.9,3.3c-0.1,0.2-0.1,0.6,0.2,0.7l9.9,5.7l1.3-0.3l1.7-3c0.5-0.9,1.4-1.6,2.3-1.9
			c4.3-1.6,6.9-6.1,6.9-6.1l9.1-16.1c1-1.8-0.6-2.8-0.9-2.9c-0.8-0.1-1.7,0.3-2.1,1.1l-3.8,6.5L26.4,40.3z"
          />
          <path
            fill="#D6BC6C"
            d="M37.3,35.6c-0.3-0.2-0.6-0.3-0.9-0.3c0.5,0.7,0.6,1.6,0.1,2.4l-9.1,16.1c0,0-2.6,4.5-6.9,6.1
			c-1,0.4-1.8,1-2.3,1.9L16,65.6l1.1,0.7c0.2,0.1,0.6,0.1,0.7-0.2l1.9-3.3c0.5-0.9,1.4-1.6,2.3-1.9c4.3-1.6,6.9-6.1,6.9-6.1l9-16.1
			C38.7,37.6,38.3,36.2,37.3,35.6z"
          />
          <path
            fill="#EBD078"
            d="M25.1,37.2c-0.8-0.1-1.7,0.3-2.1,1.1l-1.4,2.5l1.6,0.9c0.6,0.3,0.7,1.1,0.4,1.6c-0.8,1.3-2.4,2-3.8,1.5
			l-0.5-0.1l-1,1.7c-0.5,0.8-0.4,1.7,0.1,2.4c0.2,0.2,2.3,0.4,3-0.6L26,40C26.6,39,25.4,37.2,25.1,37.2z"
          />
          <path
            fill="#D6BC6C"
            d="M26,37.5c-0.3-0.2-0.6-0.3-0.9-0.3c0.5,0.7,0.6,1.6,0.1,2.4l1.6,0.9C27.4,39.4,27,38.1,26,37.5z"
          />
          <path
            fill="#EBD078"
            d="M26.4,48.2l3.3-5.7c0.6-1.1-0.6-2.8-0.9-2.9c-0.8-0.1-1.7,0.3-2.1,1.1l-3.3,5.7c-0.5,0.8-0.4,1.7,0.1,2.4
			C23.6,49.1,25.8,49.3,26.4,48.2z"
          />
          <path
            fill="#D6BC6C"
            d="M29.7,39.9L29.7,39.9c-0.3-0.2-0.6-0.3-0.9-0.3c0.5,0.7,0.6,1.6,0.1,2.4l1.6,0.9
			C31.1,41.9,30.7,40.5,29.7,39.9z"
          />
          <path
            fill="#EBD078"
            d="M15.8,42.7l-0.2,0.4c-0.5,0.8-0.4,1.7,0.1,2.4c0.2,0.2,2.3,0.4,3-0.6l0.3-0.4l-0.6-0.9L15.8,42.7z"
          />
          <path
            fill="#EBD078"
            d="M21.2,39.5l1-1.5c0.6-1.1-0.6-2.8-0.9-2.9c-0.8-0.1-1.7,0.3-2.1,1.1l-3.2,5.1l4.1-1.5L21.2,39.5z"
          />
          <path
            fill="#D6BC6C"
            d="M22.1,35.4c-0.3-0.2-0.6-0.3-0.9-0.3c0.5,0.7,0.6,1.6,0.1,2.4l1.6,0.9C23.5,37.4,23.2,36,22.1,35.4z"
          />
        </g>
      </g>
      <path
        fill="#D6BC6C"
        d="M16.7,40l-3.5,5.3c0,0-1.4-0.9,0-3c2-3,2-3,2-3S15.8,39.4,16.7,40z"
      />
    </svg>
  );
}

const MemoFingersPolish = React.memo(FingersPolish);
export default MemoFingersPolish;
