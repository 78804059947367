import { Flex, Stack, SystemProps, css, Grid } from '@storyofams/react-ui';
import { ContentImage } from '~components';
import section from '~styles/theme/section';

import { Container } from '../Container';
import { RichText } from '../RichText';
import { Sticker } from './Sticker';

type DualImageProps = {
  first?: boolean;
  content: {
    background_colour: 'banana' | 'tiger' | 'fig' | 'aloe' | 'lapiz';
    image_1?: any;
    image_2?: any;
    text_1?: any;
    text_2?: any;
    sticker_type?:
      | 'boundaries'
      | 'cashews'
      | 'check'
      | 'factory'
      | 'good'
      | 'journey'
      | 'nuts'
      | 'promise';
    sticker_alignment?: 'left' | 'right';
    image_position?: 'top' | 'left' | 'right' | 'bottom';
    lapiz_bold_colour?: boolean;
  };
} & SystemProps;

export const DualImage = ({ content, first, ...props }: DualImageProps) => {
  const { bg, bold, link, paragraph, title } =
    section[content.background_colour] || section.banana;
  const sharedText = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex:
      (content?.image_position === 'top' ||
        content?.image_position === 'bottom') &&
      '0 1 100%',
    textAlign: 'center',
  };
  const order = {
    top: [
      1,
      !!!content?.text_1?.content?.[0]?.content ? [5, 2] : [3, 2],
      [2, 3],
      4,
    ],
    bottom: [
      !!!content?.text_2?.content?.[0]?.content ? [1, 3] : [2, 3],
      4,
      1,
      [3, 2],
    ],
    left: [1, !!!content?.text_1?.content?.[0]?.content ? [5, 3] : 3, 2, 4],
    right: [!!!content?.text_2?.content?.[0]?.content ? [1, 2] : 2, 4, 1, 3],
  };

  return (
    <Container
      bgColor={bg}
      childProps={{
        pt: first && content?.image_position === 'top' ? [148, 280] : [10, 20],
      }}
      {...props}
    >
      <Grid
        width="100%"
        rowSize={[1, 2]}
        columnGap={[
          0,
          content?.image_position === 'top' ||
          content?.image_position === 'bottom'
            ? 5
            : 10,
        ]}
        rowGap={[
          3,
          content?.image_position === 'top' ||
          content?.image_position === 'bottom'
            ? 10
            : 5,
        ]}
      >
        {content?.image_1?.filename && (
          <Stack
            flexDirection="column"
            space={[0, 5]}
            order={order[content?.image_position][0]}
            justifyContent={
              (content?.image_position === 'left' ||
                content?.image_position === 'right') &&
              'flex-end'
            }
          >
            <ContentImage height={[200, 430]} content={content?.image_1} />
            {(!!!content?.text_1?.content?.[0]?.content ||
              !!!content?.text_2?.content?.[0]?.content) &&
              (content?.image_position === 'left' ||
                content?.image_position === 'right') && (
                <ContentImage
                  display={[
                    !!!content?.text_1?.content?.[0]?.content ||
                    !!!content?.text_2?.content?.[0]?.content
                      ? 'none !important'
                      : 'block',
                    'block !important',
                  ]}
                  height={[200, 430]}
                  content={content?.image_2}
                />
              )}
          </Stack>
        )}
        {content?.image_2?.filename && (
          <Flex
            order={order[content?.image_position][1]}
            display={[
              'flex',
              (!!!content?.text_1?.content?.[0]?.content ||
                !!!content?.text_2?.content?.[0]?.content) &&
              (content?.image_position === 'left' ||
                content?.image_position === 'right')
                ? 'none !important'
                : 'flex',
            ]}
          >
            <ContentImage height={[200, 430]} content={content?.image_2} />
          </Flex>
        )}
        {content?.text_1?.content?.[0]?.content && (
          <RichText
            titleColor={title}
            paragraphColor={paragraph}
            boldColor={!!content?.lapiz_bold_colour ? 'lapiz' : bold}
            linkColor={link}
            css={css(sharedText)}
            text={content?.text_1}
            order={order[content?.image_position][2]}
            justifyContent={
              content?.image_position === 'left' ||
              content?.image_position === 'right'
                ? !!content?.text_2?.content?.[0]?.content
                  ? 'flex-end'
                  : 'center'
                : 'center'
            }
          />
        )}
        {content?.text_2?.content?.[0]?.content && (
          <RichText
            titleColor={title}
            paragraphColor={paragraph}
            boldColor={!!content?.lapiz_bold_colour ? 'lapiz' : bold}
            linkColor={link}
            css={css(sharedText)}
            text={content?.text_2}
            order={order[content?.image_position][3]}
            justifyContent={
              content?.image_position === 'left' ||
              content?.image_position === 'right'
                ? !!content?.text_1?.content?.[0]?.content
                  ? 'flex-start'
                  : 'center'
                : 'center'
            }
          />
        )}
      </Grid>
      {content?.sticker_type && (
        <Sticker
          align={content?.sticker_alignment}
          type={content?.sticker_type}
        />
      )}
    </Container>
  );
};
