import { useState } from 'react';
import { Flex, Icon, Box, Stack, css } from '@storyofams/react-ui';
import { m } from 'framer-motion';

import { getNavLinkHref } from '~lib';
import {
  Navigation as NavigationShape,
  Hamburger,
  Logo,
  LogoOutline,
} from '~components/common/Icon/library';

import useHideOnScrolled from '~lib/useHideOnScrolled';
import { Link } from '../Link';
import { Shape } from '../Shape';
import { NavLink } from './NavLink';
interface NavigationProps {
  content: any;
}

export const Navigation = ({ content }: NavigationProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { hidden, bottom } = useHideOnScrolled();

  return (
    <>
      <Box
        css={css({
          position: 'fixed',
          left: '50%',
          top: ['24px', '56px'],
          zIndex: 10000,
          transform: 'translateX(-50%)',
          display: ['none', 'none', 'block'],
          pointerEvents: hidden ? 'none' : 'auto',
        })}
      >
        <m.div
          animate={hidden ? 'scrolled' : 'top'}
          initial={true}
          variants={{
            top: {
              opacity: 1,
              scale: 1,
              transition: { duration: 0.18 },
            },
            scrolled: {
              opacity: 0,
              scale: 0.4,
              transition: { duration: 0.18 },
            },
          }}
        >
          <Link href="/" stylingProps={{ cursor: 'pointer' }}>
            <a>
              <Shape
                width={['105px', '243px']}
                height={['76px', '176px']}
                icon={<Logo />}
              />
            </a>
          </Link>
        </m.div>
      </Box>
      <Box
        position="fixed"
        left={[0, 0, '16px', '16px', 'calc(50vw - 630px)']}
        top="50%"
        display={['none', 'none', 'block']}
        transform="translateY(-50%)"
        width="102px"
        zIndex="sticky"
      >
        <Shape
          icon={<NavigationShape />}
          position="absolute"
          left="0"
          top="0"
          width="100%"
          height="100%"
          zIndex="hide"
        />
        <Stack
          space={3}
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          px={1.5}
          py={6}
          position="relative"
        >
          <m.div
            animate={hidden && !bottom ? 'scrolled' : 'top'}
            initial={false}
            variants={{
              scrolled: {
                opacity: 1,
                height: 127,
                scale: 1,
                y: 0,
                x: '-54%',
                position: 'absolute',
                top: -68,
                left: '50%',
                transition: { duration: 0.18 },
              },
              top: {
                opacity: 0,
                height: 0,
                scale: 0.4,
                y: -16,
                x: '-50%',
                position: 'absolute',
                top: 0,
                left: '50%',
                transition: { duration: 0.18 },
              },
            }}
          >
            <Link href="/" stylingProps={{ cursor: 'pointer' }}>
              <a>
                <Shape width="132px" height="95px" icon={<LogoOutline />} />
              </a>
            </Link>
          </m.div>

          {content?.content?.navigation_links?.map(
            ({ hyperlink, name, icon }, i) => (
              <NavLink
                href={getNavLinkHref(hyperlink)}
                icon={icon}
                text={name}
                key={`key-${i}-${hyperlink}`}
                mt={i === 0 && '0 !important'}
              />
            ),
          )}
        </Stack>
      </Box>
      <Box
        display={['block', 'block', 'none']}
        position="fixed"
        left={0}
        top={0}
        transform="none"
        width="100%"
        zIndex="sticky"
        overflow="hidden"
      >
        <m.div
          animate={isOpen ? 'open' : hidden ? 'scrolled' : 'top'}
          initial={false}
          variants={{
            open: {
              height: '100vh',
              backgroundColor: '#EDD279',
              opacity: 1,
              color: '#01A19A',
              transition: { bounce: 0 },
            },
            top: {
              height: 140,
              color: 'white',
              transition: { bounce: 0 },
            },
            scrolled: {
              width: 'auto',
              height: 88,
              backgroundColor: '#EDD279',
              color: '#01A19A',
              transition: { bounce: 0 },
            },
          }}
        >
          <Flex width="100%" variant="center">
            <Flex
              flexDirection="column"
              position="absolute"
              left="16px"
              as="button"
              aria-label={isOpen ? 'Close navigation' : 'Open navigation'}
              onClick={() => setIsOpen(!isOpen)}
              padding={1.5}
            >
              <Box
                position="absolute"
                left="0"
                top="-1px"
                width="100%"
                height="100%"
                zIndex="hide"
                bg="lapiz"
                borderRadius="lg"
              />
              <Icon
                icon={<Hamburger />}
                mb={0.25}
                transition="0.12s transform ease-in-out"
                transform={isOpen ? 'translateY(11px) rotate(45deg)' : 'none'}
              />
              <Icon icon={<Hamburger />} opacity={isOpen ? 0 : 1} />
              <Icon
                icon={<Hamburger />}
                mt={0.25}
                transition="0.12s transform ease-in-out"
                transform={isOpen ? 'translateY(-11px) rotate(-45deg)' : 'none'}
              />
            </Flex>
            <m.div
              animate={hidden ? 'scrolled' : 'top'}
              initial={true}
              variants={{
                top: {
                  height: 140,
                  scale: 1,
                },
                scrolled: {
                  height: 88,
                  scale: 0.74,
                },
              }}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Link href="/" stylingProps={{ cursor: 'pointer' }}>
                <a>
                  <Shape width="124px" height="90px" icon={<Logo />} />
                </a>
              </Link>
            </m.div>
          </Flex>
          <Stack
            space={[4]}
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="center"
            pr={4}
            pl={[29, 4]}
            py={3}
            opacity={isOpen ? 1 : 0}
          >
            {content?.content?.navigation_links?.map(
              ({ hyperlink, name, icon }, i) => (
                <NavLink
                  href={getNavLinkHref(hyperlink)}
                  icon={icon}
                  text={name}
                  key={`key-${i}-${hyperlink}`}
                  onClick={() => setIsOpen(false)}
                />
              ),
            )}
          </Stack>
        </m.div>
      </Box>
    </>
  );
};
