import { WithStoryProps } from '@storyofams/storyblok-toolkit';
import { NextSeo } from 'next-seo';
import { defineMessages, useIntl } from 'react-intl';
import { Section, Layout } from '~components';
import { FooterComponent, NavigationComponent } from '../../graphql/sdk';

export interface DynamicPageProps extends WithStoryProps {
  footer: FooterComponent;
  navigation: NavigationComponent;
  story: any;
}

const messages = defineMessages({
  honest: 'What makes our cashews honest?',
});

export const DynamicPage = ({
  story,
  footer,
  navigation,
}: DynamicPageProps) => {
  const intl = useIntl();

  return (
    <>
      <NextSeo
        {...story?.content?.seo}
        title={story?.content?.seo?.title || 'Honest Cashew'}
        description={
          story?.content?.seo?.description ||
          intl.formatMessage(messages.honest)
        }
      />
      <Layout footer={footer} navigation={navigation}>
        {story?.content?.body.map((section, i) => (
          <Section
            sectionType={section.component}
            content={section}
            first={!!(i === 0)}
            key={`section-${i}`}
            extraPadding={
              story?.content?.body?.[i + 1]?.component === 'content section' &&
              story?.content?.body?.[i + 1]?.body?.[0]?.component ===
                'gallery' && [100, 136]
            }
          />
        ))}
      </Layout>
    </>
  );
};
