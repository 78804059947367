import { Flex, Icon, Text, css, SystemProps } from '@storyofams/react-ui';
import { useRouter } from 'next/router';

import {
  Cashews,
  Flag,
  Marker,
  Inbox,
  Home,
} from '~components/common/Icon/library';
import { Link } from '../Link';

type NavLinkProps = {
  href: string;
  icon: 'flag' | 'home' | 'cashews' | 'marker' | 'letter';
  text: string;
  onClick?(): void;
} & SystemProps;

export const NavLink = ({
  href,
  icon,
  text,
  onClick,
  ...props
}: NavLinkProps) => {
  const router = useRouter();
  let iconType = null;

  switch (icon) {
    case 'flag':
      iconType = Flag;
      break;
    case 'home':
      iconType = Home;
      break;
    case 'cashews':
      iconType = Cashews;
      break;
    case 'marker':
      iconType = Marker;
      break;
    case 'letter':
      iconType = Inbox;
  }

  return (
    <Link
      href={href}
      onClick={onClick}
      stylingProps={{
        css: css({
          fontSize: [2.25, 2.25, 1.5],
          textTransform: 'uppercase',
          textAlign: ['left', 'left', 'center'],
          color:
            router?.asPath === href ? ['black', 'banana'] : ['grey', 'banana'],
          a: {
            display: 'flex',
            flexDirection: ['row', 'row', 'column'],
            alignItems: 'center',
          },
          svg: {
            transition: '0.18s color ease-in-out, 0.18s transform ease-in-out',
          },
          '&:hover': {
            color: ['black', 'banana'],
            svg: {
              color: ['lapiz', 'cashew'],
              transform: 'scale(1.1)',
            },
          },
          ...props,
        }),
      }}
    >
      <Flex variant="center" width="56px">
        <Icon
          mb={[0, 0, 1]}
          mr={[2, 2, 0]}
          color={
            router?.asPath === href ? ['lapiz', 'cashew'] : ['lapiz', 'white']
          }
          icon={iconType}
        />
      </Flex>
      <Text>{text}</Text>
    </Link>
  );
};
