import { Stack, Flex, SystemProps, Text } from '@storyofams/react-ui';

import { ContentImage } from '~components';
import { getLinkHref } from '~lib';
import { Tag } from '~components/common/Icon/library';
import section from '~styles/theme/section';
import { Title, Paragraph } from '../Blocks';
import { Button } from '../Button';
import { Container } from '../Container';
import { Shape } from '../Shape';
import { Video } from '../Video';
import { Sticker } from './Sticker';

type FeaturedAssetProps = {
  first?: boolean;
  content: {
    background_colour: 'banana' | 'tiger' | 'fig' | 'aloe' | 'lapiz';
    button_text?: string;
    button_link?: {
      cached_url?: string;
      fieldtype?: string;
      id?: string;
      linktype?: string;
      url?: string;
      email?: string;
    };
    text: any;
    title?: any;
    youtube_url?: string;
    preview_video?: any;
    image?: any;
    sticker_type?:
      | 'boundaries'
      | 'cashews'
      | 'check'
      | 'factory'
      | 'good'
      | 'journey'
      | 'nuts'
      | 'promise';
    sticker_alignment?: 'left' | 'right';
    asset_position?: 'top' | 'left' | 'right';
    tags?: { text: string }[];
    lapiz_bold_colour?: boolean;
  };
} & SystemProps;

export const FeaturedAsset = ({
  content,
  first,
  ...props
}: FeaturedAssetProps) => {
  const { bg, bold, button, link, paragraph, title } =
    section[content.background_colour] || section.banana;

  return (
    <Container
      bgColor={bg}
      childProps={{
        pt:
          (first &&
            !(
              !!content?.preview_video?.filename || !!content?.image?.filename
            )) ||
          (first && content?.asset_position !== 'top')
            ? [148, 280]
            : [10, 20],
        flexDirection:
          content?.asset_position === 'top'
            ? 'column'
            : [
                'column',
                content?.asset_position === 'left' ? 'row' : 'row-reverse',
              ],
      }}
      {...props}
    >
      {content?.youtube_url &&
        (content?.preview_video?.filename || content?.image?.filename) && (
          <Video
            maxWidth={
              content?.asset_position === 'top' ? 'none' : ['none', '480px']
            }
            src={content?.youtube_url}
            previewVideo={content?.preview_video}
            previewImage={content?.image}
          />
        )}
      {content?.image?.filename &&
        !(content?.preview_video?.filename || content?.youtube_url) && (
          <ContentImage
            height={[200, 430]}
            maxWidth={
              content?.asset_position === 'left' ||
              content?.asset_position === 'right'
                ? 440
                : 'none'
            }
            content={content?.image}
          />
        )}
      <Stack
        flexDirection="column"
        space={[3, 5]}
        alignItems={[
          'center',
          content?.asset_position === 'top' ? 'center' : 'flex-start',
        ]}
        textAlign={[
          'center',
          content?.asset_position === 'top' ? 'center' : 'left',
        ]}
      >
        {!!content?.title?.content?.[0].content && (
          <Title
            text={content?.title}
            boldColor={!!content?.lapiz_bold_colour ? 'lapiz' : bold}
            titleColor={title}
            h1={first}
            fontSize={content?.asset_position === 'top' ? [5, 7] : [4, 6]}
          />
        )}
        {!!content?.text?.content?.[0].content && (
          <Paragraph
            text={content?.text}
            boldColor={bold}
            linkColor={link}
            fontSize={content?.asset_position === 'top' ? [2, 2.5] : [2, 2.25]}
            paragraphColor={paragraph}
          />
        )}
        {content?.tags?.length > 0 && (
          <Flex
            flexWrap="wrap"
            maxWidth="800px"
            justifyContent={[
              'center',
              content?.asset_position === 'top' ? 'center' : 'flex-start',
            ]}
          >
            {content?.tags?.map(({ text }, i) => (
              <Text
                position="relative"
                display="inline-flex"
                px={1.75}
                py={0.75}
                mb={1.75}
                mr={1.75}
                fontSize={2.25}
                color={bold}
                key={`key-${i}`}
              >
                <Shape
                  color={bold}
                  position="absolute"
                  left="0"
                  top="0"
                  width="100%"
                  height="100%"
                  icon={<Tag />}
                />
                {text}
              </Text>
            ))}
          </Flex>
        )}
        {content?.button_text && content?.button_link && (
          <Button variant={button as any} to={getLinkHref(content)}>
            {content?.button_text}
          </Button>
        )}
      </Stack>
      {content?.sticker_type && (
        <Sticker
          align={content?.sticker_alignment}
          type={content?.sticker_type}
        />
      )}
    </Container>
  );
};
