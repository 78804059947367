import { Box, Flex, Text, SystemProps, css } from '@storyofams/react-ui';
import styled, { keyframes } from 'styled-components';
import { Cashew, ArrowDown } from '~components/common/Icon/library';

import section from '~styles/theme/section';
import { ContentImage, Title, Paragraph } from '../Blocks';
import { Container } from '../Container';
import { Shape } from '../Shape';
import { Sticker } from './Sticker';

type QuoteProps = {
  first?: boolean;
  content: {
    background_colour: 'banana' | 'tiger' | 'fig' | 'aloe' | 'lapiz';
    pre_quote?: any;
    quote: any;
    sticker_type?:
      | 'boundaries'
      | 'cashews'
      | 'check'
      | 'factory'
      | 'good'
      | 'journey'
      | 'nuts'
      | 'promise';
    sticker_alignment?: 'left' | 'right';
    image?: any;
    text?: any;
    lapiz_bold_colour?: boolean;
  };
} & SystemProps;

export const Quote = ({ content, first, ...props }: QuoteProps) => {
  const { bg, bold, link, icon, paragraph, title } =
    section[content.background_colour] || section.banana;

  return (
    <Container
      childProps={{
        pt: first && !!!content?.image?.filename ? [148, 280] : [10, 20],
      }}
      bgColor={bg}
      textAlign="center"
      {...props}
    >
      {content?.image?.filename && (
        <ContentImage
          content={content?.image}
          width={[160, 240]}
          height={[160, 240]}
          borderWidth="7px"
          borderColor="white"
          borderStyle="solid"
          borderRadius="full"
          css={css({ img: { borderRadius: 'full' } })}
        />
      )}
      <Box position="relative">
        <Quote1
          color={icon as any}
          icon={Cashew}
          position="absolute"
          left={['6px', '-64px']}
          top={['-32px', '-120px']}
          width={['44px', '126px']}
          height={['70px', '202px']}
          transform="rotate(4deg)"
        />
        <Quote2
          color={icon as any}
          icon={Cashew}
          position="absolute"
          left={['56px', '64px']}
          top={['-32px', '-120px']}
          width={['44px', '126px']}
          height={['70px', '202px']}
        />
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          {content?.pre_quote && (
            <Text
              color={paragraph}
              fontSize={[2, 2.5]}
              lineHeight="high"
              maxWidth="480px"
              position="relative"
              letterSpacing="0.1em"
              mb={[1, 2]}
              px={['100px', 0]}
            >
              {content?.pre_quote}
            </Text>
          )}
          {!!content?.quote?.content?.[0].content && (
            <Title
              text={content?.quote}
              boldColor={!!content?.lapiz_bold_colour ? 'lapiz' : bold}
              titleColor={title}
              zIndex={1}
              position="relative"
              fontSize={[5, 8]}
              h1={first}
            />
          )}
        </Flex>
        <Quote3
          color={icon as any}
          icon={Cashew}
          position="absolute"
          right={['6px', '-64px']}
          bottom={['-32px', '-120px']}
          width={['44px', '126px']}
          height={['70px', '202px']}
        />
        <Quote4
          color={icon as any}
          icon={Cashew}
          position="absolute"
          right={['56px', '64px']}
          bottom={['-32px', '-120px']}
          width={['44px', '126px']}
          height={['70px', '202px']}
        />
      </Box>
      {content?.text?.content?.[0].content && (
        <Paragraph
          text={content?.text}
          boldColor={bold}
          linkColor={link}
          paragraphColor={paragraph}
          position="relative"
        />
      )}
      <Arrow
        height={['38px', '70px']}
        width={['12px', '22px']}
        color={paragraph as any}
        icon={ArrowDown}
      />
      {content?.sticker_type && (
        <Sticker
          align={content?.sticker_alignment}
          type={content?.sticker_type}
        />
      )}
    </Container>
  );
};

const translate = keyframes`
  0% {
    transform: translateY(6px) rotate(4deg);
  }

  50% {
    transform: translateY(-6px) rotate(4deg);
  }

  100% {
    transform: translateY(6px) rotate(4deg);
  }
`;

const rotate = keyframes`
  0% {
    transform: rotate(-1deg);
  }

  40% {
    transform: rotate(1deg);
  }

  100% {
    transform: rotate(-1deg);
  }
`;

const Quote1 = styled(Shape)`
  animation: ${translate} 8s cubic-bezier(0.45, 0, 0.55, 1) infinite;

  > div {
    animation: ${rotate} 8s cubic-bezier(0.45, 0, 0.55, 1) infinite;
  }
`;

const translate2 = keyframes`
  0% {
    transform: translateY(4px);
  }

  60% {
    transform: translateY(-6px);
  }

  100% {
    transform: translateY(4px);
  }
`;

const rotate2 = keyframes`
  0% {
    transform: rotate(-1deg);
  }

  40% {
    transform: rotate(1deg);
  }

  100% {
    transform: rotate(-1deg);
  }
`;

const Quote2 = styled(Shape)`
  animation: ${translate2} 10s cubic-bezier(0.45, 0, 0.55, 1) infinite;

  > div {
    animation: ${rotate2} 8s cubic-bezier(0.45, 0, 0.55, 1) infinite;
  }
`;

const translate3 = keyframes`
  0% {
    transform: translateY(-2px);
  }

  50% {
    transform: translateY(7px);
  }

  100% {
    transform: translateY(-2px);
  }
`;

const rotate3 = keyframes`
  0% {
    transform: rotate(-156deg);
  }

  40% {
    transform: rotate(-157deg);
  }

  100% {
    transform: rotate(-156deg);
  }
`;

const Quote3 = styled(Shape)`
  animation: ${translate3} 10s cubic-bezier(0.45, 0, 0.55, 1) infinite;

  > div {
    animation: ${rotate3} 8s cubic-bezier(0.45, 0, 0.55, 1) infinite;
  }
`;

const translate4 = keyframes`
  0% {
    transform: translateY(3px);
  }

  60% {
    transform: translateY(-8px);
  }

  100% {
    transform: translateY(3px);
  }
`;

const rotate4 = keyframes`
  0% {
    transform: rotate(-160deg)
  }

  40% {
    transform: rotate(-158deg)
  }

  100% {
    transform: rotate(-160deg);
  }
`;

const Quote4 = styled(Shape)`
  animation: ${translate4} 8s cubic-bezier(0.45, 0, 0.55, 1) infinite;

  > div {
    animation: ${rotate4} 8s cubic-bezier(0.45, 0, 0.55, 1) infinite;
  }
`;

const Arrow = styled(Shape)`
  animation: ${translate2} 6s cubic-bezier(0.45, 0, 0.55, 1) infinite;
`;
