import * as React from 'react';

function Nuts() {
  return (
    <svg
      width="70"
      height="70"
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35 69.5C54.1643 69.5 69.7 53.9643 69.7 34.8C69.7 15.6357 54.1643 0.100006 35 0.100006C15.8357 0.100006 0.300018 15.6357 0.300018 34.8C0.300018 53.9643 15.8357 69.5 35 69.5Z"
        fill="#EDD279"
      />
      <path
        d="M14.6 25.7C14.3 25.8 13.4 26 12.7 26C10.2 26 9.10004 24.8 9.10004 22C9.10004 19.3 10.3 17.9 12.9 17.9C13.7 17.9 14.3 18.1 14.6 18.2L14.9 18.5V19.8L14.6 20.1C14.3 20 13.5 19.8 12.9 19.8C11.7 19.8 11.5 20.7 11.5 22C11.5 23.4 11.7 24.2 12.8 24.2C13.3 24.2 14.3 24 14.6 23.9L14.9 24.1V25.5L14.6 25.7Z"
        fill="#DE773C"
      />
      <path
        d="M22.9 25.7L21.1 26L20.8 25.8L20.3 24H18.1L17.5 25.8L17.2 26L15.5 25.7L15.3 25.4L18 18.4L18.3 18.1H20.1L20.4 18.4L23 25.4L22.9 25.7ZM19.2 20.5L18.6 22.2H19.7L19.2 20.5Z"
        fill="#DE773C"
      />
      <path
        d="M26.2 26C25.1 26 24.4 25.8 23.9 25.7L23.6 25.4V24L23.9 23.8C24.3 23.9 25.6 24.1 26.1 24.1C26.4 24.1 26.6 24 26.6 23.7C26.6 22.7 23.6 22.9 23.6 20.2C23.6 18.6 24.8 17.9 26.4 17.9C27.3 17.9 28.1 18 28.6 18.2L28.9 18.5V19.8L28.6 20.1C28.2 20 27.1 19.8 26.6 19.8C26.2 19.8 26.1 19.9 26.1 20.2C26.1 21.1 29.2 20.9 29.2 23.7C29 25.3 27.8 26 26.2 26Z"
        fill="#DE773C"
      />
      <path
        d="M36.7 25.8H34.9L34.7 25.5V22.5H32.5V25.5L32.2 25.8H30.4L30.2 25.5V18.3L30.4 18H32.2L32.5 18.3V20.8H34.6V18.3L34.8 18H36.6L36.9 18.3V25.5L36.7 25.8Z"
        fill="#DE773C"
      />
      <path
        d="M43.2 25.8H38.7L38.4 25.5V18.3L38.7 18H43.1L43.4 18.3V19.6L43.1 19.9H40.8V20.9H42.9L43.2 21.2V22.5L42.9 22.8H40.8V24.2H43.2L43.5 24.5V25.8H43.2Z"
        fill="#DE773C"
      />
      <path
        d="M52.8 25.6L52.5 25.9H50.4L50.1 25.6L49.4 21.3L48.6 25.6L48.3 25.9H46.2L45.9 25.6L43.8 18.5L44 18.2L46 18L46.3 18.2L47.3 23.2L48 18.4L48.3 18.1H50.4L50.7 18.4L51.5 23.2L52.6 18.2L52.9 18L54.7 18.2L54.9 18.5L52.8 25.6Z"
        fill="#DE773C"
      />
      <path
        d="M58.1 26C57 26 56.3 25.8 55.8 25.7L55.5 25.4V24L55.8 23.8C56.2 23.9 57.5 24.1 58 24.1C58.3 24.1 58.5 24 58.5 23.7C58.5 22.7 55.5 22.9 55.5 20.2C55.5 18.6 56.7 17.9 58.3 17.9C59.2 17.9 60 18 60.5 18.2L60.8 18.5V19.8L60.5 20.1C60.1 20 59 19.8 58.5 19.8C58.1 19.8 58 19.9 58 20.2C58 21.1 61.1 20.9 61.1 23.7C61 25.3 59.7 26 58.1 26Z"
        fill="#DE773C"
      />
      <path
        d="M12.7 35.7L10.9 36L10.6 35.8L10.1 34H7.90001L7.30002 35.8L7.00002 36L5.40001 35.7L5.20001 35.4L7.90001 28.4L8.20001 28.1H10L10.3 28.4L12.9 35.4L12.7 35.7ZM9.10002 30.5L8.50002 32.2H9.60002L9.10002 30.5Z"
        fill="#DE773C"
      />
      <path
        d="M19.8 35.6L18.1 36L17.8 35.8L16.1 32.8H15.9V35.5L15.6 35.8H13.8L13.5 35.5V28.3L13.8 28H16.8C18.7 28 19.7 28.7 19.7 30.2C19.7 31.5 18.8 32 18.1 32.3L19.9 35.1L19.8 35.6ZM16.7 29.8H15.9V31.1H16.4C16.9 31.1 17.5 31.1 17.5 30.4C17.5 29.9 17.4 29.8 16.7 29.8Z"
        fill="#DE773C"
      />
      <path
        d="M25.6 35.8H21.1L20.8 35.5V28.3L21.1 28H25.5L25.8 28.3V29.6L25.5 29.9H23.2V30.9H25.3L25.6 31.2V32.5L25.3 32.8H23.2V34.2H25.6L25.9 34.5V35.8H25.6Z"
        fill="#DE773C"
      />
      <path
        d="M35.6 35.7C35.3 35.8 33.7 36 33.1 36C30.4 36 29.3 34.8 29.3 32C29.3 29.4 30.5 27.9 33.2 27.9C34.1 27.9 35 28.2 35.4 28.3L35.7 28.6V29.9L35.4 30.2C35 30.1 34 29.9 33.2 29.9C31.9 29.9 31.8 30.8 31.8 32.1C31.8 33.5 32.1 34.3 33.2 34.3C33.3 34.3 33.7 34.3 33.8 34.3V33.3H33.3L33 33V31.7L33.3 31.4H35.7L36 31.7V35.5L35.6 35.7Z"
        fill="#DE773C"
      />
      <path
        d="M40.7 36C38.2 36 36.9 34.5 36.9 32C36.9 29.6 38.1 27.9 40.8 27.9C43.4 27.9 44.6 29.5 44.6 31.9C44.5 34.5 43.3 36 40.7 36ZM40.7 29.8C39.6 29.8 39.3 30.8 39.3 32C39.3 33.2 39.6 34.2 40.7 34.2C41.8 34.2 42.1 33.2 42.1 32C42.1 30.8 41.8 29.8 40.7 29.8Z"
        fill="#DE773C"
      />
      <path
        d="M47.7 35.8H45.9L45.6 35.5V28.3L45.9 28H47.7L48 28.3V35.5L47.7 35.8Z"
        fill="#DE773C"
      />
      <path
        d="M56.2 35.8H53.8L53.5 35.5L51.5 31.4L51.7 31.9V35.4L51.4 35.7H49.7L49.4 35.4V28.2L49.7 27.9H52L52.3 28.2L54.4 32.3L54.2 31.8V28.3L54.5 28H56.2L56.5 28.3V35.5L56.2 35.8Z"
        fill="#DE773C"
      />
      <path
        d="M63.9 35.7C63.6 35.8 62 36 61.4 36C58.7 36 57.6 34.8 57.6 32C57.6 29.4 58.8 27.9 61.5 27.9C62.4 27.9 63.3 28.2 63.7 28.3L64 28.6V29.9L63.7 30.2C63.3 30.1 62.3 29.9 61.5 29.9C60.2 29.9 60.1 30.8 60.1 32.1C60.1 33.5 60.4 34.3 61.5 34.3C61.6 34.3 62 34.3 62.1 34.3V33.3H61.5L61.2 33V31.7L61.5 31.4H63.9L64.2 31.7V35.5L63.9 35.7Z"
        fill="#DE773C"
      />
      <path
        d="M27.8 45.8H25.5L25.2 45.5L23.2 41.4L23.4 41.9V45.4L23.1 45.7H21.4L21.1 45.4V38.2L21.4 37.9H23.7L24 38.2L26.1 42.3L25.9 41.8V38.3L26.2 38H27.9L28.2 38.3V45.5L27.8 45.8Z"
        fill="#6A50A1"
      />
      <path
        d="M36.2 45.7L34.5 46.1L34.2 45.9C34.2 45.7 34 45.1 34 44.9C33.5 45.4 32.7 46 31.5 46C30.2 46 29.5 45.1 29.5 43.4V38.4L29.7 38.1H31.5L31.8 38.4V43.2C31.8 43.8 31.9 44.1 32.3 44.1C32.8 44.1 33.5 43.7 33.7 43.4V38.3L33.9 38H35.7L36 38.3V43.7C36 44.1 36.3 45.1 36.4 45.3L36.2 45.7Z"
        fill="#6A50A1"
      />
      <path
        d="M43 39.9H41.2V45.6L40.9 45.9H39.1L38.8 45.6V39.9H37L36.7 39.6V38.3L37 38H42.8L43.1 38.3V39.6L43 39.9Z"
        fill="#6A50A1"
      />
      <path
        d="M46.6 46C45.5 46 44.8 45.8 44.3 45.7L44 45.4V44L44.3 43.8C44.7 43.9 46 44.1 46.5 44.1C46.8 44.1 47 44 47 43.7C47 42.7 44 42.9 44 40.2C44 38.6 45.2 37.9 46.8 37.9C47.7 37.9 48.5 38 49 38.2L49.3 38.5V39.8L49 40.1C48.6 40 47.5 39.8 47 39.8C46.6 39.8 46.5 39.9 46.5 40.2C46.5 41.1 49.6 40.9 49.6 43.7C49.4 45.4 48.2 46 46.6 46Z"
        fill="#6A50A1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.6 52.6L42.2 55.6L45.1 60C46.8 62.5 50.8 62.8 54.1 60.7C57.4 58.5 58.7 54.7 57 52.1C56.9 52 56.9 51.9 56.8 51.8L53.4 47.5L48.6 52.6Z"
        fill="#DE773C"
      />
      <path
        d="M52.8 59.4C52.5 59.4 52.2 59.3 52.1 59L48.2 53.5C47.9 53.1 48 52.5 48.4 52.2C48.8 51.9 49.4 52 49.7 52.4L53.6 57.9C53.9 58.3 53.8 58.9 53.4 59.2C53.2 59.3 53 59.4 52.8 59.4Z"
        fill="#C46A35"
      />
      <path
        d="M39.3 58.1C31.9 59.7 24.2 58.1 18.1 53.6C17.7 53.3 17.6 52.8 17.9 52.4C18.2 52 18.7 51.9 19.1 52.2C24.8 56.3 32 57.9 38.9 56.4C45.8 54.9 51.7 50.5 55.2 44.4C55.4 44 56 43.8 56.4 44.1C56.8 44.3 57 44.9 56.7 45.3C53.1 51.8 46.7 56.5 39.3 58.1Z"
        fill="#35393E"
      />
    </svg>
  );
}

const MemoNuts = React.memo(Nuts);
export default MemoNuts;
