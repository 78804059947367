import * as React from 'react';

function QR() {
  return (
    <svg
      width="89"
      height="89"
      viewBox="0 0 89 89"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.19397 0.237403H3.22804V6.30687H0.19397V0.237403Z"
        fill="black"
      />
      <path
        d="M0.19397 6.30686H3.22804V12.3763H0.19397V6.30686Z"
        fill="black"
      />
      <path
        d="M0.19397 12.3763H3.22804V21.4799H0.19397V12.3763Z"
        fill="black"
      />
      <path d="M0.19397 24.514H3.22804V30.5834H0.19397V24.514Z" fill="black" />
      <path d="M0.19397 33.6175H3.22804V39.687H0.19397V33.6175Z" fill="black" />
      <path d="M0.19397 42.721H3.22804V63.9622H0.19397V42.721Z" fill="black" />
      <path
        d="M0.19397 66.9976H3.22804V88.2387H0.19397V66.9976Z"
        fill="black"
      />
      <path d="M3.22803 0.2374H6.2621V3.27147H3.22803V0.2374Z" fill="black" />
      <path d="M3.22803 18.4445H6.2621V21.4785H3.22803V18.4445Z" fill="black" />
      <path d="M3.22803 39.687H6.2621V42.721H3.22803V39.687Z" fill="black" />
      <path d="M3.22803 51.8246H6.2621V54.8586H3.22803V51.8246Z" fill="black" />
      <path d="M3.22803 66.9976H6.2621V70.0316H3.22803V66.9976Z" fill="black" />
      <path d="M3.22803 85.2047H6.2621V88.2387H3.22803V85.2047Z" fill="black" />
      <path d="M6.26215 0.2374H9.29622V3.27147H6.26215V0.2374Z" fill="black" />
      <path
        d="M6.26215 6.30686H9.29622V12.3763H6.26215V6.30686Z"
        fill="black"
      />
      <path
        d="M6.26215 12.3763H9.29622V15.4104H6.26215V12.3763Z"
        fill="black"
      />
      <path
        d="M6.26215 18.4445H9.29622V21.4785H6.26215V18.4445Z"
        fill="black"
      />
      <path d="M6.26215 24.5139H9.29622V27.548H6.26215V24.5139Z" fill="black" />
      <path
        d="M6.26215 30.5834H9.29622V33.6175H6.26215V30.5834Z"
        fill="black"
      />
      <path d="M6.26215 42.721H9.29622V45.7551H6.26215V42.721Z" fill="black" />
      <path
        d="M6.26215 48.7905H9.29622V51.8246H6.26215V48.7905Z"
        fill="black"
      />
      <path d="M6.26215 54.86H9.29622V63.9635H6.26215V54.86Z" fill="black" />
      <path
        d="M6.26215 66.9976H9.29622V70.0316H6.26215V66.9976Z"
        fill="black"
      />
      <path
        d="M6.26215 73.0671H9.29622V82.1706H6.26215V73.0671Z"
        fill="black"
      />
      <path
        d="M6.26215 85.2047H9.29622V88.2387H6.26215V85.2047Z"
        fill="black"
      />
      <path d="M9.29755 0.2374H12.3316V3.27147H9.29755V0.2374Z" fill="black" />
      <path
        d="M9.29755 6.30686H12.3316V12.3763H9.29755V6.30686Z"
        fill="black"
      />
      <path
        d="M9.29755 12.3763H12.3316V15.4104H9.29755V12.3763Z"
        fill="black"
      />
      <path
        d="M9.29755 18.4445H12.3316V21.4785H9.29755V18.4445Z"
        fill="black"
      />
      <path d="M9.29755 24.514H12.3316V42.721H9.29755V24.514Z" fill="black" />
      <path
        d="M9.29755 51.8246H12.3316V60.9281H9.29755V51.8246Z"
        fill="black"
      />
      <path
        d="M9.29755 66.9976H12.3316V70.0316H9.29755V66.9976Z"
        fill="black"
      />
      <path
        d="M9.29755 73.0671H12.3316V82.1706H9.29755V73.0671Z"
        fill="black"
      />
      <path
        d="M9.29755 85.2047H12.3316V88.2387H9.29755V85.2047Z"
        fill="black"
      />
      <path d="M12.3316 0.2374H15.3657V3.27147H12.3316V0.2374Z" fill="black" />
      <path
        d="M12.3316 6.30686H15.3657V12.3763H12.3316V6.30686Z"
        fill="black"
      />
      <path
        d="M12.3316 12.3763H15.3657V15.4104H12.3316V12.3763Z"
        fill="black"
      />
      <path
        d="M12.3316 18.4445H15.3657V21.4785H12.3316V18.4445Z"
        fill="black"
      />
      <path d="M12.3316 24.514H15.3657V30.5834H12.3316V24.514Z" fill="black" />
      <path
        d="M12.3316 42.7211H15.3657V48.7905H12.3316V42.7211Z"
        fill="black"
      />
      <path
        d="M12.3316 66.9976H15.3657V70.0316H12.3316V66.9976Z"
        fill="black"
      />
      <path
        d="M12.3316 73.0671H15.3657V82.1706H12.3316V73.0671Z"
        fill="black"
      />
      <path
        d="M12.3316 85.2047H15.3657V88.2387H12.3316V85.2047Z"
        fill="black"
      />
      <path d="M15.3657 0.2374H18.3998V3.27147H15.3657V0.2374Z" fill="black" />
      <path
        d="M15.3657 18.4445H18.3998V21.4785H15.3657V18.4445Z"
        fill="black"
      />
      <path d="M15.3657 24.514H18.3998V30.5834H15.3657V24.514Z" fill="black" />
      <path
        d="M15.3657 36.6515H18.3998V39.6856H15.3657V36.6515Z"
        fill="black"
      />
      <path d="M15.3657 42.721H18.3998V63.9622H15.3657V42.721Z" fill="black" />
      <path
        d="M15.3657 66.9976H18.3998V70.0316H15.3657V66.9976Z"
        fill="black"
      />
      <path
        d="M15.3657 85.2047H18.3998V88.2387H15.3657V85.2047Z"
        fill="black"
      />
      <path
        d="M18.401 0.237403H21.4351V6.30687H18.401V0.237403Z"
        fill="black"
      />
      <path d="M18.401 6.30686H21.4351V12.3763H18.401V6.30686Z" fill="black" />
      <path d="M18.401 12.3763H21.4351V21.4799H18.401V12.3763Z" fill="black" />
      <path d="M18.401 24.5139H21.4351V27.548H18.401V24.5139Z" fill="black" />
      <path d="M18.401 30.5834H21.4351V33.6175H18.401V30.5834Z" fill="black" />
      <path d="M18.401 36.6515H21.4351V39.6856H18.401V36.6515Z" fill="black" />
      <path d="M18.401 42.721H21.4351V45.7551H18.401V42.721Z" fill="black" />
      <path d="M18.401 48.7905H21.4351V51.8246H18.401V48.7905Z" fill="black" />
      <path d="M18.401 54.86H21.4351V57.894H18.401V54.86Z" fill="black" />
      <path d="M18.401 60.9281H21.4351V63.9622H18.401V60.9281Z" fill="black" />
      <path d="M18.401 66.9976H21.4351V88.2387H18.401V66.9976Z" fill="black" />
      <path d="M21.4351 27.548H24.4692V36.6516H21.4351V27.548Z" fill="black" />
      <path d="M21.4351 39.687H24.4692V42.721H21.4351V39.687Z" fill="black" />
      <path
        d="M21.4351 45.7551H24.4692V48.7892H21.4351V45.7551Z"
        fill="black"
      />
      <path d="M21.4351 51.8246H24.4692V57.894H21.4351V51.8246Z" fill="black" />
      <path
        d="M24.4692 6.30686H27.5033V12.3763H24.4692V6.30686Z"
        fill="black"
      />
      <path
        d="M24.4692 12.3763H27.5033V24.5139H24.4692V12.3763Z"
        fill="black"
      />
      <path d="M24.4692 27.548H27.5033V30.5821H24.4692V27.548Z" fill="black" />
      <path d="M24.4692 33.6175H27.5033V39.687H24.4692V33.6175Z" fill="black" />
      <path d="M24.4692 42.721H27.5033V51.8246H24.4692V42.721Z" fill="black" />
      <path d="M24.4692 54.86H27.5033V57.894H24.4692V54.86Z" fill="black" />
      <path
        d="M24.4692 60.9281H27.5033V66.9976H24.4692V60.9281Z"
        fill="black"
      />
      <path
        d="M24.4692 70.0316H27.5033V82.1693H24.4692V70.0316Z"
        fill="black"
      />
      <path
        d="M24.4692 85.2047H27.5033V88.2387H24.4692V85.2047Z"
        fill="black"
      />
      <path d="M27.5046 9.34093H30.5386V12.375H27.5046V9.34093Z" fill="black" />
      <path
        d="M27.5046 12.3763H30.5386V15.4104H27.5046V12.3763Z"
        fill="black"
      />
      <path d="M27.5046 24.5139H30.5386V27.548H27.5046V24.5139Z" fill="black" />
      <path d="M27.5046 30.5834H30.5386V39.687H27.5046V30.5834Z" fill="black" />
      <path
        d="M27.5046 48.7905H30.5386V70.0316H27.5046V48.7905Z"
        fill="black"
      />
      <path
        d="M27.5046 73.0671H30.5386V76.1011H27.5046V73.0671Z"
        fill="black"
      />
      <path
        d="M27.5046 79.1352H30.5386V88.2387H27.5046V79.1352Z"
        fill="black"
      />
      <path
        d="M30.5386 0.237403H33.5727V6.30687H30.5386V0.237403Z"
        fill="black"
      />
      <path
        d="M30.5386 6.30686H33.5727V12.3763H30.5386V6.30686Z"
        fill="black"
      />
      <path
        d="M30.5386 15.4104H33.5727V21.4799H30.5386V15.4104Z"
        fill="black"
      />
      <path
        d="M30.5386 33.6175H33.5727V54.8586H30.5386V33.6175Z"
        fill="black"
      />
      <path
        d="M30.5386 60.9281H33.5727V70.0316H30.5386V60.9281Z"
        fill="black"
      />
      <path
        d="M30.5386 76.1011H33.5727V88.2387H30.5386V76.1011Z"
        fill="black"
      />
      <path d="M33.5728 0.2374H36.6068V3.27147H33.5728V0.2374Z" fill="black" />
      <path
        d="M33.5728 6.30686H36.6068V12.3763H33.5728V6.30686Z"
        fill="black"
      />
      <path
        d="M33.5728 21.4799H36.6068V27.5493H33.5728V21.4799Z"
        fill="black"
      />
      <path d="M33.5728 33.6175H36.6068V39.687H33.5728V33.6175Z" fill="black" />
      <path
        d="M33.5728 51.8246H36.6068V54.8586H33.5728V51.8246Z"
        fill="black"
      />
      <path
        d="M33.5728 60.9281H36.6068V63.9622H33.5728V60.9281Z"
        fill="black"
      />
      <path
        d="M33.5728 66.9976H36.6068V70.0316H33.5728V66.9976Z"
        fill="black"
      />
      <path
        d="M33.5728 73.0671H36.6068V76.1011H33.5728V73.0671Z"
        fill="black"
      />
      <path
        d="M33.5728 82.1706H36.6068V85.2047H33.5728V82.1706Z"
        fill="black"
      />
      <path d="M36.6082 9.34093H39.6422V12.375H36.6082V9.34093Z" fill="black" />
      <path
        d="M36.6082 12.3763H39.6422V15.4104H36.6082V12.3763Z"
        fill="black"
      />
      <path d="M36.6082 18.4445H39.6422V27.548H36.6082V18.4445Z" fill="black" />
      <path d="M36.6082 33.6175H39.6422V39.687H36.6082V33.6175Z" fill="black" />
      <path d="M36.6082 42.721H39.6422V51.8246H36.6082V42.721Z" fill="black" />
      <path
        d="M36.6082 54.8599H39.6422V60.9294H36.6082V54.8599Z"
        fill="black"
      />
      <path
        d="M36.6082 66.9976H39.6422V76.1011H36.6082V66.9976Z"
        fill="black"
      />
      <path
        d="M36.6082 79.1352H39.6422V82.1693H36.6082V79.1352Z"
        fill="black"
      />
      <path
        d="M36.6082 85.2047H39.6422V88.2387H36.6082V85.2047Z"
        fill="black"
      />
      <path
        d="M39.6422 0.237403H42.6763V6.30687H39.6422V0.237403Z"
        fill="black"
      />
      <path
        d="M39.6422 6.30686H42.6763V9.34093H39.6422V6.30686Z"
        fill="black"
      />
      <path d="M39.6422 27.548H42.6763V36.6516H39.6422V27.548Z" fill="black" />
      <path
        d="M39.6422 51.8246H42.6763V60.9281H39.6422V51.8246Z"
        fill="black"
      />
      <path d="M39.6422 82.1706H42.6763V88.24H39.6422V82.1706Z" fill="black" />
      <path
        d="M42.6763 0.237403H45.7104V6.30687H42.6763V0.237403Z"
        fill="black"
      />
      <path d="M42.6763 9.34093H45.7104V12.375H42.6763V9.34093Z" fill="black" />
      <path
        d="M42.6763 18.4445H45.7104V24.5139H42.6763V18.4445Z"
        fill="black"
      />
      <path d="M42.6763 27.548H45.7104V33.6175H42.6763V27.548Z" fill="black" />
      <path
        d="M42.6763 36.6515H45.7104V39.6856H42.6763V36.6515Z"
        fill="black"
      />
      <path
        d="M42.6763 45.7551H45.7104V48.7892H42.6763V45.7551Z"
        fill="black"
      />
      <path d="M42.6763 51.8246H45.7104V57.894H42.6763V51.8246Z" fill="black" />
      <path
        d="M42.6763 60.9281H45.7104V63.9622H42.6763V60.9281Z"
        fill="black"
      />
      <path
        d="M42.6763 70.0317H45.7104V73.0657H42.6763V70.0317Z"
        fill="black"
      />
      <path
        d="M42.6763 76.1011H45.7104V79.1352H42.6763V76.1011Z"
        fill="black"
      />
      <path
        d="M42.6763 85.2047H45.7104V88.2387H42.6763V85.2047Z"
        fill="black"
      />
      <path
        d="M45.7117 0.237403H48.7457V6.30687H45.7117V0.237403Z"
        fill="black"
      />
      <path
        d="M45.7117 6.30686H48.7457V12.3763H45.7117V6.30686Z"
        fill="black"
      />
      <path
        d="M45.7117 12.3763H48.7457V15.4104H45.7117V12.3763Z"
        fill="black"
      />
      <path d="M45.7117 27.548H48.7457V42.721H45.7117V27.548Z" fill="black" />
      <path
        d="M45.7117 51.8246H48.7457V66.9976H45.7117V51.8246Z"
        fill="black"
      />
      <path
        d="M45.7117 76.1011H48.7457V88.2387H45.7117V76.1011Z"
        fill="black"
      />
      <path d="M48.7457 3.2728H51.7798V6.30687H48.7457V3.2728Z" fill="black" />
      <path
        d="M48.7457 12.3763H51.7798V15.4104H48.7457V12.3763Z"
        fill="black"
      />
      <path d="M48.7457 18.4445H51.7798V27.548H48.7457V18.4445Z" fill="black" />
      <path
        d="M48.7457 30.5834H51.7798V33.6175H48.7457V30.5834Z"
        fill="black"
      />
      <path d="M48.7457 39.687H51.7798V42.721H48.7457V39.687Z" fill="black" />
      <path
        d="M48.7457 45.7551H51.7798V51.8246H48.7457V45.7551Z"
        fill="black"
      />
      <path d="M48.7457 54.86H51.7798V57.894H48.7457V54.86Z" fill="black" />
      <path
        d="M48.7457 63.9635H51.7798V66.9976H48.7457V63.9635Z"
        fill="black"
      />
      <path
        d="M48.7457 70.0317H51.7798V73.0657H48.7457V70.0317Z"
        fill="black"
      />
      <path
        d="M48.7457 85.2047H51.7798V88.2387H48.7457V85.2047Z"
        fill="black"
      />
      <path
        d="M51.7812 0.237403H54.8153V6.30687H51.7812V0.237403Z"
        fill="black"
      />
      <path d="M51.7812 9.34093H54.8153V12.375H51.7812V9.34093Z" fill="black" />
      <path
        d="M51.7812 15.4104H54.8153V18.4445H51.7812V15.4104Z"
        fill="black"
      />
      <path d="M51.7812 24.5139H54.8153V27.548H51.7812V24.5139Z" fill="black" />
      <path
        d="M51.7812 36.6515H54.8153V39.6856H51.7812V36.6515Z"
        fill="black"
      />
      <path d="M51.7812 42.721H54.8153V45.7551H51.7812V42.721Z" fill="black" />
      <path d="M51.7812 48.7905H54.8153V57.894H51.7812V48.7905Z" fill="black" />
      <path
        d="M51.7812 60.9281H54.8153V63.9622H51.7812V60.9281Z"
        fill="black"
      />
      <path
        d="M51.7812 73.0671H54.8153V76.1011H51.7812V73.0671Z"
        fill="black"
      />
      <path
        d="M51.7812 85.2047H54.8153V88.2387H51.7812V85.2047Z"
        fill="black"
      />
      <path d="M54.8152 0.2374H57.8493V3.27147H54.8152V0.2374Z" fill="black" />
      <path
        d="M54.8152 6.30686H57.8493V9.34093H54.8152V6.30686Z"
        fill="black"
      />
      <path
        d="M54.8152 18.4445H57.8493V21.4785H54.8152V18.4445Z"
        fill="black"
      />
      <path d="M54.8152 27.548H57.8493V30.5821H54.8152V27.548Z" fill="black" />
      <path d="M54.8152 39.6869H57.8493V54.86H54.8152V39.6869Z" fill="black" />
      <path
        d="M54.8152 57.8941H57.8493V60.9281H54.8152V57.8941Z"
        fill="black"
      />
      <path d="M54.8152 63.9635H57.8493V70.033H54.8152V63.9635Z" fill="black" />
      <path
        d="M54.8152 79.1352H57.8493V85.2047H54.8152V79.1352Z"
        fill="black"
      />
      <path d="M57.8492 0.2374H60.8833V3.27147H57.8492V0.2374Z" fill="black" />
      <path d="M57.8492 9.34093H60.8833V12.375H57.8492V9.34093Z" fill="black" />
      <path
        d="M57.8492 12.3763H60.8833V15.4104H57.8492V12.3763Z"
        fill="black"
      />
      <path d="M57.8492 27.548H60.8833V30.5821H57.8492V27.548Z" fill="black" />
      <path d="M57.8492 36.6516H60.8833V42.721H57.8492V36.6516Z" fill="black" />
      <path
        d="M57.8492 45.7551H60.8833V48.7892H57.8492V45.7551Z"
        fill="black"
      />
      <path
        d="M57.8492 51.8246H60.8833V54.8586H57.8492V51.8246Z"
        fill="black"
      />
      <path d="M57.8492 66.9976H60.8833V73.067H57.8492V66.9976Z" fill="black" />
      <path
        d="M57.8492 85.2047H60.8833V88.2387H57.8492V85.2047Z"
        fill="black"
      />
      <path
        d="M60.8846 6.30686H63.9187V9.34093H60.8846V6.30686Z"
        fill="black"
      />
      <path
        d="M60.8846 12.3763H63.9187V15.4104H60.8846V12.3763Z"
        fill="black"
      />
      <path
        d="M60.8846 18.4445H63.9187V21.4785H60.8846V18.4445Z"
        fill="black"
      />
      <path
        d="M60.8846 24.5139H63.9187V36.6516H60.8846V24.5139Z"
        fill="black"
      />
      <path d="M60.8846 42.721H63.9187V76.1011H60.8846V42.721Z" fill="black" />
      <path
        d="M60.8846 79.1352H63.9187V85.2047H60.8846V79.1352Z"
        fill="black"
      />
      <path
        d="M63.9188 33.6175H66.9528V36.6516H63.9188V33.6175Z"
        fill="black"
      />
      <path
        d="M63.9188 39.6869H66.9528V45.7564H63.9188V39.6869Z"
        fill="black"
      />
      <path d="M63.9188 51.8246H66.9528V57.894H63.9188V51.8246Z" fill="black" />
      <path
        d="M63.9188 60.9281H66.9528V63.9622H63.9188V60.9281Z"
        fill="black"
      />
      <path
        d="M63.9188 73.0671H66.9528V76.1011H63.9188V73.0671Z"
        fill="black"
      />
      <path
        d="M63.9188 82.1706H66.9528V85.2047H63.9188V82.1706Z"
        fill="black"
      />
      <path
        d="M66.9528 0.237403H69.9869V6.30687H66.9528V0.237403Z"
        fill="black"
      />
      <path
        d="M66.9528 6.30686H69.9869V12.3763H66.9528V6.30686Z"
        fill="black"
      />
      <path
        d="M66.9528 12.3763H69.9869V21.4799H66.9528V12.3763Z"
        fill="black"
      />
      <path d="M66.9528 24.514H69.9869V30.5834H66.9528V24.514Z" fill="black" />
      <path d="M66.9528 39.687H69.9869V42.721H66.9528V39.687Z" fill="black" />
      <path
        d="M66.9528 45.7551H69.9869V48.7892H66.9528V45.7551Z"
        fill="black"
      />
      <path
        d="M66.9528 51.8246H69.9869V54.8586H66.9528V51.8246Z"
        fill="black"
      />
      <path
        d="M66.9528 60.9281H69.9869V63.9622H66.9528V60.9281Z"
        fill="black"
      />
      <path
        d="M66.9528 66.9976H69.9869V70.0316H66.9528V66.9976Z"
        fill="black"
      />
      <path
        d="M66.9528 73.0671H69.9869V76.1011H66.9528V73.0671Z"
        fill="black"
      />
      <path
        d="M66.9528 79.1352H69.9869V82.1693H66.9528V79.1352Z"
        fill="black"
      />
      <path
        d="M66.9528 85.2047H69.9869V88.2387H66.9528V85.2047Z"
        fill="black"
      />
      <path d="M69.9882 0.2374H73.0223V3.27147H69.9882V0.2374Z" fill="black" />
      <path
        d="M69.9882 18.4445H73.0223V21.4785H69.9882V18.4445Z"
        fill="black"
      />
      <path d="M69.9882 24.514H73.0223V33.6175H69.9882V24.514Z" fill="black" />
      <path
        d="M69.9882 36.6516H73.0223V48.7892H69.9882V36.6516Z"
        fill="black"
      />
      <path
        d="M69.9882 51.8246H73.0223V54.8586H69.9882V51.8246Z"
        fill="black"
      />
      <path d="M69.9882 57.894H73.0223V63.9635H69.9882V57.894Z" fill="black" />
      <path
        d="M69.9882 73.0671H73.0223V76.1011H69.9882V73.0671Z"
        fill="black"
      />
      <path
        d="M69.9882 79.1352H73.0223V88.2387H69.9882V79.1352Z"
        fill="black"
      />
      <path d="M73.0223 0.2374H76.0563V3.27147H73.0223V0.2374Z" fill="black" />
      <path
        d="M73.0223 6.30686H76.0563V12.3763H73.0223V6.30686Z"
        fill="black"
      />
      <path
        d="M73.0223 12.3763H76.0563V15.4104H73.0223V12.3763Z"
        fill="black"
      />
      <path
        d="M73.0223 18.4445H76.0563V21.4785H73.0223V18.4445Z"
        fill="black"
      />
      <path d="M73.0223 24.514H76.0563V30.5834H73.0223V24.514Z" fill="black" />
      <path
        d="M73.0223 33.6175H76.0563V36.6516H73.0223V33.6175Z"
        fill="black"
      />
      <path d="M73.0223 39.687H76.0563V48.7905H73.0223V39.687Z" fill="black" />
      <path
        d="M73.0223 51.8246H76.0563V54.8586H73.0223V51.8246Z"
        fill="black"
      />
      <path
        d="M73.0223 60.9281H76.0563V76.1011H73.0223V60.9281Z"
        fill="black"
      />
      <path
        d="M73.0223 79.1352H76.0563V82.1693H73.0223V79.1352Z"
        fill="black"
      />
      <path d="M76.0564 0.2374H79.0905V3.27147H76.0564V0.2374Z" fill="black" />
      <path
        d="M76.0564 6.30686H79.0905V12.3763H76.0564V6.30686Z"
        fill="black"
      />
      <path
        d="M76.0564 12.3763H79.0905V15.4104H76.0564V12.3763Z"
        fill="black"
      />
      <path
        d="M76.0564 18.4445H79.0905V21.4785H76.0564V18.4445Z"
        fill="black"
      />
      <path d="M76.0564 24.5139H79.0905V27.548H76.0564V24.5139Z" fill="black" />
      <path
        d="M76.0564 36.6515H79.0905V39.6856H76.0564V36.6515Z"
        fill="black"
      />
      <path d="M76.0564 51.8246H79.0905V57.894H76.0564V51.8246Z" fill="black" />
      <path d="M76.0564 63.9635H79.0905V73.067H76.0564V63.9635Z" fill="black" />
      <path
        d="M76.0564 82.1706H79.0905V85.2047H76.0564V82.1706Z"
        fill="black"
      />
      <path d="M79.0918 0.2374H82.1259V3.27147H79.0918V0.2374Z" fill="black" />
      <path
        d="M79.0918 6.30686H82.1259V12.3763H79.0918V6.30686Z"
        fill="black"
      />
      <path
        d="M79.0918 12.3763H82.1259V15.4104H79.0918V12.3763Z"
        fill="black"
      />
      <path
        d="M79.0918 18.4445H82.1259V21.4785H79.0918V18.4445Z"
        fill="black"
      />
      <path d="M79.0918 24.5139H82.1259V27.548H79.0918V24.5139Z" fill="black" />
      <path
        d="M79.0918 36.6516H82.1259V45.7551H79.0918V36.6516Z"
        fill="black"
      />
      <path d="M79.0918 48.7905H82.1259V57.894H79.0918V48.7905Z" fill="black" />
      <path
        d="M79.0918 60.9281H82.1259V70.0316H79.0918V60.9281Z"
        fill="black"
      />
      <path d="M79.0918 73.067H82.1259V79.1365H79.0918V73.067Z" fill="black" />
      <path
        d="M79.0918 85.2047H82.1259V88.2387H79.0918V85.2047Z"
        fill="black"
      />
      <path d="M82.1259 0.2374H85.1599V3.27147H82.1259V0.2374Z" fill="black" />
      <path
        d="M82.1259 18.4445H85.1599V21.4785H82.1259V18.4445Z"
        fill="black"
      />
      <path
        d="M82.1259 33.6175H85.1599V36.6516H82.1259V33.6175Z"
        fill="black"
      />
      <path
        d="M82.1259 45.7551H85.1599V48.7892H82.1259V45.7551Z"
        fill="black"
      />
      <path d="M82.1259 57.894H85.1599V66.9976H82.1259V57.894Z" fill="black" />
      <path
        d="M82.1259 70.0317H85.1599V73.0657H82.1259V70.0317Z"
        fill="black"
      />
      <path
        d="M82.1259 79.1352H85.1599V85.2047H82.1259V79.1352Z"
        fill="black"
      />
      <path
        d="M85.1599 0.237403H88.194V6.30687H85.1599V0.237403Z"
        fill="black"
      />
      <path d="M85.1599 6.30686H88.194V12.3763H85.1599V6.30686Z" fill="black" />
      <path d="M85.1599 12.3763H88.194V21.4799H85.1599V12.3763Z" fill="black" />
      <path d="M85.1599 27.548H88.194V30.5821H85.1599V27.548Z" fill="black" />
      <path d="M85.1599 39.687H88.194V42.721H85.1599V39.687Z" fill="black" />
      <path d="M85.1599 51.8246H88.194V54.8586H85.1599V51.8246Z" fill="black" />
      <path d="M85.1599 60.9281H88.194V66.9976H85.1599V60.9281Z" fill="black" />
      <path d="M85.1599 70.0317H88.194V76.1011H85.1599V70.0317Z" fill="black" />
    </svg>
  );
}

const MemoQR = React.memo(QR);
export default MemoQR;
