import SbEditable from 'storyblok-react';

import { Cards } from './Cards';
import { DualImage } from './DualImage';
import { FeaturedAsset } from './FeaturedAsset';
import { FeaturedText } from './FeaturedText';
import { Quote } from './Quote';
import { Simple } from './Simple';

export interface SectionProps {
  content: any;
  first: boolean;
  sectionType: string;
  extraPadding?: number | number[];
}

export const Section: React.FC<SectionProps> = ({
  content,
  first,
  sectionType,
  extraPadding,
}) => {
  let section = null;
  const shared = { content, pb: extraPadding, first };

  switch (sectionType) {
    case 'featured video or image section':
      section = <FeaturedAsset {...shared} />;
      break;
    case 'quote section':
      section = <Quote {...shared} />;
      break;
    case 'dual image section':
      section = <DualImage {...shared} />;
      break;
    case 'content section':
      section = <Simple {...shared} />;
      break;
    case 'card section':
      section = <Cards {...shared} />;
      break;
    case 'featured text section':
      section = <FeaturedText {...shared} />;
  }

  return (
    <SbEditable content={content}>
      <>{section}</>
    </SbEditable>
  );
};
