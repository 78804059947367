import * as React from 'react';

function Home() {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.329 10.6308L24.3273 10.6291L13.3596 0.344003C12.9253 -0.0983513 11.1697 0.0123052 10.5554 0.0123052C9.94106 0.0123052 9.36354 0.255794 8.92897 0.698149L0.69846 10.6237C0.695029 10.6272 0.691598 10.6308 0.688167 10.6343C-0.203839 11.5476 -0.242303 12.4798 0.652562 13.3907C1.0614 13.807 1.64136 14.5977 2.21868 14.6229C2.24213 14.6252 2.26576 14.6264 2.28958 14.6264C2.28958 14.6264 2.34958 14.5353 2.69575 14.6264C3.04193 14.7175 2.69575 22.2603 2.69575 22.2603C2.69575 23.7709 3.9032 25 5.38759 25L8.68951 24.6683C9.09358 24.6683 9.44976 22.8685 9.44976 22.457L9.66698 18.5872C9.66698 17.8979 10.2931 16.7076 10.9703 16.7076L13.6861 17.02C14.3633 17.02 14.9141 17.5807 14.9141 18.27V24.255C14.9141 24.6665 15.562 24.6683 15.9662 24.6683L19.633 25C21.1174 25 22.4138 22.8448 21.9828 21.5517C21.9828 21.5517 21.7224 15.9337 21.7224 15.1597C21.7224 14.3858 22.7015 14.6264 22.7015 14.6264C23.3156 14.6264 23.8931 14.3829 24.3278 13.9406C25.2237 13.0281 25.224 11.5439 24.329 10.6308Z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoHome = React.memo(Home);
export default MemoHome;
