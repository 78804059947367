import * as React from 'react';

function Inbox() {
  return (
    <svg
      width="30"
      height="20"
      viewBox="0 0 30 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.36842 0H27.6316C28.5212 0.0024129 29.3121 0.497004 29.7177 1.24669C29.9233 1.62678 29.7053 2.06841 29.3278 2.27878L22.4803 6.09468L16.1555 9.61923C15.4549 10.0097 14.5906 9.9453 13.949 9.4639C12.4277 8.32243 9.57024 6.23735 7.51974 5.09468C5.36131 3.89186 2.30874 2.73671 0.861716 2.21545C0.390888 2.04585 0.0921068 1.54181 0.357521 1.11755C0.780936 0.440718 1.53093 0.00227148 2.36842 0ZM27.6316 19.4685H2.36842C1.06209 19.4645 0.00370066 18.4065 0 17.1001V5.41297C0 4.64982 0.820404 4.16792 1.48696 4.53955L14.6133 11.8578C14.7317 11.9229 14.8646 11.9584 15 11.9605C15.136 11.9621 15.2695 11.9266 15.3867 11.8578C15.3867 11.8578 19.8399 10.375 22.6934 8.78418C24.4491 7.80528 26.8107 6.11004 28.3677 4.95067C29.0361 4.45294 30 4.92873 30 5.76212V17.1001C29.9991 18.4077 28.9391 19.4673 27.6316 19.4685Z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoInbox = React.memo(Inbox);
export default MemoInbox;
