import * as React from 'react';

function CashewsPolish() {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 123.9 68.3"
      xmlSpace="preserve"
    >
      <g>
        <path
          fill="#D1BA6C"
          d="M54.6,2.6c0.8,4-6.1,14,1.7,15c5.1-0.1,18.2-5.9,14.5,3.4c-6.9,17.1-32.4,7.1-28.8-7.9
		C43.1,8.6,51.3-5.4,54.6,2.6z"
        />
        <path
          fill="#EBD078"
          d="M54.6,2.6c0.8,4-7.2,13,0.6,14c5.1-0.1,17.6-5.4,15.2,3.1c-7.7,15.7-32,6.5-28.8-7.9
		C41.7,7.2,51.3-5.4,54.6,2.6z"
        />
      </g>
      <g>
        <path
          fill="#D1BA6C"
          d="M20.5,64.3c5.7,3.2,23.2,6.6,26.2-3.3c3.8-12.3-13.5-9.7-19.6-10.6c-1.8-0.3-3.8-5.6-4.2-8.8
		C22,35.1,15,34,12.3,38C8.2,44.1,7.7,58,20.5,64.3"
        />
        <path
          fill="#EBD078"
          d="M20.5,62.4c5.7,3.2,24.1,6.8,26.2-3.3c2.1-10.2-14-9.1-19.6-10.6c-1.8-0.5-3.8-5.6-4.2-8.8
		c-0.9-6.5-7.9-6.5-10.6-2.5C8.3,43.3,7.7,55.1,20.5,62.4"
        />
        <path
          fill="#F2F3E0"
          d="M26.4,61.4c-2,0-3.8-0.5-5.5-1.4c-2.6-1.4-4.6-3.3-6-5.7c-1.4-2.4-2.2-5.1-2.5-7.8c-0.2-1.7,0-3.3,0-4.9
		c0-0.3,0.1-0.6,0.3-0.9c0.4,2.1,0.8,4.1,1.4,6c0.5,1.7,1.2,3.4,1.8,5.1c0.5,1.3,1.4,2.5,2.3,3.7c1.4,1.6,2.8,3.1,4.8,4.2
		C24.2,60.3,25.4,60.6,26.4,61.4z"
        />
      </g>
      <path
        fill="#D1BA6C"
        d="M12.4,28.1c6,2.6,24.7,5,26.9-3.5c2.8-10.7-14.9-7.2-20.7-8.4c-1.9-0.4-4.5-1.4-5-4C12.4,6.9,4.6,5.8,2,9.3
	C-1.9,14.5-0.9,23,12.4,28.1"
      />
      <path
        fill="#EBD078"
        d="M12.4,26.1c6,2.6,25.2,5.1,26.9-3.5c1.8-8.6-14.9-7.2-20.7-8.4c-1.9-0.4-4.5-1.4-5-4C12.4,4.8,5.2,5,2.6,8.5
	C-1.4,13.7-1.2,20.3,12.4,26.1"
      />
      <path
        fill="#F2F3E0"
        d="M2,12.4c-0.7,7.6,9.6,12,15.7,12.1C11.4,21.7,5,19,2,12.4z"
      />
      <path
        fill="#D1BA6C"
        d="M113.8,35.2c-5.1,2.3-21.1,4.3-22.7-3.9c-2.1-10.6,12.6-6.6,17.6-7.6c1.6-0.3,3.5-4.4,4-6.9
	c1-5.2,7.1-4.9,9.3-1.5C125.3,20.4,125.3,29.9,113.8,35.2"
      />
      <g>
        <path
          fill="#D1BA6C"
          d="M80.6,51.5c5.9,3.4,25.5,7.8,27.9-2.1C111.5,36.3,94,40,88.2,38.2c-1.9-0.6-3.8-5.6-4.1-8.7
		c-0.7-6.3-8-6.7-11.1-2.8C68.5,32.4,67.3,43.8,80.6,51.5"
        />
        <path
          fill="#EBD078"
          d="M80.6,49.7c5.9,3.4,25.2,7.7,27.9-2.1c2.7-9.8-14.5-9.4-20.3-11.2c-1.9-0.6-3.8-5.6-4.1-8.7
		c-0.7-6.3-8-6.7-11.1-2.8C68.5,30.6,67.3,42.1,80.6,49.7"
        />
      </g>
      <path
        fill="#D1BA6C"
        d="M48.5,49.8c-5.4-0.3-10.2-2.2-13.9-6.2c-1.5-1.7-2.1-5.1-1.8-7.4c0.2-1.4,1.5-1.5,3-1.7c1.1-0.1,2.1,0,3.2,0.3
	c3.1,0.9,6.3,1.4,9.5,0.3c2.4-0.8,3.8-2.8,3.8-5.3c0-1.9,4.7-2,4.1-3.9c-0.6-1.8-5.1-4.6-3.4-5.5c1.9-1,3.9-1.1,6-0.7
	c1.4,0.3,2.6,1.1,3.7,2c2.6,2.3,3.7,5.2,3.6,8.6c-0.1,2.5-0.7,4.8-1.7,7.1c-1.1,2.5-2.4,4.9-4.2,7c-1.9,2.2-4.3,3.8-7.1,4.6
	C51.7,49.6,50.2,49.9,48.5,49.8z"
      />
      <path
        fill="#EBD078"
        d="M48.5,48.1c-5.4-0.3-10.2-2.2-13.9-6.2c-1.5-1.7-2.2-3.7-2-6c0.2-1.4,1.6-2.9,3.2-3c1.1-0.1,2.1,0,3.2,0.3
	c3.1,0.9,6.3,1.4,9.5,0.3c2.4-0.8,3.8-2.8,3.8-5.3c0-1.9-0.3-3.8-1-5.6c-0.6-1.8,0-2.9,1.7-3.7c1.9-1,3.9-1.1,6-0.7
	c1.4,0.3,2.6,1.1,3.7,2c2.6,2.3,3.7,5.2,3.6,8.6c-0.1,2.5-0.7,4.8-1.7,7.1c-1.1,2.5-2.4,4.9-4.2,7c-1.9,2.2-4.3,3.8-7.1,4.6
	C51.7,47.9,50.2,48.1,48.5,48.1z"
      />
      <path
        fill="#F2F3E0"
        d="M49.8,45.6c1.7,0.1,3.4-0.2,4.9-1c2.3-1.2,4.1-2.9,5.4-5.1c1.4-2.3,2.1-4.7,2.5-7.3c0.2-1.6,0.2-3.1,0.2-4.7
	c0-0.3,0-0.6-0.3-0.9c-0.4,2-0.9,3.8-1.5,5.7c-0.5,1.6-1.2,3.2-1.8,4.8c-0.5,1.3-1.3,2.3-2.2,3.4c-1.3,1.5-2.6,2.9-4.4,3.8
	C51.8,44.7,50.8,45,49.8,45.6z"
      />
      <path
        fill="#EBD078"
        d="M113.8,33.7c-5.1,2.3-21.3,4.4-22.7-3.9c-1.4-8.3,12.6-6.6,17.6-7.6c1.6-0.3,3.5-4.4,4-6.9
	c1-5.2,7.1-4.9,9.3-1.5C125.3,18.9,125.3,28.4,113.8,33.7"
      />
      <g>
        <path
          fill="#D1BA6C"
          d="M84.8,42.4c-0.5-2.6-1.2-5.1-3.1-6.5c-2.5-2-8.1-1.2-7.4,2.8c1.1,3.8,1.4,9.7-2.3,12.4
		c-4.5,5.4-15.2-3-17.1,5.2C53.3,78.7,89.9,67.3,84.8,42.4z"
        />
        <path
          fill="#EBD078"
          d="M84,41c-0.6-2.7-1.2-5.1-3.1-6.5c-2.5-2-8.1-1.2-7.4,2.8c1.1,3.8,1,9.3-2.3,12.4c-5.3,4.9-15.2-3-17.1,5.2
		C52.5,77.3,89.3,65.8,84,41z"
        />
        <path
          fill="#F2F3E0"
          d="M60.6,63.7c9,0.5,15-4.3,19.9-11.1C78.7,60.2,69.7,67.4,60.6,63.7z"
        />
        <path
          fill="#F2F3E0"
          d="M90.4,50.5c8.6-1,11.5,0.3,15.8-4.4C103.6,52.7,98.3,52.3,90.4,50.5z"
        />
      </g>
      <path
        fill="#F2F3E0"
        d="M108.7,32.6c1.7,0.1,3.4-0.2,4.9-0.8c2.3-1,4.1-2.5,5.4-4.3c1.4-1.9,2.1-4,2.5-6.2c0.2-1.3,0.2-2.6,0.2-4
	c0-0.2,0-0.5-0.3-0.7c-0.4,1.7-0.9,3.2-1.5,4.8c-0.5,1.4-1.2,2.7-1.8,4c-0.5,1.1-1.3,2-2.2,2.8c-1.3,1.2-2.6,2.4-4.4,3.2
	C110.7,31.8,109.7,32,108.7,32.6z"
      />
    </svg>
  );
}

const MemoCashewsPolish = React.memo(CashewsPolish);
export default MemoCashewsPolish;
